import React from 'react';
import PropTypes from 'prop-types';
import RCalendar from 'react-calendar';

import './index.scss';

// import Chevron, { DIRECTION } from '../Chevron';

const formatShortWeekday = (locale, date) => {
  switch (date.getDay()) {
    case 0:
      return 'D';
    case 2:
      return 'T';
    case 3:
    case 4:
      return 'Q';
    case 1:
    case 5:
    case 6:
      return 'S';
    default:
      return '';
  }
};

const Calendar = props => {
  const { onChange, value } = props;

  return (
    <RCalendar
      onChange={onChange}
      className='custom-calendar'
      value={value}
      locale='pt-br'
      formatShortWeekday={formatShortWeekday}
      next2Label={null}
      prev2Label={null}
    />
  );
};

Calendar.defaultProps = {
  onChange: () => {},
  value: new Date(),
};

Calendar.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
};

export default Calendar;

import { combineReducers } from 'redux';
import * as types from './types';

const initialUI = { open: false, loading: false, error: false };

const initialConflict = { ...initialUI };
const conflict = (state = initialConflict, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER_CONFLICT:
      return { ...state, open: true };
    case types.CLOSE_DRAWER_CONFLICT:
      return { ...state, open: false };
    case types.FETCH_CONFLICT_INFO:
      return { ...state, loading: true, error: false };
    case types.FETCH_CONFLICT_DONE:
      return { ...state, loading: false };
    case types.FETCH_CONFLICT_FAIL:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

const initialBookingDetail = { ...initialUI, selected: null };
const bookingDetail = (state = initialBookingDetail, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER_BOOKING_DETAIL:
      return { ...state, open: true, selected: action.payload };
    case types.CLOSE_DRAWER_BOOKING_DETAIL:
      return { ...state, open: false, selected: null };
    default:
      return state;
  }
};

const initialClassDetail = { ...initialUI, selectedBookingId: null };
const classDetail = (state = initialClassDetail, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER_CLASS_DETAIL:
      return { ...state, open: true };
    case types.CLOSE_DRAWER_CLASS_DETAIL:
      return { ...state, open: false };
    case types.SELECT_CLASS_DETAIL_ID:
      return { ...state, bookingId: action.payload };
    default:
      return state;
  }
};

const newBookingInitialState = { ...initialUI, initialValues: null };
const newBooking = (state = newBookingInitialState, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER_NEW_BOOKING:
      return { ...state, open: true, initialValues: action.payload };
    case types.CLOSE_DRAWER_NEW_BOOKING:
      return { ...state, open: false, initialValues: null };
    default:
      return state;
  }
};

const userInfoInitialState = { ...initialUI, initialValues: null };
const userInfo = (state = userInfoInitialState, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER_USER_INFO:
      return { ...state, open: true, initialValues: action.payload };
    case types.CLOSE_DRAWER_USER_INFO:
      return { ...state, open: false, initialValues: null };
    default:
      return state;
  }
};

const payInfoInitialState = { ...initialUI, initialValues: null };
const payInfo = (state = payInfoInitialState, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER_PAY_INFO:
      return { ...state, open: true, initialValues: action.payload };
    case types.CLOSE_DRAWER_PAY_INFO:
      return { ...state, open: false, initialValues: null };
    default:
      return state;
  }
};

export default combineReducers({
  conflict,
  bookingDetail,
  classDetail,
  newBooking,
  userInfo,
  payInfo,
});

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { unauthenticate } from '../../store/auth/actions';

import styles from './index.module.scss';

const LogoutButton = () => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(unauthenticate());
  }, [dispatch]);

  return (
    <button className={styles['logout-button']} type='button' onClick={onClick}>
      <span>sair</span>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='19'
        height='18'
        viewBox='0 0 19 18'
      >
        <g
          fill='none'
          fillRule='evenodd'
          stroke='#EB4E52'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        >
          <path d='M7.407 9.25h9.244M13.768 5.222l3.89 3.889L13.767 13M7.933 17H1V1h6.933' />
        </g>
      </svg>
    </button>
  );
};

export default LogoutButton;

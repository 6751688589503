import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Table = props => {
  const { children } = props;

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th style={{ width: '33%' }}>Nome</th>
          <th style={{ width: '33%' }}>Telefone</th>
          <th style={{ width: '33%' }}>E-mail</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

Table.defaultProps = {
  children: null,
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Table;

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import styles from './index.module.scss';

const container = {
  hover: {
    scale: 1.1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const dot = {
  hover: { y: [0, -5, 0] },
};

const Dot = () => <motion.div variants={dot} className={styles.dot} />;

const OptionsButton = props => {
  const { onClick } = props;

  return (
    <motion.div
      variants={container}
      whileHover='hover'
      className={styles.container}
      onClick={onClick}
    >
      {[...Array(3)].map((_, i) => (
        <Dot key={i} />
      ))}
    </motion.div>
  );
};

OptionsButton.defaultProps = {
  onClick: () => {},
};

OptionsButton.propTypes = {
  onClick: PropTypes.func,
};

export default OptionsButton;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';

/* Single Radio */
const Radio = props => {
  const { id, name, value, label, checked, onChange, style } = props;

  return (
    <div className={styles.radio} style={style}>
      <input
        type='radio'
        value={value}
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

Radio.defaultProps = {
  style: null,
};

Radio.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

/**
 * @template {string|number} T
 * @param {object} props
 * @param {string} props.name
 * @param {Array<{value: T, label: string}>} [props.options=[]]
 * @param {T} [props.value]
 * @param {string|number} [props.gap]
 * @param {import('react').InputHTMLAttributes<HTMLInputElement>['onChange']} [props.onChange]
 * @returns
 */
const RadioGroup = props => {
  const { name, value, options, onChange, gap } = props;
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (value !== selected) setSelected(value);
  }, [value, selected]);

  const _onChange = e => {
    setSelected(e.currentTarget.value);
    if (onChange && typeof onChange === 'function') {
      onChange(e);
    }
  };

  if (!options || !options.length) return null;

  const _marginTop = typeof gap === 'string' ? `-${gap}` : -gap;

  const _options = [];
  for (let i = 0; i < options.length; i++) {
    const { value: optValue, label: optLabel } = options[i];

    _options.push(
      <Radio
        style={{ marginTop: gap }}
        key={`${name}_${i}`}
        id={`${name}_${i}`}
        checked={String(selected) === String(optValue)}
        name={name}
        value={optValue}
        label={optLabel}
        onChange={_onChange}
      />
    );
  }

  return <div style={{ marginTop: _marginTop }}>{_options}</div>;
};

RadioGroup.defaultProps = {
  gap: 0,
  value: '',
  options: [],
  onChange: () => '',
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  /**
   * Space between options
   */
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RadioGroup;

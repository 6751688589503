/**
 *
 * @param {string} str
 * @return {string} masked phone number
 */
export default function maskPhone(str) {
  let s = String(str).replace(/[^0-9]/g, '');

  const match = s.match(/(\d{2})(\d{4,5})(\d{4})/);

  if (match) {
    // eslint-disable-next-line no-unused-vars
    const [_, ddd, d1, d2] = match;

    s = `(${ddd}) ${d1}-${d2}`;
  }

  return s;
}

import union from 'lodash/union';

import { fetchArenas } from '../routines';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case fetchArenas.SUCCESS:
      return union([], state, action.payload.order);
    default:
      return state;
  }
};

import * as types from '../types';

const initialState = () => [];

export default (state = initialState(), action) => {
  switch (action.type) {
    case types.ADD_PAGINATED_PAYMENT:
      return action.payload;
    case types.RESET_PAYMENT_STORE:
      return initialState();
    default:
      return state;
  }
};

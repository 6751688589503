import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast, ToastType } from 'react-toastify';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { baseAPI } from '../../service/axios';
import objectToFormData from '../../utils/toFormData';
import { W } from '../../constants/AppConstants';
import Base64 from '../../utils/Base64';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .nullable()
    .required('Campo obrigatório.'),
  confirm: Yup.string()
    .oneOf([Yup.ref('password')], 'Senhas precisam ser iguais.')
    .required('Campo obrigatório'),
});

const ConfirmNewPasswordForm = ({ onSuccess, urlParams }) => {
  const params = useMemo(() => {
    const res = {};

    if (urlParams) {
      const regex = /(?<key>\w*)=(?<value>\w*)/g;

      const matches = urlParams.match(regex);

      if (matches && matches.length) {
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i];
          const [key, value] = match.split('=');

          if (value) {
            res[key] = value;
          }
        }
      }
    }

    return res;
  }, [urlParams]);

  const getUrlParams = part => {
    const recoverPath = '/app/recover-password/';
    const path =
      window.location.pathname.indexOf(recoverPath) >= 0
        ? window.location.pathname
            .slice(recoverPath.length, window.location.pathname.length)
            .split('/')
        : '';
    let pathReturn = '';
    // return path[0];
    if (typeof path == 'object' && path.length === 2) {
      switch (part) {
        case 'rp_key':
          pathReturn = path[0];
          break;
        case 'login':
          let login = Base64.atob(path[1].replace('_', '/'));
          pathReturn = login;
          break;

        default:
          break;
      }
    }
    return pathReturn;
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      password: '',
      confirm: '',
      rp_key: getUrlParams('rp_key'),
      login: getUrlParams('login'),
      w: W,
    },
    onSubmit: async values => {
      try {
        const payload = {
          pass1: values.password,
          rp_key: values.rp_key,
          user_login: values.login,
          w: values.w,
          ...params,
        };

        await baseAPI.post('user/recover/confirm', objectToFormData(payload));

        toast('Sua senha foi trocada com sucesso.', {
          type: ToastType.SUCCESS,
        });

        if (onSuccess && typeof onSuccess === 'function') onSuccess();
      } catch (ex) {
        toast('Desculpe, mas não foi possível realizar esta solicitação.', {
          type: ToastType.ERROR,
        });
      }
    },
  });

  return (
    <>
      <Input
        id='password'
        name='password'
        type='password'
        placeholder='Senha'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && formik.errors.password}
        style={{ marginBottom: 15 }}
      />
      <Input
        id='confirm'
        name='confirm'
        type='password'
        placeholder='Confirmação de senha'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.confirm}
        error={formik.touched.confirm && formik.errors.confirm}
        style={{ marginBottom: 15 }}
      />
      <Input
        id='login'
        name='login'
        type='hidden'
        placeholder=''
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.login}
        error={formik.touched.login && formik.errors.login}
        style={{ display: 'none' }}
      />
      <Input
        id='rp_key'
        name='rp_key'
        type='hidden'
        placeholder=''
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.rp_key}
        error={formik.touched.rp_key && formik.errors.rp_key}
        style={{ display: 'none' }}
      />
      <Input
        id='w'
        name='w'
        type='hidden'
        placeholder=''
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.w}
        error={formik.touched.w && formik.errors.w}
        style={{ display: 'none' }}
      />
      <Button
        title='Enviar'
        type='submit'
        onClick={formik.handleSubmit}
        loading={formik.isSubmitting}
      />
    </>
  );
};

export default ConfirmNewPasswordForm;

ConfirmNewPasswordForm.defaultProps = {
  urlParams: '',
  onSuccess: () => '',
};

ConfirmNewPasswordForm.propTypes = {
  urlParams: PropTypes.string,
  onSuccess: PropTypes.func,
};

import * as types from './types';

/* CRUD */
export const addPerson = payload => ({
  type: types.ADD_PERSON,
  payload,
});

export const removePerson = payload => ({
  type: types.REMOVE_PERSON,
  payload,
});

/* UI */
export const clearOrderPeson = () => ({
  type: types.CLEAR_ORDER_PERSON,
});

/* PAGINATION */
export const addPaginatedPerson = payload => ({
  type: types.ADD_PAGINATED_PERSON,
  payload,
});

export const changePersonMaxPages = payload => ({
  type: types.CHANGE_MAX_PAGES_PERSON,
  payload,
});

export const changePersonPage = payload => ({
  type: types.CHANGE_PAGE_PERSON,
  payload,
});

export const changePersonPageSize = payload => ({
  type: types.CHANGE_PAGE_SIZE_PERSON,
  payload,
});

export const changePersonSearchParam = payload => ({
  type: types.CHANGE_SEARCH_PARAM_PERSON,
  payload,
});

export const changePersonTotal = payload => ({
  type: types.CHANGE_TOTAL_PERSON,
  payload,
});

export const resetPersonPaginationData = () => ({
  type: types.RESET_PERSON_PAGINATION_DATA,
});

export const resetPersonStore = () => ({ type: types.RESET_PERSON_STORE });

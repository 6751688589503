import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Input from '../../components/Input';
import { authenticate } from '../../store/auth/actions';
import style from './index.module.scss';
import Button from '../../components/Button';
import UserIcon from '../../assets/icons/UserIcon';
import LockIcon from '../../assets/icons/LockIcon';

/* Local Components */
const IconContainer = ({ children }) => {
  const _style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '10px',
  };

  return <div style={_style}>{children}</div>;
};

const RecoverPasswordButton = ({ onClick }) => {
  const d = { display: 'flex', background: 'transparent' };
  const s = {
    color: '#a0a0a0',
    paddingLeft: 7.5,
    fontSize: 12,
    fontWeight: 500,
    cursor: 'pointer',
    alignItems: 'center',
    userSelect: 'none',
  };

  return (
    <button type='button' style={d} onClick={onClick}>
      <IconContainer>
        <LockIcon stroke='#a0a0a0' />
      </IconContainer>
      <span style={s}>Esqueci a senha</span>
    </button>
  );
};

/* Exported Component */
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido!')
    .required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
});

const FormLogin = props => {
  /* FIXME: Remove this line when backend is fixed. Also uncomment JSX below */
  // eslint-disable-next-line no-unused-vars
  const { onPwdRecoverClick } = props;

  const dispatch = useDispatch();
  const requesting = useSelector(state => state.auth.ui.request);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      dispatch(authenticate(values));
    },
  });

  return (
    <form className={style.container} onSubmit={formik.handleSubmit}>
      <Input
        style={{ marginBottom: 15 }}
        id='email'
        name='email'
        type='email'
        placeholder='E-mail'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && !!formik.errors.email}
        leftComponent={
          <IconContainer>
            <UserIcon />
          </IconContainer>
        }
      />
      <Input
        style={{ marginBottom: 15 }}
        id='password'
        name='password'
        type='password'
        placeholder='Senha'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && !!formik.errors.password}
        leftComponent={
          <IconContainer>
            <LockIcon />
          </IconContainer>
        }
      />
      <div className={style['fgt-pwd-wrapper']}>
        <RecoverPasswordButton onClick={onPwdRecoverClick} />
      </div>
      <Button
        title='Entrar'
        type='submit'
        disabled={requesting}
        onClick={formik.handleSubmit}
        loading={requesting}
      />
    </form>
  );
};

export default FormLogin;

/* PropsTypes */
IconContainer.defaultProps = {
  children: null,
};

IconContainer.propTypes = {
  children: PropTypes.node,
};

RecoverPasswordButton.defaultProps = {
  onClick: () => '',
};

RecoverPasswordButton.propTypes = {
  onClick: PropTypes.func,
};

FormLogin.defaultProps = {
  onPwdRecoverClick: () => '',
};

FormLogin.propTypes = {
  onPwdRecoverClick: PropTypes.func,
};

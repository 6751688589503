import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import styles from './index.module.scss';

const Checkbox = props => {
  const { id, checked, label, onChange, fill, stroke, color } = props;

  const [_checked, setChecked] = useState(checked);

  React.useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const _onChange = () => {
    if (onChange && typeof onChange === 'function') {
      onChange(!_checked, id);
    } else {
      setChecked(!_checked);
    }
  };

  // Animation
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  const boxVariants = {
    checked: { backgroundColor: fill },
    unchecked: { backgroundColor: 'rgba(255, 255, 255 ,0)' },
  };

  const checkVariants = {
    checked: { pathLength: 0.9 },
    unchecked: { pathLength: 0 },
  };

  return (
    <div className={styles.checkbox}>
      <motion.div
        className={styles.checkmark}
        style={{ borderColor: fill }}
        variants={boxVariants}
        initial='checked'
        animate={checked ? 'checked' : 'unchecked'}
        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        onTap={_onChange}
      >
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150'>
          <motion.path
            d='M38 74.707l24.647 24.646L116.5 45.5'
            fill='transparent'
            strokeWidth='20'
            stroke={stroke}
            strokeLinecap='round'
            variants={checkVariants}
            style={{ pathLength, opacity }}
          />
        </svg>
      </motion.div>
      <div
        onClick={_onChange}
        role='none'
        className={styles.label}
        style={{ color }}
      >
        {label}
      </div>
    </div>
  );
};

Checkbox.defaultProps = {
  id: undefined,
  fill: 'rgba(0,0,0,1)',
  color: 'white',
  stroke: 'rgba(255,255,255,1)',
  label: '',
  checked: false,
  onChange: () => {},
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  color: PropTypes.string,
  stroke: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default React.memo(Checkbox);

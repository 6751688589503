import { toast, ToastType } from 'react-toastify';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import PaymentStatus from '../../enum/paymentStatus';
import * as bRP from '../../providers/bookingRequest';
import * as P from '../../providers/events';
import snakelizeObject from '../../utils/snakelizeObject';

import * as bRA from '../bookingRequest/actions';
import * as eventActions from '../event/actions';
import { fetchEvents } from '../event/routines';
import * as sDA from '../sideDrawer/actions';

import * as R from './routines';

/**
 * @typedef { import('../../model/NewBookingRequest.model').NewBookingRequest } NewBookingRequest
 */

/* Generic Generators */
function* removeBookingRequestAndCloseDrawer(id) {
  yield all([
    put(bRA.removeBookingRequest(id)),
    put(sDA.closeBookingDetailDrawer()),
  ]);
}

/* API Generators */

/**
 * @generator
 * @function createBooking
 * @param {Object} action
 * @param {string} action.type
 * @param {NewBookingRequest} action.payload
 */
function* createEditBooking(action) {
  try {
    yield put(R.createEditBooking.request());

    const { payload } = action;
    const { bookingId, ...values } = payload;

    /* Get active arena id */
    const arenaId = yield select(state => state.config.activeArena);

    /* Add arenaId to newBooking. Convert object keys from pascal case to camel case */
    const newBooking = snakelizeObject({ ...values, arenaId });

    if (newBooking.week_entries) {
      newBooking.booking_schedule = JSON.stringify(newBooking.week_entries);
    }

    let res;

    /* Editar é o id da reserva */
    const isEditing = bookingId != null && !!bookingId;

    if (!isEditing) {
      res = yield call(P.postNewBooking, newBooking);
    } else {
      newBooking.id = bookingId;
      res = yield call(P.editBooking, newBooking);
      yield put(sDA.closeNewBookingDrawer());
    }

    yield put(R.createEditBooking.success(res));

    toast(`Reserva ${isEditing ? 'editada' : 'criada'} com sucesso!`, {
      type: ToastType.SUCCESS,
    });

    const date = yield select(state => state.agenda.selectedDate);
    yield put(
      fetchEvents({
        date,
        payStatus: [PaymentStatus.PAID, PaymentStatus.PENDING],
      })
    );
  } catch (err) {
    yield put(R.createEditBooking.failure(err.message));

    // eslint-disable-next-line camelcase
    if (err.response?.data?.body_response != null) {
      toast(err.response.data.body_response, { type: ToastType.ERROR });
    }
  } finally {
    yield put(R.createEditBooking.fulfill());
  }
}

/**
 * @generator
 * @function createBookingRequest
 * @param {Object} action
 * @param {string} action.type
 * @param {NewBookingRequest} action.payload
 */
function* createBookingRequest(action) {
  try {
    const { payload } = action;

    yield put(R.createNewBookingRequest.request());
    const res = yield call(P.postNewBookingRequest, payload);
    yield put(R.createNewBookingRequest.success(res));
    toast('Pedido de reserva criado com sucesso!', {
      type: toast.TYPE.SUCCESS,
    });
    // yield
  } catch (err) {
    yield put(R.createNewBookingRequest.failure(err.message));
    toast(err.response.data.body_response, { type: toast.TYPE.ERROR });
  } finally {
    yield put(R.createNewBookingRequest.fulfill());
  }
}

function* deleteBooking(action) {
  try {
    const { payload: id } = action;

    yield put(R.deleteBooking.request());
    const res = yield call(P.deleteBooking, id);
    yield put(R.deleteBooking.success(res));
    toast('Reserva cancelada com sucesso!', { type: toast.TYPE.SUCCESS });
    yield put(eventActions.removeBooking(id));
    yield put(sDA.closeClassDetailDrawer());
  } catch (err) {
    yield put(R.deleteBooking.failure(err.message));
    toast(err.response.data.body_response, { type: toast.TYPE.ERROR });
  } finally {
    yield put(R.deleteBooking.fulfill());
  }
}

function* deleteBookingRecurrence(action) {
  try {
    const { payload: id } = action;

    yield put(R.deleteBookingRecurrence.request());
    const res = yield call(P.deleteBookingRecurrence, id);
    yield put(R.deleteBookingRecurrence.success(res));
    toast('Reservas canceladas com sucesso!', { type: toast.TYPE.SUCCESS });
    yield put(eventActions.removeBooking(id));
    yield put(sDA.closeClassDetailDrawer());
  } catch (err) {
    yield put(R.deleteBookingRecurrence.failure(err.message));
    toast(err.response.data.body_response, { type: toast.TYPE.ERROR });
  } finally {
    yield put(R.deleteBookingRecurrence.fulfill());
  }
}

function* cancelBookingRequest(action) {
  try {
    /* Request booking id */
    const { payload } = action;

    yield put(R.cancelBookingRequest.request());
    const res = yield call(bRP.cancelBookingRequest, payload);

    if (res) {
      toast('Reserva cancelada com sucesso!', { type: toast.TYPE.SUCCESS });
      yield call(removeBookingRequestAndCloseDrawer, payload);
    } else {
      toast('Não foi possível cancelar esta reserva.', {
        type: toast.TYPE.ERROR,
      });
    }

    yield put(R.cancelBookingRequest.success(res));
  } catch (err) {
    yield put(R.cancelBookingRequest.failure(err.message));
    toast(err.response.data.body_response, { type: toast.TYPE.ERROR });
  } finally {
    yield put(R.cancelBookingRequest.fulfill());
  }
}

function* approveBookingRequest(action) {
  try {
    /* Payload = booking request id */
    const { payload } = action;

    yield put(R.approveBookingRequest.request());

    let bookingRequest = null;
    if (typeof payload === 'object') {
      /* Full approve */
      bookingRequest = payload;
    } else {
      /* Fast approve */
      bookingRequest = yield select(state =>
        state.bookingRequest.data.find(x => x.id === payload)
      );
    }

    if (bookingRequest) {
      const { id, ...others } = bookingRequest;

      /* Get active arena id */
      const arenaId = yield select(state => state.config.activeArena);

      const newRequest = snakelizeObject({
        ...others,
        bookingRequestId: id,
        arenaId,
      });

      if (newRequest.week_entries) {
        newRequest.booking_schedule = JSON.stringify(newRequest.week_entries);
      }

      /* Try posting */
      const res = yield call(bRP.approveBookingRequest, newRequest);

      if (res) {
        toast('Reserva aprovada com sucesso!', { type: toast.TYPE.SUCCESS });
        yield call(removeBookingRequestAndCloseDrawer, id);
      } else {
        toast('Não foi possível aprovar este pedido.', {
          type: toast.TYPE.ERROR,
        });
      }

      yield put(R.approveBookingRequest.success(res));
    }
  } catch (err) {
    yield put(R.approveBookingRequest.failure(err.message));

    if (err.response) {
      toast(err.response.data.body_response, { type: toast.TYPE.ERROR });
    } else {
      toast(err.message, { type: toast.TYPE.ERROR });
    }
  } finally {
    yield put(R.approveBookingRequest.fulfill());
  }
}

export default function* httpRequestSaga() {
  yield all([
    takeEvery(R.createEditBooking.TRIGGER, createEditBooking),
    takeEvery(R.createNewBookingRequest.TRIGGER, createBookingRequest),
    takeEvery(R.deleteBooking.TRIGGER, deleteBooking),
    takeEvery(R.cancelBookingRequest.TRIGGER, cancelBookingRequest),
    takeEvery(R.approveBookingRequest.TRIGGER, approveBookingRequest),
    takeEvery(R.deleteBookingRecurrence.TRIGGER, deleteBookingRecurrence),
  ]);
}

import React, { forwardRef } from 'react';

import classes from './index.module.scss';

/** @typedef {import('react').DetailedHTMLProps<import('react').InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} Props */
/** @typedef {import('react').ClassAttributes<HTMLInputElement>['ref']} Ref */

const ContainedInput = forwardRef(
  /**
   *
   * @param {Props} props
   * @param {Ref} ref
   * @returns
   */
  function ContainedInput(props, ref) {
    return <input ref={ref} {...props} className={classes.input} />;
  }
);

export default ContainedInput;

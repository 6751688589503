import { baseAPI, v2API } from '../../service/axios';

/**
 * @typedef { import('../../model/Court.model').CourtTypes } Court
 *
 * Function to fetch arena courts
 *
 * @param {number} id - Arena id
 *
 * @returns {Promise<Court[]>}
 */
const fetchArenaCourts = async id => {
  const res = await baseAPI.get(`arena/${id}/courts`);

  return res.data;
};

export default fetchArenaCourts;

export const fetchArenaCourtsV2 = id =>
  v2API.get(`arena/${id}/courts`).then(res => res.data);

import { getApi } from '../../service/axios';
import snakelizeObject from '../../utils/snakelizeObject';

/**
 * @typedef PaymentApiResponse
 * @property {number} id
 * @property {string} applicant_email
 * @property {number} applicant_id
 * @property {string} applicant_name
 * @property {string} applicant_telephone
 * @property {number} arena_id
 * @property {boolean} booking_class
 * @property {string} booking_date
 * @property {number} booking_duration
 * @property {string} booking_instructor_reservation
 * @property {string} booking_player_level
 * @property {0|1|2} booking_status
 * @property {0|1} booking_type
 * @property {'Avulsa'|'Mensal'} booking_type_label
 * @property {number} court_id
 * @property {string} date_created
 * @property {string} modality
 * @property {0|1|2|3} pay_status
 * @property {0|1|2} pay_tipo
 * @property {number} valor
 * @property {number[]} week_day
 * @property {string[]} week_time
 * @property {string} created_at
 * @property {string} cancelled_at
 * @property {string} cancelled_by
 * @property {string} updated_at
 */

/**
 * @typedef FetchPaymentsResponse
 * @property {number} max_pages
 * @property {number} total
 * @property {number} page
 * @property {number} page_size
 * @property {Payment[]} data
 */

/**
 *
 * @param {string} id
 * @param {object} [payload]
 * @param {number} [payload.applicantId]
 * @param {string} [payload.applicantName]
 * @param {boolean} [payload.history=false]
 * @param {forceModel} [payload.forceModel=false]
 * @param {string} [payload.order]
 * @param {string} [payload.date]
 * @param {string} [payload.minDate]
 * @param {string} [payload.maxDate]
 * @param {number} [payload.firsts]
 * @param {number} [payload.page=1]
 * @param {number} [payload.pageSize=20]
 * @param {Array<0|1|2|3>} [payload.payStatus]
 * @param {Array<0|1|2>} [payload.status]
 *
 * @param {object} [options]
 * @param {1|2|3} [options.version=2]
 * @returns {Promise<import('axios').AxiosResponse<FetchPaymentsResponse>['data']>}
 */
export default function(id, payload, options = { version: 2 }) {
  let params;

  if (payload != null) {
    const { history, page, pageSize, forceModel, ...rest } = payload;

    params = snakelizeObject({
      ...rest,
      pageSize: pageSize <= 0 ? 20 : pageSize,
      history: history ?? false,
      page: page ?? 1,
      forceModel: forceModel ?? false,
    });
  }

  const version = options?.version ?? 2;

  return (
    getApi(version)
      .get(`arena/${id}/bookings`, { params })
      .then(res => res.data)
      // eslint-disable-next-line camelcase
      .then(res =>
        version !== 3 || payload.forceModel
          ? res
          : {
              max_pages: res.max_pages,
              total: res.total,
              page: res.page,
              page_size: res.page_size,
              data: res.data.map(item => ({
                id: Number(item.id),
                applicant_email: item.applicant_email,
                applicant_name: item.applicant_name,
                arena_id: Number(item.arena_id),
                booking_date: `${item.date} ${item.time}`,
                booking_type: Number(item.type),
                pay_status: Number(item.status),
              })),
            }
      )
  );
}

/**
 *
 * @param {number} arenaId
 * @param {number} bookingId
 * @returns {Promise<PaymentApiResponse>}
 */
export const fetchPayment = (arenaId, bookingId) =>
  getApi(3)
    .get(`arena/${arenaId}/booking/${bookingId}`)
    .then(res => res.data);

export {};

const WEEK_DAY = Object.freeze({
  MONDAY: '1',
  TUESDAY: '2',
  WEDNESDAY: '3',
  THURSDAY: '4',
  FRIDAY: '5',
  SATURDAY: '6',
  SUNDAY: '0',
});

const getWeekDayString = value => {
  switch (value) {
    case WEEK_DAY.SUNDAY:
      return 'Dom';
    case WEEK_DAY.MONDAY:
      return 'Seg';
    case WEEK_DAY.TUESDAY:
      return 'Ter';
    case WEEK_DAY.WEDNESDAY:
      return 'Qua';
    case WEEK_DAY.THURSDAY:
      return 'Qui';
    case WEEK_DAY.FRIDAY:
      return 'Sex';
    case WEEK_DAY.SATURDAY:
      return 'Sab';
    default:
      return '';
  }
};

const getWeekDayLongString = value => {
  switch (value) {
    case WEEK_DAY.SUNDAY:
      return 'Domingo';
    case WEEK_DAY.MONDAY:
      return 'Segunda-feira';
    case WEEK_DAY.TUESDAY:
      return 'Terça-feira';
    case WEEK_DAY.WEDNESDAY:
      return 'Quarta-feira';
    case WEEK_DAY.THURSDAY:
      return 'Quinta-feira';
    case WEEK_DAY.FRIDAY:
      return 'Sexta-feira';
    case WEEK_DAY.SATURDAY:
      return 'Sábado';
    default:
      return '';
  }
};

const weekDayOptions = Object.freeze(
  Object.keys(WEEK_DAY).map(x => {
    const value = WEEK_DAY[x];
    return { value, label: getWeekDayLongString(value) };
  })
);

export default WEEK_DAY;
export { getWeekDayString, getWeekDayLongString, weekDayOptions };

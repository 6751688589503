// eslint-disable-next-line import/prefer-default-export
export function moneyMask(value) {
  let _value = -1;

  switch (typeof value) {
    case 'string': {
      const temp = Number.parseFloat(
        String(value)
          // eslint-disable-next-line no-useless-escape
          .replace(/[^\d\.,]/g, '')
          .replace(/,/, '.')
      );
      if (!Number.isNaN(temp)) {
        _value = temp;
      }
      break;
    }
    case 'number':
      _value = value;
      break;
    default:
      break;
  }

  if (_value < 0) {
    return undefined;
  }

  return `R$ ${_value.toFixed(2).replace('.', ',')}`;
}

import AgendaPage from '../pages/AgendaPage';
import ApplicationsPage from '../pages/ApplicationsPage';
import LoginPage from '../pages/LoginPage';
import ClientsPage from '../pages/ClientsPage';
import PaymentsPage from '../pages/PaymentsPage';

const routes = Object.freeze([
  {
    exact: true,
    path: '/',
    component: LoginPage,
    regex: /^\/$/,
  },
  {
    path: '/recover-password',
    component: LoginPage,
    regex: /^\/recover-password$/i,
  },
  {
    private: true,
    path: '/agenda',
    component: AgendaPage,
    regex: /^\/agenda$/i,
  },
  {
    private: true,
    path: '/students',
    component: ClientsPage,
    regex: /^\/students$/i,
  },
  {
    private: true,
    path: '/clients',
    component: ClientsPage,
    regex: /^\/clients$/i,
  },
  {
    private: true,
    path: '/payments',
    component: PaymentsPage,
    regex: /^\/payments$/i,
  },
]);

export default routes;

import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

const GuestRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => !!state.auth.token);

  if (isAuthenticated) dispatch(replace('/agenda'));

  return <Route {...rest} render={props => <Component {...props} />} />;
};

GuestRoute.propTypes = Route.propTypes;

export default GuestRoute;

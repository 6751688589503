import * as types from './types';

/* CRUD */
export const addPayment = payload => ({
  type: types.ADD_PAYMENT,
  payload,
});

export const removePayment = payload => ({
  type: types.REMOVE_PAYMENT,
  payload,
});

/* UI */
export const clearOrderPeson = () => ({
  type: types.CLEAR_ORDER_PAYMENT,
});

/* PAGINATION */
export const addPaginatedPayment = payload => ({
  type: types.ADD_PAGINATED_PAYMENT,
  payload,
});

export const changePaymentMaxPages = payload => ({
  type: types.CHANGE_MAX_PAGES_PAYMENT,
  payload,
});

export const changePaymentPage = payload => ({
  type: types.CHANGE_PAGE_PAYMENT,
  payload,
});

export const changePaymentPageSize = payload => ({
  type: types.CHANGE_PAGE_SIZE_PAYMENT,
  payload,
});

export const changePaymentSearchParam = payload => ({
  type: types.CHANGE_SEARCH_PARAM_PAYMENT,
  payload,
});

export const changePaymentTotal = payload => ({
  type: types.CHANGE_TOTAL_PAYMENT,
  payload,
});

export const changePaymentStatusParam = payload => ({
  type: types.CHANGE_PAY_STATUS_PARAM_PAYMENT,
  payload,
});

export const resetPaymentPaginationData = () => ({
  type: types.RESET_PAYMENT_PAGINATION_DATA,
});

export const resetPaymentStore = () => ({ type: types.RESET_PAYMENT_STORE });

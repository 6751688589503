import firebase from 'firebase';

class FirebaseCloudMessaging {
  constructor(serviceWorker) {
    if (FirebaseCloudMessaging.instance) {
      return FirebaseCloudMessaging.instance;
    }

    FirebaseCloudMessaging.instance = this;

    if (serviceWorker) {
      this.serviceWorker = serviceWorker;
    }

    this.initializeFirebase();

    return this;
  }

  initializeFirebase() {
    /* Loading .env variables */
    this.firebase = firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    });
  }

  async askForPermission() {
    let token;

    try {
      const messaging = this.firebase.messaging();

      messaging.useServiceWorker(this.serviceWorker);

      await messaging.requestPermission();
      token = await messaging.getToken();
      console.log('messaging token: ', token);

      /* FIXME Change where onMessage is called */

      messaging.onMessage(message => {
        console.log('message', message);
      });
    } catch (error) {
      console.log(error);
    }

    return token;
  }
}

export default FirebaseCloudMessaging;

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

import styles from './index.module.scss';

const Checkbox = props => {
  const { id, name, value, checked, onChange, label, style } = props;
  const ref = useRef(null);
  const [isChecked, setChecked] = useState(checked);

  const _onChange = event => {
    setChecked(!isChecked);
    if (onChange && typeof onChange === 'function') {
      onChange(event, { id, value, isChecked: !isChecked });
    }
  };

  return (
    <div className={styles['input-wrapper']} style={style}>
      <input
        ref={ref}
        type='checkbox'
        name={name}
        id={id}
        value={value}
        checked={isChecked}
        onChange={_onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

Checkbox.defaultProps = {
  value: undefined,
  checked: false,
  onChange: () => '',
  label: '',
  style: undefined,
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

/**
 *
 * @param {object} props
 * @param {{checked:boolean;id:string|number;value:string|number;label:string}[]} props.options
 * @param {string} [props.name]
 * @param {import('react').ChangeEventHandler<HTMLInputElement} [props.onChange]
 * @param {string} [props.error]
 * @param {{option: import('react').HTMLAttributes<HTMLDivElement>['style']}} [props.style]
 * @returns {JSX.Element}
 */
const CheckboxGroup = props => {
  const { options, name, onChange, error, style } = props;
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!options || !Array.isArray(options) || !options.length) return;
    const checkedArray = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];

      if (option && typeof option.checked === 'boolean') {
        if (option.checked) checkedArray.push(option.value);
      }
    }

    if (checkedArray.length) setSelected(checkedArray);
  }, []);

  if (!options || !Array.isArray(options) || !options.length) return null;

  const _onChange = (event, checkbox) => {
    let result = null;
    if (checkbox) {
      if (checkbox.isChecked) {
        result = [...selected, checkbox.value];
      } else {
        result = selected.filter(s => s !== checkbox.value);
      }
    }
    if (result) {
      setSelected(result);
      if (onChange && typeof onChange === 'function') {
        onChange(event, result);
      }
    }
  };

  /* Component to render error */
  let errorComponent = null;
  if (error) {
    /* In case type of error is a string. Render message */
    if (typeof error === 'string') {
      errorComponent = (
        <div style={{ paddingTop: 2 }}>
          <span style={{ lineHeight: 1, fontSize: 12, color: Colors.negative }}>
            {error}
          </span>
        </div>
      );
    }
  }

  const _options = [];
  for (let i = 0; i < options.length; i++) {
    const o = options[i];

    _options.push(
      <Checkbox
        key={`${name}_${o.id}`}
        id={`${name}_${o.id}`}
        name={name}
        value={o.value || o.id}
        checked={o.checked}
        onChange={_onChange}
        label={o.label}
        style={style?.option ?? undefined}
      />
    );
  }

  return (
    <>
      {_options}
      {errorComponent}
    </>
  );
};

CheckboxGroup.defaultProps = {
  onChange: () => '',
  error: null,
  style: undefined,
};

CheckboxGroup.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  style: PropTypes.shape({ option: PropTypes.object }),
  error: PropTypes.string,
};

export default CheckboxGroup;

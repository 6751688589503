import union from 'lodash/union';

import { fetchCourts } from '../routines';

const initialState = {};

const order = (state = initialState, action) => {
  switch (action.type) {
    case fetchCourts.SUCCESS:
      return union({}, state, action.payload.order);
    default:
      return state;
  }
};

export default order;

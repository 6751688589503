import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Badge = props => {
  const { title, color } = props;

  return (
    <div className={styles.badge}>
      <div className={styles.bg} style={{ background: color }} />
      <span className={styles.title} style={{ color }}>
        {title}
      </span>
    </div>
  );
};

Badge.defaultProps = {
  title: '',
  color: '',
};

Badge.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

export default Badge;

import { baseAPI } from '../../service/axios';

import toFormData from '../../utils/toFormData';

const cancelBookingRequest = async id => {
  const res = await baseAPI.post(
    'booking/request/cancel',
    toFormData({ booking_request_id: id })
  );

  return res.data;
};

export default cancelBookingRequest;

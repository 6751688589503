const objectDeepWalk = (obj, callback) => {
  if (!obj) return null;
  if (typeof callback !== 'function') return null;

  const isArray = Array.isArray(obj);

  const newObj = {};

  const keys = Object.keys(obj);
  const values = Object.values(obj);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = values[i];

    const newKey = callback(key);

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        const tempArray = [];
        for (let j = 0; j < value.length; j++) {
          const deepValue = value[j];

          if (typeof deepValue === 'object') {
            tempArray.push(objectDeepWalk(deepValue, callback));
          } else {
            tempArray.push(deepValue);
          }
        }

        newObj[newKey] = tempArray;
      } else if (!(value instanceof Date || value instanceof File)) {
        newObj[newKey] = objectDeepWalk(value, callback);
      }
    } else {
      newObj[newKey] = value;
    }
  }

  if (isArray) return Object.values(newObj);

  return newObj;
};

export default objectDeepWalk;

/**
 * Function to convert strings from pascal case to camel case
 *
 * @param {string} str
 *
 * @returns {string} Retuns snake case string
 */
const toSnakeCase = str => {
  if (!str) return '';

  const _str = String(str);

  return _str
    .replace(/([a-z])([A-Z])/g, (_, p1, p2) => `${p1}_${p2.toLowerCase()}`)
    .toLowerCase();
};

export default toSnakeCase;

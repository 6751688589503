import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

import SideDrawer from '../../containers/SideDrawer';
import IconButton from '../IconButton';
import Badge from '../Badge';

import Colors from '../../sass/_colors.scss';

const iconApprove = require('../../assets/svg/icon-approve.svg');
const iconCancel = require('../../assets/svg/icon-cancel.svg');

const conflicts = [
  {
    id: 1,
    type: 'Avulso',
    name: 'Lucas da Silva',
    schedule: 'Seg, Ter, Qua às 8h - 9h',
    timeStamp: '9h42',
  },
  {
    id: 2,
    type: 'Mensal',
    name: 'Lucas da Silva',
    schedule: 'Seg, Ter, Qua às 8h - 9h',
    timeStamp: '10h30',
  },
];

const handleTagColor = type => {
  let color = '';

  if (type) {
    switch (type) {
      case 'Avulso':
        color = Colors.accent;
        break;
      case 'Mensal':
        color = Colors.primary;
        break;
      default:
        break;
    }
  }
  return color;
};

const Conflict = props => {
  const { type, name, schedule, timeStamp } = props;

  return (
    <div className={styles.conflict}>
      <div className={styles.conflictRow}>
        <Badge title={type} color={handleTagColor(type)} />
        <div className={styles.timeStamp}>Feito às {timeStamp}</div>
      </div>

      <div className={styles.conflictRow}>
        <div className={styles.conflictColumn}>
          <div className={styles.name}>{name}</div>
          <div className={styles.schedule}>{schedule}</div>
        </div>
        <div className={styles.conflictColumn}>
          <div style={{ marginRight: 10 }}>
            <IconButton color={Colors.negative} icon={iconCancel} />
          </div>
          <IconButton color={Colors.positive} icon={iconApprove} />
        </div>
      </div>
    </div>
  );
};

const ConflictDrawer = props => {
  const { closeOnOutsideClick, onPressClose, isOpen } = props;

  return (
    <SideDrawer
      closeOnOutsideClick={closeOnOutsideClick}
      onPressClose={onPressClose}
      isOpen={isOpen}
    >
      <div className={styles.container}>
        <div className={styles['container-content']}>
          <header className={styles.header}>
            <div className={styles.title}>Conflito de Reserva</div>
            <div className={styles.subTitle}>Quadra 02 - 11 Dezembro 2020</div>
          </header>
          <div className={styles.conflictList}>
            {conflicts &&
              conflicts.length &&
              conflicts.map(data => (
                <Conflict
                  key={data.id}
                  type={data.type}
                  name={data.name}
                  schedule={data.schedule}
                  timeStamp={data.timeStamp}
                />
              ))}
          </div>
        </div>
      </div>
    </SideDrawer>
  );
};

Conflict.defaultProps = {
  type: '',
  name: '',
  schedule: '',
  timeStamp: '',
};

Conflict.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  schedule: PropTypes.string,
  timeStamp: PropTypes.string,
};

ConflictDrawer.defaultProps = {
  closeOnOutsideClick: false,
  onPressClose: () => {
    console.log('Button has to have an action');
  },
  isOpen: false,
};

ConflictDrawer.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  onPressClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ConflictDrawer;

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import RCalendar from 'react-calendar';

import Colors from '../../sass/_colors.scss';
import CalendarIcon from '../../assets/icons/CalendarIcon';

import './index.scss';
import classNames from 'classnames';

const formatShortWeekday = (locale, date) => {
  switch (date.getDay()) {
    case 0:
      return 'D';
    case 2:
      return 'T';
    case 3:
    case 4:
      return 'Q';
    case 1:
    case 5:
    case 6:
      return 'S';
    default:
      return '';
  }
};

const DatePicker = props => {
  const { onChange, date, error, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = e => {
    /* Handle outside click */
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    /* Add add listener to document */
    document.addEventListener('mousedown', handleClick);

    return () => {
      /* Remove listener from document on unmount */
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const _onChange = _date => {
    if (onChange && typeof onChange === 'function') onChange(_date);

    setIsOpen(false);
  };

  const _onClick = () => {
    if (disabled) return;
    setIsOpen(prev => !prev);
  };

  /* Label Placeholder */
  const label = React.useMemo(() => {
    const isValid = moment(date).isValid();

    if (isValid) return moment(date).format('LL');

    return 'Selecione uma data.';
  }, [date]);

  /* Check if date is an valid date */
  // eslint-disable-next-line
  const _date = React.useMemo(() => {
    // eslint-disable-next-line
    const _date = moment(date);

    if (_date.isValid()) return _date.toDate();

    return null;
  }, [date]);

  /* Component to render error */
  let errorComponent = null;

  if (error) {
    /* In case type of error is a string. Render message */
    if (typeof error === 'string') {
      errorComponent = (
        <div style={{ paddingTop: 2 }}>
          <span style={{ lineHeight: 1, fontSize: 12, color: Colors.negative }}>
            {error}
          </span>
        </div>
      );
    }
  }

  return (
    <div
      className={classNames({
        'react-date-picker-wrapper': true,
        disabled,
      })}
      ref={ref}
    >
      <button
        type='button'
        className='react-date-picker__action-button'
        onClick={_onClick}
      >
        {label}
        <CalendarIcon stroke={disabled ? '#adadad' : null} />
      </button>
      {isOpen && !disabled && (
        <RCalendar
          className='react-date-picker__calendar'
          onChange={_onChange}
          value={_date}
          formatShortWeekday={formatShortWeekday}
          locale='pt-br'
        />
      )}
      {errorComponent}
    </div>
  );
};

DatePicker.defaultProps = {
  onChange: () => '',
  date: new Date(),
  error: null,
  disabled: false,
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DatePicker;

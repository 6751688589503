import { format } from 'date-fns';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { v5 as uuidv5 } from 'uuid';

/** Utils */
import { toMappedObject } from '../../utils';

/** Routines */
import { fetchEvents as fetchEventsRoutine } from './routines';

/** Actions */
import { agendaSelectDate } from '../agenda/actions';
import * as personActions from '../person/actions';

/** Providers */
import fetchPayments from '../../providers/payments/fetchPayments';

/* NOTE: Random generated uuid just to be used as SEED */
const seed = 'cf159a9c-a89f-4b9a-a83b-0ab9c11cb4ac';

function* requestEvents(action) {
  try {
    const { payload } = action;

    yield all([
      put({ type: 'EVENT_CLEAR' }),
      put(fetchEventsRoutine.request()),
      put(agendaSelectDate(payload.date)),
    ]);

    yield delay(1000);

    const arenaID = yield select(state => state.config.activeArena);

    const date = format(payload.date, 'yyyy-MM-dd');

    const response = yield call(
      fetchPayments,
      arenaID,
      {
        ...payload,
        date,
        forceModel: true,
      },
      { version: 3 }
    );

    if (response != null && response.data.length) {
      /** Extracting person from inside event */
      const _events = [];
      const _persons = [];

      for (const element of response.data) {
        /** Split person from event */
        const {
          // applicant_id: applicantId,
          applicant_name: applicantName,
          applicant_email: applicantEmail,
          applicant_telephone: applicantTelephone,
          ...others
        } = element;

        const _str = [
          String(applicantName)
            .toLowerCase()
            .trim(),
          String(applicantEmail)
            .toLowerCase()
            .trim(),
          String(applicantTelephone)
            .toLowerCase()
            .trim(),
        ].join(`,`);

        const _id = uuidv5(_str, seed);

        _events.push({ ...others, applicant_id: _id });

        _persons.push({
          id: _id,
          name: applicantName,
          email: applicantEmail,
          telephone: applicantTelephone,
        });
      }

      const mappedEvents = toMappedObject(_events);
      const mappedPersons = toMappedObject(_persons);

      if (mappedEvents) {
        /** Insert events to store */
        yield put(fetchEventsRoutine.success(mappedEvents));
      }

      if (mappedPersons) {
        /** Insert persons to store */
        yield put(personActions.addPerson(mappedPersons));
      }
    }
  } catch (err) {
    yield put(fetchEventsRoutine.failure(err.message));
  } finally {
    yield put(fetchEventsRoutine.fulfill());
  }
}

export default function* eventSaga() {
  yield all([takeLatest(fetchEventsRoutine.TRIGGER, requestEvents)]);
}

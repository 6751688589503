import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import styles from './index.module.scss';

/* Motion Animation variants */
const item = {
  active: { color: '#fff' },
  inactive: { color: '#949494' },
};

const border = {
  inactive: {
    clipPath: 'circle(0% at 50% 50%)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  active: {
    clipPath: 'circle(100% at 50% 50%)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

/**
 * @component
 */
function TabItem(props) {
  const { id, label, onClick, isActive } = props;

  const handleClick = () => {
    onClick(id);
  };

  const styleAnimatedBorder = `${styles.border} ${styles.active}`;

  return (
    <motion.li
      key={id}
      className={styles['tab-header']}
      variants={item}
      onClick={handleClick}
      animate={isActive ? 'active' : 'inactive'}
    >
      {label}
      <motion.div variants={border} className={styleAnimatedBorder} />
    </motion.li>
  );
}

TabItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

/**
 * @component
 */
function TabSelector(props) {
  const { tabs, children } = props;

  const [active, setActive] = React.useState(tabs?.[0].id ?? null);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
      }}
    >
      <motion.ul className={styles.tabs}>
        {tabs.map(x =>
          TabItem({ ...x, onClick: setActive, isActive: active === x.id })
        )}
      </motion.ul>
      {children(active)}
    </div>
  );
}

TabSelector.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ),
  children: PropTypes.func,
};

TabSelector.defaultProps = {
  tabs: [],
  children: () => '',
};

export default TabSelector;

/**
 * Simple function to convert an hexadecimal color to rgba
 *
 * @param {string} hex - Hexadecimal color
 * @param {number} [opacity=1] - Opacity to apply. Value between 0 and 1
 */
const hexToRGBA = (hex, opacity = 1) => {
  /* Type guard function */
  if (!hex || typeof hex !== 'string') return hex;
  if (typeof opacity !== 'number') return hex;

  /* Value guard opacity */
  let _opacity = 1;
  if (opacity >= 0 && opacity <= 1) _opacity = opacity;

  /* Regex to validate hex. Hex HAS to start with '#' and has 3 or 6 valid hexadecimals */
  const isValidHex = String(hex).match(/^#(?:[0-9a-f]{3}|[0-9a-f]{6})$/i);

  if (!isValidHex) return hex;

  let _hex = hex.substring(1); // Remove #

  /* Complete hex with 3 positions */
  if (_hex.length === 3) {
    let temp = '';
    temp += `${_hex[0]}${_hex[0]}`;
    temp += `${_hex[1]}${_hex[1]}`;
    temp += `${_hex[2]}${_hex[2]}`;

    _hex = temp;
  }

  const r = parseInt(_hex.substring(0, 2), 16);
  const g = parseInt(_hex.substring(2, 4), 16);
  const b = parseInt(_hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${_opacity})`;
};

export default hexToRGBA;

import fetchArena from './fetchArena';

/**
 *
 * Envelopes fetchArena on a try catch so its possible to execute a promise.all call without problem
 *
 * @param {number} id
 *
 * @return {Promise<unknown> | number}
 */
const _fetchArena = async id => {
  try {
    return await fetchArena(id);
  } catch (ex) {
    return id;
  }
};

/**
 *
 * @param {number[]} ids
 *
 * @return {Promise<unknown>}
 */
const fetchArenaBatch = async (ids = []) => {
  const arenas = [];
  const failed = [];

  const { length } = ids;

  for (let i = 0; i < length; i += 12) {
    /* Creating chunks of 12 to send requests to server */
    const array = ids.slice(i, i + 12);

    /* Need to use await inside for loop because of limitations of https requests */
    // eslint-disable-next-line no-await-in-loop
    const res = await Promise.all(array.map(id => _fetchArena(id)));

    arenas.push(...res.filter(a => typeof a !== 'number'));
    failed.push(...res.filter(a => typeof a === 'number'));
  }

  return { arenas, failed };
};

export default fetchArenaBatch;

import classNames from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { EVENT_DURATION } from '../../constants/AppConstants';
import BookingType from '../../enum/bookingType';
import useArenaOrProfessor from '../../hooks/useArenaOrProfessor';
import {
  closeNewBookingDrawer,
  openClassDetailDrawer,
  openNewBookingDrawer,
  selectClassDetailId,
} from '../../store/sideDrawer/actions';

import Badge from '../Badge';

import styles from './index.module.scss';

export const EVENT_RECURRENCE = Object.freeze({
  SINGLE: 'single',
  MONTHLY: 'monthly',
  FREE: 'free',
});

const eventRecurrenceToBookingType = val => {
  switch (val) {
    case EVENT_RECURRENCE.MONTHLY:
      return BookingType.MONTHLY;
    case EVENT_RECURRENCE.SINGLE:
      return BookingType.SINGLE;
    case EVENT_RECURRENCE.FREE:
    default:
      return BookingType.VACANT;
  }
};

const formatDate = date => {
  let res = '';

  if (date && date instanceof Date) {
    const hour = `${String(date.getHours()).padStart(2, '0')}h`;
    const min = date.getMinutes()
      ? `${String(date.getMinutes()).padStart(2, '0')}min`
      : '';

    res = `${hour}${min}`;
  }

  return res;
};

const AgendaEvent = props => {
  const {
    background,
    courtId,
    end,
    id,
    recurrence,
    sport,
    start,
    user,
    isClass,
    duration,
    reservedArena,
  } = props;

  const caption = useArenaOrProfessor(
    <span className={styles.sport}>{sport}</span>,
    <span className={styles.sport}>{reservedArena}</span>
  );

  const smallCaption = useArenaOrProfessor(
    sport ? <span className={styles.sport}>{sport} - </span> : null,
    reservedArena ? (
      <span className={styles.sport}>{reservedArena} - </span>
    ) : null
  );

  const dispatch = useDispatch();

  const isVacant = /free/i.test(recurrence);

  const container = classNames({
    [styles.event]: true,
    [styles.class]: isClass,
    [styles[recurrence]]: !isClass && !isVacant,
    [styles[`no-bg-${recurrence}`]]: isVacant,
  });

  const badge = BookingType.getBadge(
    eventRecurrenceToBookingType(recurrence),
    isClass
  );

  const _openClassDetailDrawer = () => {
    if (!recurrence.match(/free/i)) {
      dispatch(closeNewBookingDrawer());
      dispatch(openClassDetailDrawer());
      dispatch(selectClassDetailId(id));
    } else {
      dispatch(
        openNewBookingDrawer({
          court: courtId,
          dateTime: start,
          bookingType: BookingType.SINGLE,
          modality: sport,
        })
      );
    }
  };

  return (
    <motion.div
      role='none'
      className={container}
      style={{ background }}
      onClick={_openClassDetailDrawer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.main}>
        <div className={styles.time}>
          <span>{`${formatDate(start)} - ${formatDate(end)}`}</span>
          <Badge {...badge} />
        </div>

        {duration === 30 ? (
          <div className={styles['about-small']}>
            {smallCaption}
            <span className={styles.user}>{user}</span>
          </div>
        ) : (
          <div className={styles.about}>
            {caption}
            <span className={styles.user}>{user}</span>
          </div>
        )}
      </div>
    </motion.div>
  );
};

AgendaEvent.defaultProps = {
  recurrence: 'free',
  user: '',
  reservedArena: '',
  sport: '',
  background: null,
  isClass: false,
  duration: EVENT_DURATION,
};

AgendaEvent.propTypes = {
  background: PropTypes.string,
  courtId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  /**
   * Identificador
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * Recorrencia do evento. Valores no enum EVENT_RECURRENCE
   */
  recurrence: PropTypes.oneOf(Object.values(EVENT_RECURRENCE)),
  /**
   * Modalide na qual a quadra foi reservada
   */
  sport: PropTypes.string,
  /**
   * Nome da arena da arena onde professor associou a reservas
   */
  reservedArena: PropTypes.string,
  start: PropTypes.instanceOf(Date).isRequired,
  /**
   * Nome do usuário
   */
  user: PropTypes.string,
  /**
   * Booleano para marcar como aula
   */
  isClass: PropTypes.bool,

  duration: PropTypes.number,
};

export default React.memo(AgendaEvent);

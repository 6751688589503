import { v2API } from '../../service/axios';
import { toFormData } from '../../utils';

/**
 * @typedef SingleBookingRequestPaymentResponse
 * @property {number} status
 * @property {string} response
 * @property {string} body_response
 * @property {string} link_pix
 * @property {string} chave_pix
 * @property {string} qrcode
 * @property {string} tempo_expiracao Expiration in minutes
 * @property {string} valor Document value
 */

/**
 * @param {string|number} id
 * @param {import("../../enum/paymentType").PaymentTypeValues} type
 * @param {number} price
 * @param {string} cpf
 * @returns {import('axios').AxiosPromise<SingleBookingRequestPaymentResponse>}
 */
export const single = (id, type, price, cpf) =>
  v2API.post(
    'pay_asaas/cobranca_avulsa',
    toFormData({
      booking_id: id,
      payment_type: type,
      booking_price: price,
      user_cpf: cpf,
    })
  );

export const monthly = () => {};

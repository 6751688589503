/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import Loading from '../Loading';

import styles from './index.module.scss';

const Button = props => {
  const {
    type,
    title,
    onClick,
    disabled,
    background,
    color,
    outline,
    loading,
    icon,
  } = props;

  const classname = classNames({
    [styles.button]: true,
    [styles.outline]: outline,
  });

  const _background = outline ? '' : background;
  const _color = outline ? background : color;

  return (
    <motion.button
      whileHover={!disabled && { scale: 1.1 }}
      whileTap={!disabled && { scale: 0.95 }}
      className={classname}
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{ background: _background, color: _color }}
    >
      <AnimatePresence exitBeforeEnter>
        {!loading ? (
          <motion.div
            className={styles.content}
            style={{ opacity: !loading ? 1 : 0, width: '100%' }}
            key='text'
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {icon}
            {title}
          </motion.div>
        ) : (
          <motion.div
            className={styles.loader}
            style={{ opacity: loading ? 1 : 0 }}
            initial={{ opacity: 0, x: '-100%', y: '-50%' }}
            animate={{ opacity: 1, x: '-50%', y: '-50%' }}
            exit={{ opacity: 0 }}
            key='loader'
          >
            <Loading size={70} fill={outline ? background : color} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.button>
  );
};

Button.defaultProps = {
  type: 'button',
  background: '',
  title: '',
  onClick: () => {},
  disabled: false,
  color: '',
  outline: false,
  loading: false,
  icon: null,
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  background: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  outline: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
};

export default Button;

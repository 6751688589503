import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

import Colors from '../../sass/_colors.scss';

/* Importing components */
import Checkbox from '../../components/Checkbox';
import { hexToRGBA } from '../../utils';

/* Checkbox filters choices */
const choices = Object.freeze([
  { id: 'monthly', label: 'Mensal', fill: Colors.accent },
  {
    id: 'single',
    label: 'Avulso',
    fill: Colors.primary,
    stroke: 'rgba(0, 0, 0, 1)',
  },
  { id: 'class', label: 'Aula', fill: Colors.class },
]);

/* Filters Component */
const Filters = React.memo(props => {
  const { filters, onChange } = props;

  const toggleFilter = (val, id) => {
    onChange({ ...filters, [id]: val });
  };

  return (
    <div className={styles.filter}>
      <span className={styles.title}>Filtros</span>
      <div className={styles.choices}>
        {choices.map(c => (
          <Checkbox
            key={c.id}
            id={c.id}
            label={c.label}
            stroke={c.stroke}
            fill={hexToRGBA(c.fill)}
            checked={filters[c.id]}
            onChange={toggleFilter}
          />
        ))}
      </div>
    </div>
  );
});

Filters.defaultProps = {
  filters: {},
  onChange: () => '',
};

Filters.propTypes = {
  filters: PropTypes.shape({
    monthly: PropTypes.bool,
    single: PropTypes.bool,
    class: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};

export default Filters;

/* eslint-disable import/prefer-default-export */
import * as types from './types';

/**
 * @typedef ToggleArenaActiveAction
 * @property {'TOGGLE_ACTIVE_ARENA'} type
 * @property {number} payload
 *
 * Function to launch event to change active arena
 *
 * @param {number} payload - Active arena ID
 *
 * @returns {ToggleArenaActiveAction}
 */
export const toggleActiveArena = payload => ({
  type: types.TOGGLE_ACTIVE_ARENA,
  payload,
});

/**
 * @typedef {ToggleArenaActiveAction} ConfigActions
 */

import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

const Loading = props => {
  const { size, fill, style } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
      display='block'
      style={style}
    >
      <circle cx={84} cy={50} r={0.256} fill={fill}>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='0.33333333333333326s'
          calcMode='spline'
          keyTimes='0;1'
          values='10;0'
          keySplines='0 0.5 0.5 1'
          begin='0s'
        />
        <animate
          attributeName='fill'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='discrete'
          keyTimes='0;0.25;0.5;0.75;1'
          values={fill}
          begin='0s'
        />
      </circle>
      <circle cx={83.13} cy={50} r={10} fill={fill}>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='0s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='0s'
        />
      </circle>
      <circle cx={16} cy={50} r={0} fill={fill}>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.33333333333333326s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.33333333333333326s'
        />
      </circle>
      <circle cx={16} cy={50} r={9.744} fill={fill}>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.6666666666666665s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.6666666666666665s'
        />
      </circle>
      <circle cx={49.13} cy={50} r={10} fill={fill}>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.9999999999999999s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.333333333333333s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.9999999999999999s'
        />
      </circle>
    </svg>
  );
};

Loading.defaultProps = { size: 100, fill: Colors.primary, style: null };

Loading.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
};

export default Loading;

import * as types from './types';

/* CRUD */
export const addBookingRequest = payload => ({
  type: types.ADD_BOOKING_REQUEST,
  payload,
});

export const removeBookingRequest = payload => ({
  type: types.REMOVE_BOOKING_REQUEST,
  payload,
});

/* UI */
export const clearOrderPeson = () => ({
  type: types.CLEAR_ORDER_BOOKING_REQUEST,
});

/* PAGINATION */
export const changeBookingRequestMaxPages = payload => ({
  type: types.CHANGE_MAX_PAGES_BOOKING_REQUEST,
  payload,
});

export const changeBookingRequestPage = payload => ({
  type: types.CHANGE_PAGE_BOOKING_REQUEST,
  payload,
});

export const changeBookingRequestPageSize = payload => ({
  type: types.CHANGE_PAGE_SIZE_BOOKING_REQUEST,
  payload,
});

export const changeBookingRequestSearchParam = payload => ({
  type: types.CHANGE_SEARCH_PARAM_BOOKING_REQUEST,
  payload,
});

export const changeBookingRequestTotal = payload => ({
  type: types.CHANGE_TOTAL_BOOKING_REQUEST,
  payload,
});

export const resetBookingRequestPaginationData = () => ({
  type: types.RESET_BOOKING_REQUEST_PAGINATION_DATA,
});

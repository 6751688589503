import { all, takeEvery, put } from 'redux-saga/effects';
import {
  closeConflictDrawer,
  closeNewBookingDrawer,
  closeClassDetailDrawer,
  closeBookingDetailDrawer,
  closeUserInfoDrawer,
} from '../sideDrawer/actions';

function* onLocationChange() {
  /* Close all drawers on route change */
  yield all([
    put(closeUserInfoDrawer()),
    put(closeConflictDrawer()),
    put(closeNewBookingDrawer()),
    put(closeClassDetailDrawer()),
    put(closeBookingDetailDrawer()),
  ]);
}

export default function* RouterSaga() {
  yield all([takeEvery('@@router/LOCATION_CHANGE', onLocationChange)]);
}

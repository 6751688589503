import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

export default function CalendarIcon(props) {
  const { fill, stroke, width, height } = props;

  return (
    <svg width={width} height={height} viewBox='0 0 29 27'>
      <g fill='none' fillRule='evenodd'>
        <path
          fill={fill}
          stroke={stroke ?? Colors.primary}
          strokeWidth={2}
          d='M1.11 10.344h26v-4a2 2 0 00-2-2h-22a2 2 0 00-2 2v4z'
        />
        <path
          fill='transparent'
          d='M17 3h5v2a2 2 0 01-2 2h-1a2 2 0 01-2-2V3zM6 3h5v2a2 2 0 01-2 2H8a2 2 0 01-2-2V3z'
        />
        <path
          fill={fill}
          stroke={stroke ?? Colors.primary}
          strokeWidth={2}
          d='M27.11 10.159h-26v12.994c0 1.089.997 2.006 2.265 2.006h21.47c1.27 0 2.265-.917 2.265-2.006V10.16z'
        />
        <path
          stroke={stroke ?? Colors.primary}
          strokeLinecap='round'
          strokeWidth={2}
          d='M19.5 4.379V1m-11 3.379V1'
        />
        <path
          stroke={stroke}
          strokeLinecap='square'
          strokeWidth={2}
          d='M1 9.5h26.686'
        />
      </g>
    </svg>
  );
}

CalendarIcon.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

CalendarIcon.defaultProps = {
  height: 27,
  width: 29,
  stroke: Colors.primary,
  fill: 'none',
};

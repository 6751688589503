import union from 'lodash/union';

import * as types from '../types';

const initialState = () => [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_PERSON:
      return union([], state, action.payload.order);
    case types.REMOVE_PERSON:
      return state.filter(id => id !== action.payload);
    case types.CLEAR_ORDER_PERSON:
      return [];
    case types.RESET_PERSON_STORE:
      return initialState();
    default:
      return state;
  }
};

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

/**
 *
 * @param {object} props
 * @param {string} props.id
 * @param {(val: string) => void} [props.onChange]
 * @param {() => void} [props.onClear]
 * @param {string} [props.value]
 * @param {string} [props.placeholder]
 * @returns
 */
const SearchInput = props => {
  const { id, onChange, onClear, value, placeholder } = props;

  const ref = useRef(null);

  const [_value, setValue] = useState(value || '');

  const _onChange = e => {
    setValue(e.target.value);
    return onChange(e.target.value);
  };

  const _onFocus = () => {
    ref.current.focus();
  };

  const _onBlur = () => {
    ref.current.blur();
  };

  const _onClear = () => {
    setValue('');
    onClear();
    return onChange('');
  };

  let clear = null;

  if (_value && _value.trim().length) {
    clear = (
      <div className={styles.clear} role='none' onClick={_onClear}>
        <span>x</span>
      </div>
    );
  }

  return (
    <div className={styles.search}>
      <input
        id={id}
        ref={ref}
        className={styles.input}
        type='text'
        placeholder={placeholder}
        value={_value}
        onChange={_onChange}
        onFocus={_onFocus}
        onBlur={_onBlur}
      />
      {clear}
    </div>
  );
};

SearchInput.defaultProps = {
  value: '',
  onChange: () => '',
  onClear: () => '',
  placeholder: 'Filtrar',
};

SearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SearchInput;

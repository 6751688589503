/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import { format, isSameDay, set } from 'date-fns';
import { getIn, useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VMasker from 'vanilla-masker';

import AutoCompleteInput from '../../components/AutoCompleteInput';
import Button from '../../components/Button';
import CheckboxGroup from '../../components/CheckboxGroup';
import ContainedInput from '../../components/ContainedInput/ContainedInput';
import DatePicker from '../../components/DatePicker';
import Input from '../../components/Input';
import RadioGroup from '../../components/Radio';
import Select from '../../components/Select';
import BookingType from '../../enum/bookingType';
import MonthlyChargeType from '../../enum/monthlyChargeType';
import PaymentType from '../../enum/paymentType';
import { playerLevelOptions } from '../../enum/playerLevel';
import usePrevious from '../../hooks/usePrevious';
import fetchUsers from '../../providers/user/fetchUsers';
import Colors from '../../sass/_colors.scss';
import {
  activeArenaSelector,
  arenaCourtOptionsSelector,
} from '../../store/arena/selectors';
import {
  courtModalityOptionsSelector,
  courtOpenHoursOptionsSelector,
} from '../../store/court/selector';
import { createEditBooking } from '../../store/httpRequest/routines';
import { delay, match, SafeParse } from '../../utils';

import { todayAtMidnight } from '../BookingForm/helpers';

import {
  bookingTypeOptions,
  config,
  INITIAL_MONTHLY_PRICE,
  monthlyPaymentTypes,
  paymentGenerationOptions,
  paymentOptions,
  styleWithError,
  styleWithoutError,
  usageOptions,
  validationSchema,
  weekDaysOptions,
} from './constants';
import classes from './index.module.scss';
import UserItem from './UserItem';

/* Local Selectors */
const isLoadingSelector = state => state.httpRequest.createEditBooking.loading;

/**
 *
 * @param {object} props
 * @param {object} [props.initialValues]
 * @param {number} [props.initialValues.id]
 * @param {boolean} [props.initialValues.bookingClass]
 * @param {0|1} [props.initialValues.bookingType]
 * @param {number} [props.initialValues.court]
 * @param {Date} [props.initialValues.dateTime]
 * @param {string} [props.initialValues.modality]
 * @param {string} [props.initialValues.name]
 * @param {string} [props.initialValues.phone]
 * @param {string} [props.initialValues.email]
 * @param {string} [props.initialValues.cpf]
 * @param {() => void} [props.onReset]
 * @returns
 */
function NewReservationForm(props, ref) {
  const { initialValues, onReset } = props;

  const dispatch = useDispatch();

  const user = useSelector(state => state.user.data);
  const arena = useSelector(activeArenaSelector);
  const isLoading = useSelector(isLoadingSelector);

  const isEditing = useMemo(() => initialValues.id != null, [initialValues]);

  const formik = useFormik({
    validationSchema,
    initialValues: {
      applicant: {
        /** @type {number | string} */
        id: null,
        name: '',
        email: '',
        phone: '',
        cpf: '',
      },
      booking: {
        /** @type {number | null} */
        id: null,
        /** @type {keyof typeof import('../../enum/playerLevel').default | null} */
        playerLevel: null,
        /** @type {string | null} */
        workArena: null,
        /** @type {boolean} */
        isClass: false,
        /** @type {0 | 1} */
        type: BookingType.SINGLE,
        /** @type {number} */
        court: null,
        /** @type {Date} */
        date: todayAtMidnight(),
        /** @type {string} */
        modality: null,
        /** @type {string} - Time HH:mm:ss */
        schedule: '',
        /* Monthly exclusive */
        weekDays: '',
        scheduleBegin: todayAtMidnight(),
        /** @type {Date} */
        scheduleEnd: null,
      },
      payment: {
        /** @type {'-1' | '0' | '1'} */
        isEnabled: '0', // -1 does not apply | 0 disabled | 1 enabled
        /** @type {'0' | '1' | '2'} */
        type: PaymentType.AT_LOCATION,
        /** @type {0 | 1} */
        monthlyChargeType: MonthlyChargeType.BY_MONTH,
        /** @type {string} */
        value: SafeParse.money(0),
      },
    },
    onSubmit: val => {
      const {
        applicant: { cpf, email, id: applicantId, name, phone, search },
        booking: {
          id: bookingId,
          isClass,
          type: bookingType,
          court: courtId,
          playerLevel,
          modality: bookingModality,
          workArena: instructorReservation,
          date,
          schedule,
          weekDays,
          scheduleBegin,
          scheduleEnd,
        },
        payment: {
          type: paymentType,
          value: paymentPrice,
          isEnabled,
          monthlyChargeType,
        },
      } = val;

      const dateFormat = 'yyyy-MM-dd';

      const data = {
        bookingId, // Booking id for editing
        applicantId,
        applicantName: name && String(name).trim() !== '' ? name : search,
        applicantEmail: email,
        applicantTelephone: phone.replace(/\D/g, ''),
        applicantCpf: cpf.replace(/\D/g, ''),
        courtId,
        bookingClass: isClass,
        bookingType,
        playerLevel,
        instructorReservation,
        bookingModality,
      };

      /* Quando editando reserva nunca será mensal */
      if (BookingType.isMonthly(bookingType) && bookingId == null) {
        /* Campos para a reserva mensal */
        data.weekEntries = weekDays.split(',').map(weekDay => ({
          weekDay,
          dayTime: schedule,
        }));
        data.bookingScheduleBegin = format(scheduleBegin, dateFormat);
        data.bookingScheduleEnd = format(scheduleEnd, dateFormat);

        /* Tipo de cobrança exclusiva de mensalista */
        data.payTipoCobranca = Number(monthlyChargeType);
      } else {
        /**
         * Essa linha garante que se for uma edição de reserva mensal será enviado
         * ao backend que é uma reserva avulsa, pois o sistema não permite
         * edição de reserva mensais.
         */
        data.bookingType = BookingType.SINGLE;
        /* Campos para a reserva avulsa */
        data.bookingDatetime = `${format(date, dateFormat)} ${schedule}`;
      }

      const price = SafeParse.number(VMasker.toNumber(paymentPrice) / 100, 0);

      if (isEnabled === '1' && price > 0) {
        /* Pagamento habilitado */
        data.payValor = price;
        data.payTipo = paymentType;
      } else {
        /* Pagamento não habilitado, força como pagar no local se valor for 0 (zero) */
        data.payValor = 0;
        data.payTipo = PaymentType.AT_LOCATION;
      }

      dispatch(createEditBooking(data));
    },
  });

  /** @type {typeof formik.values} */
  const previous = usePrevious(formik.values);

  const previousInit = usePrevious(initialValues);

  useEffect(() => {
    (async () => {
      /* Força a espera de tudo renderizar para montar os valores iniciais. */
      await delay(100);

      if (initialValues != null && !_.isEqual(previousInit, initialValues)) {
        /** @type {typeof formik.values} */
        const update = {
          applicant: {},
          booking: {},
          payment: {},
        };

        const {
          id,
          bookingClass,
          bookingType,
          court,
          dateTime,
          modality,

          /* Applicant */
          name,
          phone,
          cpf,
          email,
        } = initialValues;

        if (name != null) {
          update.applicant.name = name;
        }

        if (phone != null) {
          update.applicant.phone = SafeParse.phone(phone);
        }

        if (cpf != null) {
          update.applicant.cpf = SafeParse.cpf(cpf);
        }

        if (email != null) {
          update.applicant.email = email;
        }

        if (id != null) {
          update.booking.id = id;
        }

        if (modality != null) {
          update.booking.modality = modality;
        }

        if (bookingClass != null) {
          update.booking.isClass = bookingClass;
        }

        if (bookingType != null) {
          update.booking.type = bookingType;
        }

        if (court != null) {
          update.booking.court = court;
        }

        if (dateTime != null) {
          let temp = dateTime;
          if (typeof dateTime === 'string') {
            temp = new Date(String(temp).replace(/\s/, 'T'));
          }

          const time = format(temp, 'HH:mm:ss');
          const date = set(temp, {
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });
          update.booking.date = date;
          update.booking.schedule = time;
        }

        if (Object.keys(update).length) {
          /* Atualiza todas as informações de uma única vez */
          formik.setValues({
            applicant: { ...formik.values.applicant, ...update.applicant },
            booking: { ...formik.values.booking, ...update.booking },
            payment: { ...formik.values.payment, ...update.payment },
          });
        }
      }
    })();
  }, [initialValues]);

  const onResetHandler = () => {
    // eslint-disable-next-line no-unused-expressions
    onReset?.();
    autoCompleteClearHandler();
    setTimeout(() => {
      formik.resetForm();
    }, 0);
  };

  /* Computed */
  const isInstructor = useMemo(
    () => String(user.role).match(/Professor|Instructor/i),
    [user]
  );

  const paymentTitle = useMemo(() => {
    if (
      BookingType.isMonthly(formik.values.booking.type) &&
      formik.values.payment.monthlyChargeType === MonthlyChargeType.BY_BOOKING
    ) {
      return 'Insira o valor que será cobrado por aula';
    }

    return 'Insira o valor da cobrança total';
  }, [formik.values.payment.monthlyChargeType, formik.values.booking.type]);

  const courtOptions = useSelector(state =>
    arenaCourtOptionsSelector(state, arena.id)
  );

  const modalityOptions = useSelector(state =>
    courtModalityOptionsSelector(state, formik.values.booking.court)
  );

  const scheduleOptions = useSelector(state => {
    const singleScheduleOptions = () => {
      const weekday = formik.values.booking.date.getDay();
      return courtOpenHoursOptionsSelector(
        state,
        formik.values.booking.court,
        weekday
      );
    };

    const monthlyScheduleOptions = () => {
      if (formik.values.booking.weekDays === '') return [];

      const weekdays = formik.values.booking.weekDays.split(',');
      return courtOpenHoursOptionsSelector(
        state,
        formik.values.booking.court,
        weekdays
      );
    };

    /* Quando editando reserva mensal se comporta como reserva avulsa */
    if (isEditing) {
      return singleScheduleOptions();
    }

    return match(formik.values.booking.type, {
      [BookingType.MONTHLY]: monthlyScheduleOptions(),
      [BookingType.SINGLE]: singleScheduleOptions(),
    });
  });

  /* Input Handlers */
  /** @param {import('react').ChangeEvent<HTMLInputElement>} event */
  const phoneOnChangeHandler = event =>
    formik.setFieldValue(
      'applicant.phone',
      SafeParse.phone(event.target.value)
    );

  /** @param {import('react').ChangeEvent<HTMLInputElement>} event */
  const cpfOnChangeHandler = event => {
    formik.setFieldValue('applicant.cpf', SafeParse.cpf(event.target.value));
  };

  const handleMoney = event => {
    formik.setFieldValue('payment.value', SafeParse.money(event.target.value));
  };

  const handleDateChange = (date = null) => {
    handleGenericDateChange(date, 'booking.date');
  };

  /* Handle monthly schedule begin change */
  const handleBeginScheduleChange = (date = null) => {
    handleGenericDateChange(date, 'booking.scheduleBegin');
  };

  /* Handle monthly schedule end change */
  const handleEndScheduleChange = (date = null) => {
    handleGenericDateChange(date, 'booking.scheduleEnd');
  };

  const handleWeekDaysChange = (_, _weekDays) => {
    let value = '';

    if (_weekDays && _weekDays.length) {
      /* Sort weekdays to always have same csv when selecting the same options */
      value = _weekDays.sort().join(',');
    }

    /* Reset schedule when selecting another weekday */
    if (value !== formik.values.weekDays) {
      formik.setFieldValue('booking.weekDays', value);
    }
  };

  const autoCompleteOnSelectHandler = ({ id, name, email, phone, cpf }) => {
    formik.setValues({
      ...formik.values,
      applicant: {
        id: id ?? '',
        name: name ?? '',
        email: email ?? '',
        phone: SafeParse.phone(phone),
        cpf: SafeParse.cpf(cpf),
      },
    });
  };

  const autoCompleteClearHandler = () => {
    formik.setValues({
      ...formik.values,
      applicant: { id: null, name: '', email: '', phone: '', cpf: '' },
    });
  };

  /**
   * Generic function to handle select change
   * @param {string | number | { value: string | number }} value
   * @param {string | { name: string }} name
   */
  const onSelectChangeHandler = (value, name) => {
    let _name = name;
    let _value = value;

    if (typeof value === 'object') _value = value.value;
    if (typeof name === 'object') _name = name.name;

    if (_name) {
      formik.setFieldValue(_name, _value);
    }
  };

  /**
   * Generic function to handle date change
   * @param {Date} value
   * @param {string} name
   */
  const handleGenericDateChange = (value = null, name = null) => {
    if (value && name) {
      /* Check date by timestamp. Comparing Date object is always different */
      const saved = getIn(formik.values, name);
      if (!Number.isNaN(value.getTime())) {
        if (!saved || value.getTime() !== saved.getTime()) {
          /* Reset schedule if day changes */
          formik.setFieldValue(name, value);
        }
      }
    }
  };

  /* Getters */
  const getSelectedWorkArena = useMemo(() => {
    if (user && Array.isArray(user.works)) {
      const found = user.works.find(
        x => x.id === formik.values.booking.workArena
      );

      if (found) {
        const { id, title } = found;
        return { value: id, label: title };
      }
    }

    return null;
  }, [user, formik.values.booking.workArena]);

  const getSelectedPlayerOption = useMemo(
    () =>
      formik.values.booking.playerLevel != null
        ? playerLevelOptions.find(
            x => x.value === formik.values.booking.playerLevel
          )
        : null,

    [formik.values.booking.playerLevel]
  );

  const getSelectedUsage = React.useMemo(() => {
    return usageOptions.find(x => x.value === formik.values.booking.isClass);
  }, [formik.values.booking.isClass]);

  const getSelectedCourt = useMemo(
    () => courtOptions.find(x => x.value === formik.values.booking.court),
    [formik.values.booking.court, courtOptions]
  );

  const getSelectedModality = useMemo(
    () => modalityOptions.find(x => x.value === formik.values.booking.modality),
    [formik.values.booking.modality, modalityOptions]
  );

  const getSelectedBookingType = useMemo(
    () => bookingTypeOptions.find(x => x.value === formik.values.booking.type),
    [formik.values.booking.type]
  );

  const getSelectedSchedule = useMemo(
    () => scheduleOptions.find(x => x.value === formik.values.booking.schedule),
    [formik.values.booking.schedule, scheduleOptions]
  );

  const getSelectedPayment = useMemo(
    () => paymentOptions.find(x => x.value === formik.values.payment.type),
    [formik.values.payment.type]
  );

  /* Mapped options */
  const workArenaOptions = useMemo(
    () => user.works.map(x => ({ label: x.title, value: x.id })),
    [user]
  );

  /* Errors */
  const isErrorName =
    formik.touched.applicant?.name && formik.errors.applicant?.name;
  const isErrorEmail =
    formik.touched.applicant?.email && formik.errors.applicant?.email;
  const isErrorPhone =
    formik.touched.applicant?.phone && formik.errors.applicant?.phone;
  const isErrorCPF =
    formik.touched.applicant?.cpf && formik.errors.applicant?.cpf;
  const isErrorSingleDate =
    formik.touched.booking?.date && formik.errors.booking?.date;
  const isErrorMonthlyBegin =
    formik.touched.booking?.scheduleBegin &&
    formik.errors.booking?.scheduleBegin;
  const isErrorMonthlyEnd =
    formik.touched.booking?.scheduleEnd && formik.errors.booking?.scheduleEnd;
  const isErrorMonthlyWeekDays =
    formik.touched.booking?.weekDays && formik.errors.booking?.weekDays;

  /**
   * Aqui será feito as comparações para ver o que deve
   * ser alterado de forma programatica quando outro campo
   * é alterado
   */
  useEffect(() => {
    const { values } = formik;

    /** @type {typeof formik.values.applicant} */
    const newApplicant = {};
    /** @type {typeof formik.values.booking} */
    const newBooking = {};
    /** @type {typeof formik.values.payment} */
    const newPayment = {};

    function disablePayment(_isMonthly = undefined) {
      /* Arena only accepts payment at location if isEnabled is -1 */
      const isMonthly =
        _isMonthly ?? BookingType.isMonthly(values.booking.type);

      if (
        PaymentType.isAtLocation(PaymentType.getValidValue(arena?.payTipo)) &&
        !(isMonthly && arena.hasWalletToken)
      ) {
        newPayment.isEnabled = '-1';
      } else {
        newPayment.isEnabled = '0';
      }
      newPayment.type = PaymentType.AT_LOCATION;
      newPayment.value = SafeParse.money(0);
    }

    /**
     * Function return if schedule option exists for that date
     * @returns {boolean}
     */
    function changePaymentValue() {
      const option = scheduleOptions.find(
        ({ value }) => value === values.booking.schedule
      );

      if (option != null) {
        if (values.payment.isEnabled === '1') {
          if (BookingType.isSingle(values.booking.type)) {
            newPayment.value = SafeParse.money(
              SafeParse.number(option.single, 0),
              true
            );
          } else if (BookingType.isMonthly(values.booking.type)) {
            if (
              formik.values.payment.monthlyChargeType ===
              MonthlyChargeType.BY_BOOKING
            ) {
              /* Caso seja cobrado por aula pega valor adicionado no painel wordpress */
              newPayment.value = SafeParse.money(
                SafeParse.number(option.monthly, 0),
                true
              );
            } else {
              /* Caso seja mensal adicionar valor padrão hardcoded no sistema */
              newPayment.value = SafeParse.money(INITIAL_MONTHLY_PRICE, true);
            }
          }
        }

        return true;
      }

      newPayment.value = SafeParse.money(0);
      return false;
    }

    /**
     * Verifica se Gerar Cobrança mudou de valor
     */
    if (
      previous?.payment == null ||
      previous?.payment?.isEnabled !== values.payment.isEnabled
    ) {
      if (values.payment.isEnabled === '1') {
        /**
         * Nesta linha ele adiciona a modalidade de pagamento selecionado pela arena
         * quando pagamento é habilitado
         */
        if (
          BookingType.isMonthly(values.booking.type) &&
          arena.hasWalletToken
        ) {
          /**
           * Se o tipo de reserva for mensal e a arena
           * tiver configurado o token de pagamento
           * força tipo de pagamento para valor
           * integral
           */
          newPayment.type = PaymentType.FULL;
        } else {
          /**
           * Caso o tipo de reserva for avulsa
           * forma de pagamento segue o valor preferido
           * pela arena, que foi configurado no backoffice
           */
          newPayment.type = PaymentType.getValidValue(arena.payTipo);
        }
        changePaymentValue();
      } else {
        disablePayment();
      }
    }

    /* Verifica se tipo de agendamento mudou */
    if (
      previous?.booking?.type != null &&
      previous?.booking?.type !== values.booking.type
    ) {
      /* Ao trocar de mensal para avulso desativa pagamento */
      disablePayment();

      if (BookingType.isSingle(values.booking.type)) {
        /* Nova resversa é avulsa, limpa dados da mensal, e copia dia escolhido do mensal pro inicio da reserva */
        newBooking.date = values.booking.scheduleBegin;
        newBooking.weekDays = '';
        newBooking.scheduleEnd = null;
        newBooking.scheduleBegin = todayAtMidnight();
      } else {
        newBooking.date = todayAtMidnight();
        newBooking.weekDays = '';
        newBooking.scheduleEnd = null;
        newBooking.scheduleBegin = values.booking.date;
      }
    }

    /* Verifica se usuário mudou a data de reserva avulsa */
    if (BookingType.isSingle(values.booking.type)) {
      if (!isSameDay(previous?.booking?.date, values.booking.date)) {
        /* Verifica se horário existe na nova data. Se não existir limpar o valor de horário  */
        if (!changePaymentValue()) {
          newBooking.schedule = '';
        }
      }

      if (previous?.booking?.schedule !== values.booking.schedule) {
        changePaymentValue();
      }
    } else if (
      BookingType.isMonthly(values.booking.type) &&
      values.booking.weekDays !== ''
    ) {
      if (
        previous?.booking?.weekDays !== values.booking.weekDays ||
        previous?.booking?.schedule !== values.booking.schedule ||
        previous?.payment?.monthlyChargeType !==
          values.payment.monthlyChargeType
      ) {
        changePaymentValue();
      }
    }

    setTimeout(() => {
      if (
        Object.keys(newApplicant).length ||
        Object.keys(newBooking).length ||
        Object.keys(newPayment).length
      ) {
        /* Atualiza todas as informações de uma única vez */
        formik.setValues({
          applicant: { ...formik.values.applicant, ...newApplicant },
          booking: { ...formik.values.booking, ...newBooking },
          payment: { ...formik.values.payment, ...newPayment },
        });
      }
    }, 0);
  }, [formik.values, previous, arena, scheduleOptions]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      autoCompleteClearHandler();
      setTimeout(() => {
        formik.resetForm();
      }, 0);
    },
  }));

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Dados do aplicante */}
      <div className={classes.applicantContent}>
        <div className={classes.title}>Cadastro de Reserva</div>

        <div style={isErrorName ? styleWithError : styleWithoutError}>
          <AutoCompleteInput
            id='applicantSearch'
            name='applicant.name'
            disabled={isEditing}
            debounceFn={getUsers}
            debounceTime={500}
            placeholder='Nome'
            onChange={formik.handleChange}
            renderItem={renderItem}
            renderSelectedText={renderName}
            onValueSelection={autoCompleteOnSelectHandler}
            onClear={autoCompleteClearHandler}
            error={isErrorName}
            value={formik.values.applicant.name}
          />
        </div>

        <Input
          id='email'
          name='applicant.email'
          type='email'
          placeholder='E-mail'
          disabled={isEditing}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.applicant.email}
          error={isErrorEmail}
          style={isErrorEmail ? styleWithError : styleWithoutError}
        />

        <Input
          id='phone'
          name='applicant.phone'
          type='text'
          placeholder='Telefone'
          disabled={isEditing}
          onChange={phoneOnChangeHandler}
          onBlur={formik.handleBlur}
          value={formik.values.applicant.phone}
          error={isErrorPhone}
          style={isErrorPhone ? styleWithError : styleWithoutError}
        />

        <Input
          id='cpf'
          name='applicant.cpf'
          type='text'
          placeholder='CPF'
          disabled={isEditing}
          onChange={cpfOnChangeHandler}
          value={formik.values.applicant.cpf}
          onBlur={formik.handleBlur}
          error={isErrorCPF}
          style={isErrorCPF ? styleWithError : styleWithoutError}
        />
      </div>

      <div className={classes.hr} />

      {/* Dados da reserva */}
      <div className={classes.bookingContent}>
        {isInstructor && workArenaOptions.length > 0 ? (
          <InputWrapper title='Espaço esportivo'>
            <Select
              id='workArena'
              name='booking.workArena'
              options={workArenaOptions}
              value={getSelectedWorkArena}
              onChange={onSelectChangeHandler}
            />
          </InputWrapper>
        ) : null}

        {isInstructor ? (
          <InputWrapper title='Nível'>
            <Select
              id='playerLevel'
              name='booking.playerLevel'
              options={playerLevelOptions}
              value={getSelectedPlayerOption}
              onChange={onSelectChangeHandler}
            />
          </InputWrapper>
        ) : null}

        {!isInstructor ? (
          <InputWrapper title='Utilização'>
            <Select
              id='bookingClass'
              name='booking.isClass'
              options={usageOptions}
              value={getSelectedUsage}
              onChange={onSelectChangeHandler}
            />
          </InputWrapper>
        ) : null}

        <InputWrapper title={isInstructor ? 'Vaga' : 'Quadra'}>
          <Select
            id='court'
            name='booking.court'
            options={courtOptions}
            value={getSelectedCourt}
            onChange={onSelectChangeHandler}
            error={
              formik.touched.booking?.court && formik.errors.booking?.court
            }
          />
        </InputWrapper>

        {modalityOptions.length > 0 ? (
          <InputWrapper title='Modalidade'>
            <Select
              id='modality'
              name='booking.modality'
              options={modalityOptions}
              value={getSelectedModality}
              onChange={onSelectChangeHandler}
              error={
                formik.touched.booking?.modality &&
                formik.errors.booking?.modality
              }
              disabled={isEditing}
            />
          </InputWrapper>
        ) : null}

        <InputWrapper title='Tipo'>
          <Select
            id='bookingType'
            name='booking.type'
            options={bookingTypeOptions}
            value={getSelectedBookingType}
            onChange={onSelectChangeHandler}
            error={formik.touched.booking?.type && formik.errors.booking?.type}
            disabled={isEditing}
          />
        </InputWrapper>

        <AnimatePresence exitBeforeEnter>
          {BookingType.isMonthly(formik.values.booking.type) && !isEditing ? (
            <motion.div
              className={classes.selectGroup}
              key='monthly'
              {...config}
            >
              <InputWrapper title='Início'>
                <DatePicker
                  id='scheduleBegin'
                  name='scheduleBegin'
                  date={formik.values.booking.scheduleBegin}
                  onChange={handleBeginScheduleChange}
                  error={isErrorMonthlyBegin}
                />
              </InputWrapper>

              <InputWrapper title='Término'>
                <DatePicker
                  id='scheduleEnd'
                  name='scheduleEnd'
                  date={formik.values.booking.scheduleEnd}
                  onChange={handleEndScheduleChange}
                  error={isErrorMonthlyEnd}
                />
              </InputWrapper>

              <InputWrapper title='Dias da semana'>
                <CheckboxGroup
                  id='weekDays'
                  name='weekDays'
                  value={formik.values.booking.weekDays}
                  options={weekDaysOptions}
                  onChange={handleWeekDaysChange}
                  error={isErrorMonthlyWeekDays}
                />
              </InputWrapper>
            </motion.div>
          ) : (
            <motion.div
              className={classes.selectGroup}
              key='single'
              {...config}
            >
              <span>Dia</span>
              <DatePicker
                id='date'
                name='booking.date'
                date={formik.values.booking.date}
                onChange={handleDateChange}
                error={isErrorSingleDate}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <InputWrapper title='Horário'>
          <Select
            id='schedule'
            name='booking.schedule'
            options={scheduleOptions}
            value={getSelectedSchedule}
            onChange={onSelectChangeHandler}
            error={
              formik.touched.booking?.schedule &&
              formik.errors.booking?.schedule
            }
          />
        </InputWrapper>
      </div>

      {!isEditing ? (
        <>
          <div className={classes.hr} />

          {/* Dados do pagamento */}
          <div className={classes.paymentContent}>
            {formik.values.payment.isEnabled !== '-1' ? (
              <InputWrapper title='Gerar cobrança?' gap={24}>
                <RadioGroup
                  name='payment.isEnabled'
                  gap={16}
                  options={paymentGenerationOptions}
                  value={formik.values.payment.isEnabled}
                  onChange={formik.handleChange}
                />
              </InputWrapper>
            ) : null}

            <InputWrapper title='Pagamento'>
              <Select
                id='paymentType'
                name='payment.type'
                options={paymentOptions}
                value={getSelectedPayment}
                disabled
              />
            </InputWrapper>

            {BookingType.isMonthly(formik.values.booking.type) &&
            formik.values.payment.isEnabled === '1' ? (
              <InputWrapper title='Modelo de cobrança mensal' gap={24}>
                <RadioGroup
                  name='payment.monthlyChargeType'
                  gap={16}
                  options={monthlyPaymentTypes}
                  value={formik.values.payment.monthlyChargeType}
                  onChange={formik.handleChange}
                />
              </InputWrapper>
            ) : null}

            {formik.values.payment.isEnabled === '1' ? (
              <InputWrapper title={paymentTitle}>
                <ContainedInput
                  onChange={handleMoney}
                  value={formik.values.payment.value}
                />
              </InputWrapper>
            ) : null}
          </div>
        </>
      ) : null}

      <div className={classes.actionButtons}>
        <Button
          outline
          background={Colors.negative}
          title='Cancelar'
          onClick={onResetHandler}
          disabled={isLoading}
        />

        <Button
          type='submit'
          title={isEditing ? 'Editar reserva' : 'Criar reserva'}
          loading={isLoading}
        />
      </div>
    </form>
  );
}

NewReservationForm.propTypes = {
  initialValues: PropTypes.shape({
    // Booking
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bookingClass: PropTypes.bool,
    bookingType: PropTypes.oneOf([BookingType.MONTHLY, BookingType.SINGLE]),
    court: PropTypes.number,
    dateTime: PropTypes.instanceOf(Date),
    modality: PropTypes.string,

    // Applicant
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    cpf: PropTypes.string,
  }),
  onReset: PropTypes.func,
};

NewReservationForm.defaultProps = {
  initialValues: null,
  onReset: null,
};

/* Local components */

/**
 * @component
 *
 * @description Input Wrapper
 */
const InputWrapper = ({ title, children, gap }) => (
  <div className={classes.selectGroup}>
    <span style={{ marginBottom: gap }}>{title}</span>
    {children}
  </div>
);

InputWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

InputWrapper.defaultProps = { children: null, gap: 8 };

const renderName = el => el.name;

const getUsers = async search => {
  let users = [];

  try {
    const res = await fetchUsers({ search, page_size: -1 });

    if (res) users = res.data.data;
  } catch (ex) {
    console.error(ex);
  }

  return users;
};

const renderItem = (el, idx, arr) => {
  const { length } = arr;

  const { name, email, phone, cellphone } = el;

  return (
    <>
      <UserItem name={name} email={email} phone={phone} cellphone={cellphone} />
      {idx < length - 1 && <hr />}
    </>
  );
};

export default forwardRef(NewReservationForm);

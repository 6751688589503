import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';

import * as R from './routines';
import * as A from './actions';
import * as P from '../../providers/people';

function* fetchPeople(action) {
  try {
    yield delay(750);

    yield put(R.fetchPeople.request());

    const { page = 1, search: s = undefined } = action.payload;

    /* Get active arena  */
    const id = yield select(state => state.config.activeArena);

    const { perPage, search } = yield select(state => state.person.pagination);

    const regex = new RegExp(String(s), 'i');

    if ((s && !String(search).match(regex)) || (!s && search)) {
      // console.log('search are diferent');
      yield all([
        put(A.resetPersonPaginationData()),
        put(A.changePersonSearchParam(s)),
      ]);
    }

    /* Passing arguments to fetchPeople provider */
    const request = yield call(P.fetchPeople, id, {
      page,
      per_page: perPage,
      search: s ?? search,
    });

    const { total, page: returnedPage, page_size: pageSize, data } = request;

    yield all([
      put(A.addPaginatedPerson(data)),
      put(A.changePersonPage(returnedPage)),
      put(A.changePersonPageSize(pageSize)),
      put(A.changePersonTotal(total)),
    ]);
  } catch (ex) {
    yield put(R.fetchPeople.failure());
  } finally {
    yield put(R.fetchPeople.fulfill());
  }
}

export default function* PeopleSaga() {
  yield all([takeLatest(R.fetchPeople, fetchPeople)]);
}

import { v2API } from '../../service/axios';
import { toFormData } from '../../utils';

/**
 * @typedef { import('../../model/NewBookingRequest.model').NewBookingRequest } NewBookingRequest
 */

/**
 * Function to request creation of a new booking
 *
 * @param {NewBookingRequest} values
 *
 * @returns {Promise<*>}
 */
const postNewBooking = async values => {
  const formData = toFormData(values);

  const res = await v2API.post('/booking/save', formData);

  return res.data;
};

export default postNewBooking;

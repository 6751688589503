import { throwMissingValue } from '../utils';

/**
 * @typedef {Object} ResourceType
 * @property {string} [sport] - Sport name
 * @property {string} [user] - User name
 * @property {'monthly'|'single'|'free'} recurrence - Event recurrence
 * @property {boolean} [isClass=false] - Boolean booking class
 */

/**
 * @typedef {Object} AgendaEventType
 * @property {number} id - Object identifier
 * @property {string} title - Event title
 * @property {Date} start - Date and hour of event start
 * @property {Date} end - Date and hour of event end
 * @property {string} resourceId - Identifier to vinculate with agenda resource. (Column)
 * @property {ResourceType} resource - Custom data passed to event
 */

/**
 * Checking object for resource keys
 *
 * @param {ResourceType} resource
 *
 * @returns {string} Return missing key from Resource
 */
const isResourceMissingKey = resource => {
  const { hasOwnProperty } = Object.prototype;

  if (!hasOwnProperty.call(resource, 'recurrence')) return 'recurrence';

  return '';
};

/**
 * Class representing a agenda event.
 *
 * @class AgendaEvent
 */
class AgendaEvent {
  /**
   * Creates an instance of AgendaEvent.
   * @param {AgendaEventType} obj
   * @memberof AgendaEvent
   */
  constructor(obj) {
    const { id, title = '', start, end, resourceId, resource } = obj;

    if (!id) throwMissingValue('id');
    if (!start) throwMissingValue('start');
    if (!end) throwMissingValue('end');
    if (resourceId === null || resourceId === undefined)
      throwMissingValue('resourceId');

    if (!(start instanceof Date))
      throw new Error('start has to be a date object');

    if (!(end instanceof Date)) throw new Error('end has to be a date object');

    const missingKey = isResourceMissingKey(resource);
    if (missingKey)
      throw new Error(`Key ${missingKey} is missing in resource object`);

    /* Normal way to assigne values */
    /* this.id = id;
    this.title = title;
    this.start = start;
    this.end = end;
    this.resourceId = resourceId;
    this.resource = resource; */

    /* Read-only way */
    Object.defineProperty(this, 'id', {
      value: id,
      writable: false,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(this, 'title', {
      value: title,
      writable: false,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(this, 'start', {
      value: start,
      writable: false,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(this, 'end', {
      value: end,
      writable: false,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(this, 'resourceId', {
      value: resourceId,
      writable: false,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(this, 'resource', {
      value: resource,
      writable: false,
      enumerable: true,
      configurable: true,
    });
  }
}

export default AgendaEvent;

/**
 * @typedef ErrorLoadingState
 * @property {'LOADING'|'ERROR'} status
 * @property {null} data
 */

/**
 * @typedef IdleState
 * @property {'IDLE'} status
 * @property {object} data
 * @property {string} data.applicantEmail
 * @property {number} data.applicantId
 * @property {string} data.applicantName
 * @property {string} data.applicantTelephone
 * @property {string} data.applicantCpf
 * @property {number} data.arenaId
 * @property {boolean} data.bookingClass
 * @property {string} data.bookingDate
 * @property {number} data.bookingStatus
 * @property {'Avulsa'|'Mensal'} data.bookingTypeLabel
 * @property {import("../../enum/bookingType").BookingType} data.bookingType
 * @property {number} data.courtId
 * @property {number} data.id
 * @property {string} data.modality
 * @property {Array<number>} data.weekDay
 * @property {Array<string>} data.weekTime
 * @property {string} [data.bookingInstructorReservation]
 * @property {string} data.bookingPlayerLevel
 * @property {import("../../enum/paymentStatus").PaymentStatus} data.payStatus
 * @property {import("../../enum/paymentType").PaymentType} data.payTipo
 * @property {string} data.valor
 */

/** @typedef {ErrorLoadingState | IdleState} State */

/** @returns {State} */
export const initialState = () => ({
  /** @var {'IDLE' | 'LOADING' | 'ERROR'} */
  status: 'LOADING',
  data: null,
});

export const createActions = dispatch => ({
  /** @returns {void} */
  fetch: () => dispatch({ type: 'FETCH' }),
  /** @returns {void} */
  fail: () => dispatch({ type: 'FAILED' }),
  /**
   * @param {IdleState['data']} payload
   * @returns {void}
   */
  success: payload => dispatch({ type: 'SUCCESSFUL', payload }),
});

/**
 *
 * @param {State} initialState
 * @param {{ type: string, payload?: any }} action
 * @returns {State}
 */
export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case 'FETCH':
      if (state.status === 'LOADING') return state;
      return { status: 'LOADING', data: null };
    case 'FAILED':
      if (state.status === 'ERROR') return state;
      return { status: 'ERROR', data: null };
    case 'SUCCESSFUL':
      if (state.status === 'IDLE') return state;
      return { status: 'IDLE', data: action.payload };
    default:
      return state;
  }
};

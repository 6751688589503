import PropTypes from 'prop-types';
import React from 'react';

import defaultImage from '../../assets/image/logo512.png';
import useImage from '../../hooks/useImage';

import CircularLoader from '../CircularLoader/CircularLoader';

import classes from './index.module.scss';

/**
 *
 * @param {object} props
 * @param {string} [props.url]
 * @returns
 */
export default function Avatar(props) {
  const { url } = props;
  const [src, isLoading] = useImage(url, defaultImage);

  return (
    <div className={classes.avatar}>
      <img src={src} alt='avatar' className={classes.image} />

      {isLoading && (
        <div className={classes.loader}>
          <CircularLoader fontSize={4} />
        </div>
      )}
    </div>
  );
}

Avatar.propTypes = {
  url: PropTypes.string,
};

Avatar.defaultProps = {
  url: undefined,
};

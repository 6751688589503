import axios from 'axios';

const baseURL = process.env.REACT_APP_API;

const authAPI = axios.create({
  baseURL: `${baseURL}/jwt-auth/v1/token`,
});

const baseAPI = axios.create({
  baseURL: `${baseURL}/api/v1/`,
});

const v2API = axios.create({
  baseURL: `${baseURL}/api/v2/`,
});

const v3API = axios.create({
  baseURL: `${baseURL}/api/v3/`,
});

const reqInterceptor = [
  /** @param {import('axios').AxiosRequestConfig} config */
  config => {
    const token = window.localStorage.getItem('AUTHENTICATION_BEARER');
    if (token != null && String(token).trim() !== '') {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
];

const resInterceptor = [
  response => response,
  error => {
    if (axios.isAxiosError(error)) {
      if (error.response.status === 401) {
        window.localStorage.removeItem('AUTHENTICATION_BEARER');
      }
    }

    return Promise.reject(error);
  },
];

baseAPI.interceptors.request.use(...reqInterceptor);
baseAPI.interceptors.response.use(...resInterceptor);
v2API.interceptors.request.use(...reqInterceptor);
v2API.interceptors.response.use(...resInterceptor);
v3API.interceptors.request.use(...reqInterceptor);
v3API.interceptors.response.use(...resInterceptor);

/**
 *
 * @param {number} version
 * @returns {import('axios').AxiosInstance}
 * @throws
 */
// eslint-disable-next-line consistent-return
function getApi(version = 1) {
  if (version <= 0 || version > 3) {
    throw new Error('Invalid API version');
  }

  // Default case will never be reached
  // eslint-disable-next-line default-case
  switch (version) {
    case 1:
      return baseAPI;
    case 2:
      return v2API;
    case 3:
      return v3API;
  }
}

export { authAPI, baseAPI, v2API, v3API, getApi };

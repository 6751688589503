import { baseAPI } from '../../service/axios';
import { toFormData } from '../../utils';

const editBooking = async values => {
  const formData = toFormData(values);

  const res = await baseAPI.post(`/booking/${values.id}/edit`, formData);

  return res.data;
};

export default editBooking;

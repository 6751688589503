import { useEffect, useMemo, useReducer } from 'react';

/**
 * @typedef {'idle' | 'loading' | 'error' | 'successful'} State
 */

/**
 *
 * @param {*} src
 * @param {*} dep
 * @returns
 */

export default function useImage(src, placeholder) {
  // eslint-disable-next-line no-use-before-define
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'SET_LOADING' });

    const image = new Image();

    image.onload = function onLoad() {
      dispatch({ type: 'SET_SUCCESSFUL', payload: src });
    };

    image.onerror = function onError() {
      dispatch({ type: 'SET_ERROR' });
    };

    image.src = src;
  }, [src]);

  const isLoading = useMemo(() => state.state === 'loading', [state]);

  return [state.src ?? placeholder, isLoading];
}

const initialState = Object.freeze({
  /** @type {string | undefined} */
  src: undefined,
  /** @type {State} */
  state: 'idle',
});

/**
 *
 * @param {typeof initialState} state
 * @param {any} action
 * @return {typeof initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, state: 'loading' };
    case 'SET_ERROR':
      return { state: 'error' };
    case 'SET_SUCCESSFUL':
      return { src: action.payload, state: 'successful' };
    default:
      return state;
  }
};

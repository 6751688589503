import { combineReducers } from 'redux';

import * as types from './types';

const token = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATE_OK:
      return action.payload;
    case types.AUTHENTICATE_ERROR:
      return null;
    case types.UNAUTHENTICATE:
      return null;
    default:
      return state;
  }
};

const initUI = { request: false, error: false };
const ui = (state = initUI, action) => {
  switch (action.type) {
    case types.AUTHENTICATE:
      return { request: true, error: false };
    case types.AUTHENTICATE_OK:
      return { request: false, error: false };
    case types.AUTHENTICATE_ERROR:
      return { request: false, error: true };
    default:
      return state;
  }
};

export default combineReducers({ token, ui });

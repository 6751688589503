import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast, ToastType } from 'react-toastify';
import PropTypes from 'prop-types';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { baseAPI } from '../../service/axios';
import objectToFormData from '../../utils/toFormData';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email('Não é um e-mail válido.')
    .required('Campo obrigatório.'),
});

const RecoverPasswordForm = ({ onSuccess }) => {
  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: '',
    },
    onSubmit: async values => {
      try {
        await baseAPI.post(
          'user/recover',
          objectToFormData({ ...values, w: 'U2V1IGhpa2VyIGhhY2tlciE=' })
        );
        toast(
          'Dentro de alguns instantes você receberá o email de confirmação.',
          { type: ToastType.SUCCESS }
        );

        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess();
        }
      } catch (ex) {
        toast('Desculpe, mas não foi possível realizar esta solicitação.', {
          type: ToastType.ERROR,
        });
      }
    },
  });

  return (
    <>
      <Input
        id='email'
        name='email'
        type='email'
        placeholder='E-mail'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email}
        style={{ marginBottom: 15 }}
      />
      <Button
        title='Enviar'
        type='submit'
        onClick={formik.handleSubmit}
        loading={formik.isSubmitting}
      />
    </>
  );
};

RecoverPasswordForm.propTypes = {
  onSuccess: PropTypes.func,
};

RecoverPasswordForm.defaultProps = {
  onSuccess: undefined,
};

export default RecoverPasswordForm;

import Colors from '../sass/_colors.scss';

/** @typedef {-1 | 0 | 1} BookingTypeValues */

/**
 * @typedef BookingType
 * @property {-1} VACANT
 * @property {0} SINGLE
 * @property {1} MONTHLY
 * @property {(val: any) => boolean} isVacant
 * @property {(val: any) => boolean} isSingle
 * @property {(val: any) => boolean} isMonthly
 * @property {(val: BookingTypeValues) => string} getLabel
 * @property {(val: BookingTypeValues) => string} getColor
 * @property {(val: BookingTypeValues, isClass = false) => { color:string; title:string; }} getBadge
 * @property {(val: BookingTypeValues) => { id:string; value:string; label:string; }[]} getOptions
 */

/** @type {BookingType} */
const _bookingType = {
  VACANT: -1, // Local value only
  SINGLE: 0,
  MONTHLY: 1,
};

Object.defineProperty(_bookingType, 'isVacant', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_bookingType.VACANT);
  },
});

Object.defineProperty(_bookingType, 'isSingle', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_bookingType.SINGLE);
  },
});

Object.defineProperty(_bookingType, 'isMonthly', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_bookingType.MONTHLY);
  },
});

Object.defineProperty(_bookingType, 'getColor', {
  /**
   * @param {BookingTypeValues} val
   * @returns {string}
   */
  value(val) {
    switch (String(val)) {
      case String(_bookingType.VACANT):
        return Colors.positive;
      case String(_bookingType.SINGLE):
        return Colors.primary;
      case String(_bookingType.MONTHLY):
        return Colors.accent;
      default:
        return '';
    }
  },
});

Object.defineProperty(_bookingType, 'getLabel', {
  /**
   * @param {BookingTypeValues} val
   * @returns {string}
   */
  value(val) {
    switch (String(val)) {
      case String(_bookingType.VACANT):
        return 'Livre';
      case String(_bookingType.SINGLE):
        return 'Avulsa';
      case String(_bookingType.MONTHLY):
        return 'Mensal';
      default:
        return '';
    }
  },
});

Object.defineProperty(_bookingType, 'getBadge', {
  /**
   * @param {BookingTypeValues} val
   * @param {boolean} [isClass=false]
   * @returns {{ color: string; title: string; }}
   */
  value(val, isClass) {
    if (isClass) {
      return { title: 'Aula', color: Colors.class };
    }

    return {
      color: _bookingType.getColor(val),
      title: _bookingType.getLabel(val),
    };
  },
});

Object.defineProperty(_bookingType, 'getOptions', {
  /**
   * @returns {{ id: string; value: string; label: string; }[]}
   */
  value() {
    return Object.entries(_bookingType).map(([id, value]) => ({
      id,
      value,
      label: _bookingType.getLabel(value),
    }));
  },
});

/** @deprecated */
export const getBookingTypeString = value => {
  switch (value) {
    case _bookingType.VACANT:
      return 'Livre';
    case _bookingType.SINGLE:
      return 'Avulsa';
    case _bookingType.MONTHLY:
      return 'Mensal';
    default:
      return '';
  }
};

/** @deprecated */
export const getBookingTypeColor = value => {
  switch (value) {
    case _bookingType.VACANT:
      return Colors.positive;
    case _bookingType.SINGLE:
      return Colors.primary;
    case _bookingType.MONTHLY:
      return Colors.accent;
    default:
      return '';
  }
};

/** @deprecated */
export const getBookingTypeBadge = value => ({
  title: getBookingTypeString(value),
  color: getBookingTypeColor(value),
});

/** @deprecated */
export const bookingTypeOptions = Object.freeze(
  Object.keys(_bookingType).map(x => {
    const value = _bookingType[x];
    return { value, label: getBookingTypeString(value) };
  })
);

/** @type {BookingType} */
const BookingType = Object.freeze(_bookingType);

export default BookingType;

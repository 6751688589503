import React, { useEffect, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';

import usePrevious from '../../hooks/usePrevious';
import useOutsideClick from '../../hooks/useOutsideClick';

import Input from '../Input';
import CircularLoader from '../CircularLoader/CircularLoader';

import {
  reducer,
  initialState,
  changeValue,
  showResults,
  hideResults,
  request,
  fulfill,
  success,
  setFocus,
  clear as actionClear,
  select as actionSelect,
} from './reducer';

import classes from './index.module.scss';
import useDebouncedEffect from '../../hooks/useDebouncedEffect';
import CloseIcon from '../../assets/icons/CloseIcon';

// eslint-disable-next-line react/prop-types
const Item = React.memo(({ el, idx, arr, onValueSelection, renderItem }) => {
  const handleSelection = () => onValueSelection(el);

  return (
    <div
      tabIndex={0}
      role='button'
      onClick={handleSelection}
      onKeyPress={handleSelection}
    >
      {renderItem(el, idx, arr)}
    </div>
  );
});

const AutoCompleteInput = props => {
  const {
    id,
    name,
    placeholder,
    onBlur,
    renderItem,
    debounceFn,
    debounceTime,
    onValueSelection,
    maxResultHeight,
    onClear,
    renderSelectedText,
    error,
    disabled,
  } = props;

  /** @type {[initialState, function]} */
  const [state, dispatch] = useReducer(reducer, initialState());

  const { value, loading, clear, data, isOpen, focus, selected } = state;

  const ref = useRef(null);
  useOutsideClick(ref, () => dispatch(hideResults()));

  // eslint-disable-next-line react/destructuring-assignment
  const prevPropVal = usePrevious(props.value);

  useEffect(() => {
    /* On prop change check if is different from previous */
    if (prevPropVal !== props.value) dispatch(changeValue(props.value));

    // eslint-disable-next-line react/destructuring-assignment
  }, [props.value, prevPropVal]);

  const handleFocus = () => {
    dispatch(showResults());
    dispatch(setFocus(true));
  };

  const handleClear = () => {
    dispatch(actionClear());
    onClear();
  };

  const handleSelection = el => {
    onValueSelection(el);
    dispatch(actionSelect(el));
    dispatch(hideResults());
  };

  const handleChange = e => {
    dispatch(changeValue(e.target.value));
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const handleItemRendering = (el, idx, arr) => (
    <Item
      key={el?.id ?? idx}
      el={el}
      idx={idx}
      arr={arr}
      onValueSelection={handleSelection}
      renderItem={renderItem}
    />
  );

  const handleRequest = async () => {
    if (disabled) return;
    if (value) {
      dispatch(hideResults());
      dispatch(request());
      try {
        const res = await debounceFn(value);

        if (res) {
          dispatch(success(res));
          dispatch(showResults());
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        dispatch(fulfill());
      }
    }
  };

  const handleValue = () => {
    if (focus) return value;

    if (renderSelectedText && typeof renderSelectedText === 'function') {
      if (selected !== null) {
        const res = renderSelectedText(selected);

        if (typeof res === 'string') return res;
      }
    }

    return value;
  };

  useDebouncedEffect(handleRequest, debounceTime, [value]);

  let resultsComponent = null;
  if (isOpen && data.length) {
    resultsComponent = (
      <div className={classes.results} style={{ maxHeight: maxResultHeight }}>
        {data.map(handleItemRendering)}
      </div>
    );
  }

  return (
    <div ref={ref} className={classes.wrapper}>
      <Input
        id={id}
        name={name}
        type='text'
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        value={handleValue}
        error={error}
        disabled={disabled}
        rightComponent={
          <>
            {loading && <CircularLoader />}
            {clear && !disabled && (
              <div
                className={classes['close-btn']}
                tabIndex='0'
                role='button'
                onClick={handleClear}
                onKeyPress={handleClear}
              >
                <CloseIcon />
              </div>
            )}
          </>
        }
        onFocus={handleFocus}
      />
      {resultsComponent}
    </div>
  );
};

AutoCompleteInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  debounceFn: PropTypes.func,
  debounceTime: PropTypes.number,
  error: PropTypes.string,
  maxResultHeight: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onValueSelection: PropTypes.func,
  placeholder: PropTypes.string,
  renderItem: PropTypes.func,
  renderSelectedText: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

AutoCompleteInput.defaultProps = {
  id: 'auto-complete',
  name: 'auto-complete',
  debounceFn: async value => console.log(value),
  debounceTime: 1000,
  error: null,
  maxResultHeight: 300,
  onBlur: () => '',
  onChange: () => '',
  onClear: () => '',
  onValueSelection: el => console.log(el),
  placeholder: '',
  renderItem: (el, idx) => <div key={idx}>{JSON.stringify(el)}</div>,
  renderSelectedText: null,
  value: '',
  disabled: false,
};

export default AutoCompleteInput;

import Colors from '../sass/_colors.scss';

/** @typedef {'0' | '1' | '2'} PaymentStatusValues */

/**
 * @typedef PaymentStatus
 * @property {'0'} PENDING
 * @property {'1'} PAID
 * @property {'2'} CANCELLED
 * @property {(val: any) => boolean} isPending
 * @property {(val: any) => boolean} isPaid
 * @property {(val: any) => boolean} isCancelled
 * @property {(val: PaymentStatusValues) => string} getLabel
 * @property {(val: PaymentStatusValues) => string} getColor
 * @property {(val: PaymentStatusValues) => {color:string;title:string;}} getBadge
 * @property {() => { label: string; value: string; id: string; }[]} getOptions
 */

/** @type {PaymentStatus} */
const _paymentStatus = {
  PENDING: '0',
  PAID: '1',
  CANCELLED: '2',
};

Object.defineProperty(_paymentStatus, 'isPending', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_paymentStatus.PENDING);
  },
});

Object.defineProperty(_paymentStatus, 'isPaid', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_paymentStatus.PAID);
  },
});

Object.defineProperty(_paymentStatus, 'isCancelled', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_paymentStatus.CANCELLED);
  },
});

Object.defineProperty(_paymentStatus, 'getColor', {
  /**
   * @param {PaymentStatusValues} val
   * @returns {string}
   */
  value(val) {
    switch (String(val)) {
      case '0':
        return Colors.warning;
      case '1':
        return Colors.positive;
      case '2':
        return Colors.negative;
      default:
        return '';
    }
  },
});

Object.defineProperty(_paymentStatus, 'getLabel', {
  /**
   * @param {PaymentStatusValues} val
   * @returns {string}
   */
  value(val) {
    switch (String(val)) {
      case '0':
        return 'Pagamento Pendente';
      case '1':
        return 'Pago';
      case '2':
        return 'Cancelado';
      default:
        return '';
    }
  },
});

Object.defineProperty(_paymentStatus, 'getBadge', {
  /**
   * @param {PaymentStatusValues} val
   * @returns {{ color: string; title: string; }}
   */
  value(val) {
    return {
      color: _paymentStatus.getColor(val),
      title: _paymentStatus.getLabel(val),
    };
  },
});

Object.defineProperty(_paymentStatus, 'getOptions', {
  /**
   * @returns {{ label: string; value: string; id: string }[]}
   */
  value() {
    return Object.values(_paymentStatus).map(x => ({
      id: x,
      value: x,
      label: _paymentStatus.getLabel(x),
    }));
  },
});

const PaymentStatus = Object.freeze(_paymentStatus);

export default PaymentStatus;

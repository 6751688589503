import { motion } from 'framer-motion';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';
import Colors from '../../sass/_colors.scss';
import { fetchPayments } from '../../store/payment/routines';
import { openPayInfoDrawer } from '../../store/sideDrawer/actions';

import PaymentStatus from '../../enum/paymentStatus';
import PaymentType from '../../enum/paymentType';
import styles from './index.module.scss';
import Row from './Row';
import Table from './Table';

const opacity0 = { opacity: 0 };
const opacity1 = { opacity: 1 };

const mapRows = (row, action) => {
  const handleClick = () => action(row);

  let title;
  let icon;
  let outline = true;

  if (
    PaymentStatus.isPending(row.pay_status) &&
    PaymentType.isAtLocation(row.pay_tipo)
  ) {
    title = 'Recebido';
    outline = false;
    icon = (
      <div style={{ marginRight: 8 }}>
        <img
          src={require('../../assets/svg/icon-money.svg')}
          alt='money-symbol'
        />
      </div>
    );
  } else if (PaymentStatus.isCancelled(row.pay_status)) {
    title = 'Nova reserva';
  } else {
    title = 'Ver detalhes';
  }

  return (
    <Row
      bookingDatetime={row.booking_date}
      bookingType={row.booking_type}
      name={row.applicant_name}
      paymentStatus={row.pay_status}
      actions={
        <div className={styles.actions}>
          <Button
            {...{ title, icon, outline }}
            onClick={handleClick}
            background={Colors.positive}
            color='#fff'
          />
        </div>
      }
    />
  );
};

const PaymentsContainer = () => {
  const dispatch = useDispatch();

  const activePage = useSelector(state => state.payment.pagination.page);
  const isLoading = useSelector(state => state.payment.ui.loading);
  const list = useSelector(state => state.payment.data);

  const handleOptionsClick = value => dispatch(openPayInfoDrawer(value));

  const handleRowsMap = r => mapRows(r, handleOptionsClick);

  const _onSelectPage = page => {
    dispatch(fetchPayments({ page }));
  };

  const pageSize = useSelector(state => state.payment.pagination.pageSize);
  const total = useSelector(state => state.payment.pagination.total);
  const totalPages = !total ? 1 : Math.ceil(total / pageSize);

  const firstLength = 1 + (activePage - 1) * pageSize;
  let lastLength = activePage * pageSize;
  if (lastLength > total) lastLength = total;

  return (
    <>
      {!isLoading && list && list.length ? (
        <motion.div initial={opacity0} animate={opacity1} exit={opacity0}>
          <Table>{list.map(handleRowsMap)}</Table>

          <footer className={styles.footer}>
            <div className={styles['empty-wrapper']} />

            <div className={styles['pagination-wrapper']}>
              <Pagination
                pages={totalPages}
                activePage={activePage}
                onSelectPage={_onSelectPage}
              />
            </div>

            {total >= 1 && (
              <div className={styles['orders-length-indicator']}>
                {`${firstLength} a ${lastLength} de ${total} pagamento(s)`}
              </div>
            )}
          </footer>
        </motion.div>
      ) : null}

      {isLoading && (
        <motion.div
          className={styles.loader}
          initial={opacity0}
          animate={opacity1}
          exit={opacity0}
        >
          <Loading />
        </motion.div>
      )}
    </>
  );
};

export default PaymentsContainer;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import PaymentStatus from '../../enum/paymentStatus';

import FilterButton from '../FilterButton/FilterButton';

/** @type {React.CSSProperties} */
const style = { marginRight: '0.5rem' };

/**
 *
 * @param {object} props
 * @param {(value: string[]) => void} props.onChange
 * @returns {JSX.Element}
 */
export default function PaymentStatusFilterButton(props) {
  const { onChange } = props;

  const options = useMemo(() => PaymentStatus.getOptions(), []);

  return <FilterButton {...{ onChange, style, options }} />;
}

PaymentStatusFilterButton.propTypes = {
  onChange: PropTypes.func.isRequired,
};

PaymentStatusFilterButton.defaultProps = {};

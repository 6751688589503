import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import agenda from './agenda';
import app from './app';
import arena from './arena';
import auth from './auth';
import bookingRequest from './bookingRequest';
import config from './config';
import court from './court';
import event from './event';
import httpRequest from './httpRequest';
import person from './person';
import sideDrawer from './sideDrawer';
import user from './user';
import payment from './payment';
import { UNAUTHENTICATE } from './auth/types';

// Configure persistence
const persistConfig = {
  key: 'weplayapp-arena:auth',
  storage,
  whitelist: ['token'],
};

const createRootReducer = history => {
  const reducers = combineReducers({
    agenda,
    app,
    arena,
    auth: persistReducer(persistConfig, auth),
    bookingRequest,
    config,
    court,
    event,
    httpRequest,
    payment,
    person,
    router: connectRouter(history),
    sideDrawer,
    user,
  });

  return (state = {}, action) => {
    switch (action.type) {
      case UNAUTHENTICATE:
        // eslint-disable-next-line no-param-reassign
        state = undefined; // Reset store
      // HAS to fallthrought
      default:
        return reducers(state, action);
    }
  };
};

export default createRootReducer;

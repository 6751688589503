/* eslint-disable no-use-before-define */
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastPosition, ToastType } from 'react-toastify';
import VMasker from 'vanilla-masker';

import CheckboxGroup from '../../components/CheckboxGroup';
import ContainedInput from '../../components/ContainedInput/ContainedInput';
import DatePicker from '../../components/DatePicker';
import RadioGroup from '../../components/Radio';
import Select from '../../components/Select';
import BookingType from '../../enum/bookingType';
import MonthlyChargeType from '../../enum/monthlyChargeType';
import PaymentType from '../../enum/paymentType';
import PLAYER_LEVEL, { playerLevelOptions } from '../../enum/playerLevel';
import { weekDayOptions } from '../../enum/weekDay';
import {
  activeArenaSelector,
  arenaCourtOptionsByModalitySelector,
  arenaCourtOptionsSelector,
} from '../../store/arena/selectors';
import {
  courtModalityOptionsSelector,
  courtOpenHoursOptionsSelector,
} from '../../store/court/selector';
import { match, SafeParse } from '../../utils';

import {
  config,
  DISABLE_MODALITY,
  INITIAL_MONTHLY_PRICE,
  INITIAL_SINGLE_PRICE,
  monthlyPaymentTypes,
  shouldGeneratePaymentOptions,
  usageOptions,
} from './constants';
import { buildInitialDate, todayAtMidnight } from './helpers';
import styles from './index.module.scss';
import validationSchema from './validationSchema';

/**
 * @component
 *
 * @description Input Wrapper
 */

const InputWrapper = ({ title, children, gap }) => (
  <div className={styles['select-group']}>
    <span style={{ marginBottom: gap }}>{title}</span>
    {children}
  </div>
);

InputWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InputWrapper.defaultProps = { gap: 8 };

const bookingTypeOptions = BookingType.getOptions().filter(
  x => x.value !== BookingType.VACANT
);

/**
 * @component
 *
 * @description Component to render a Booking form. It can receive initial values
 */
const BookingForm = props => {
  const { initialValues, bindSubmit, onSubmit, lock } = props;
  const activeArena = useSelector(activeArenaSelector);

  /* Building formik initial date values */
  const initBookingDate = React.useMemo(
    () => buildInitialDate(initialValues),
    []
  );

  const formik = useFormik({
    validationSchema,
    initialValues: {
      playerLevel: initialValues.playerLevel || null,
      workArena: initialValues.workArena || null,
      bookingClass: initialValues.bookingClass || false,
      bookingType: initialValues.bookingType || BookingType.SINGLE,
      court: initialValues.court || null,
      date: initBookingDate.date || todayAtMidnight(),
      modality: initialValues.modality || null,
      schedule: initBookingDate.schedule || '',
      weekDays: initBookingDate.weekDays || '',
      scheduleBegin: initBookingDate.scheduleBegin || todayAtMidnight(),
      scheduleEnd: null,
      price: SafeParse.money(INITIAL_SINGLE_PRICE),
      paymentType: PaymentType.getOption(PaymentType.AT_LOCATION),
      /* Meta data */
      meta: {
        /** @type {'0' | '1'} */
        generatePayment: '0',
        /** @type {0 | 1} */
        monthlyPaymentBy: 1,
      },
    },
    onSubmit: values => {
      if (onSubmit && typeof onSubmit === 'function') {
        const {
          playerLevel,
          workArena,
          bookingClass,
          modality,
          court,
          bookingType,
          date,
          weekDays,
          schedule,
          scheduleBegin,
          scheduleEnd,
          price,
          paymentType,
          meta: { monthlyPaymentBy, generatePayment },
        } = values;

        const tempBooking = {
          bookingClass,
          bookingType,
          /* Renaming */
          courtId: court,
          payTipo: Number(paymentType?.value),
          payValor: match(generatePayment, {
            '0': 0,
            '1': Number(VMasker.toNumber(price)) / 100,
          }), // Convert cents to decimal
        };

        if (modality !== DISABLE_MODALITY) {
          tempBooking.bookingModality = modality;
        }

        if (workArena != null) {
          tempBooking.instructorReservation = workArena;
        }

        if (playerLevel != null) {
          tempBooking.playerLevel = playerLevel;
        }

        if (BookingType.isMonthly(values.bookingType)) {
          tempBooking.weekEntries = weekDays.split(',').map(x => ({
            weekDay: x,
            dayTime: schedule,
          }));
          tempBooking.bookingScheduleBegin = moment(scheduleBegin).format(
            'YYYY-MM-DD'
          );
          tempBooking.bookingScheduleEnd = moment(scheduleEnd).format(
            'YYYY-MM-DD'
          );
          tempBooking.payTipoCobranca = Number(monthlyPaymentBy);
        } else if (BookingType.isSingle(values.bookingType)) {
          const dateString = moment(date).format('YYYY-MM-DD');
          tempBooking.bookingDatetime = `${dateString} ${schedule}`;

          if (Number.isNaN(tempBooking.payTipo))
            tempBooking.payTipo = PaymentType.AT_LOCATION;
        }

        /* Check if selected hour is valid to selected court */
        if (validSchedule.findIndex(x => x.value === schedule) === -1) {
          const label =
            String(user.role).match(/Professor/) === null ? 'quadra' : 'vaga';

          const message = `Horário ${schedule} não é válido para ${label} ${getSelectedCourt.label}.`;

          toast(message, {
            type: ToastType.ERROR,
            position: ToastPosition.BOTTOM_RIGHT,
          });
          return;
        }

        onSubmit(tempBooking);
      }
    },
  });

  /**
   * Function handled by radio group button
   *
   * If value is '1' it shall use arena payment method
   * else it should use payment at location (outside application jurisdiction)
   *
   * @param {'0' | '1'} val
   * @param {import('../../enum/bookingType').BookingTypeValues} [newBookingType]
   */
  const handlePaymentTypeChange = (val, newBookingType = undefined) => {
    let option;

    /**
     * When booking type is change it triggers this function before changing
     * formik values. So when this case happens the new value is passed by argument.
     * Although this function is also called when radio is changed and radio
     * does not know booking value that is why the fallback is formik value.
     */
    const bookingType = newBookingType ?? formik.values.bookingType;

    /**
     * The following flow is
     *
     * -------------------------            ______________
     * | Is payment by WePlay? | ---Yes--> | Is monthly? | ---YES--> PaymentType is FULL
     * ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾            ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
     *            |                               |
     *          NO                                ------------NO---> Follow Arena Payment configuration (FULL or PARTIAL)
     *          |
     *          -------------------------> Payment type is AT LOCATION
     */
    if (val === '1' && activeArena?.pay_tipo != null) {
      option = activeArena.pay_tipo;
    } else {
      option = PaymentType.AT_LOCATION;
    }

    formik.setFieldValue('paymentType', PaymentType.getOption(option));
  };

  if (bindSubmit && typeof bindSubmit === 'function') {
    bindSubmit(() => formik.submitForm);
  }

  const handleMoney = event => {
    formik.setFieldValue('price', SafeParse.money(event.target.value));
  };

  /* Mapping options */
  const _weekDayOptions = weekDayOptions.map(x => {
    const regex = new RegExp(x.value, 'i');

    /* Check option is in the id csv */
    const checked = Boolean(initBookingDate.weekDays.match(regex));

    return {
      ...x,
      id: x.value,
      checked,
    };
  });

  const courtOptions = useSelector(state => {
    if (lock && lock.modality) {
      return arenaCourtOptionsByModalitySelector(state, formik.values.modality);
    }
    return arenaCourtOptionsSelector(state, activeArena.id);
  });

  const modalityOptions = useSelector(state =>
    courtModalityOptionsSelector(state, formik.values.court)
  );

  const scheduleOptions = useSelector(state => {
    return match(formik.values.bookingType, {
      [BookingType.MONTHLY]: () => {
        const weekdays = formik.values.weekDays.split(',');
        return courtOpenHoursOptionsSelector(
          state,
          formik.values.court,
          weekdays,
          initialValues.dateTime
        );
      },
      [BookingType.SINGLE]: () => {
        const weekday = formik.values.date.getDay() + 1;
        return courtOpenHoursOptionsSelector(
          state,
          formik.values.court,
          weekday,
          initialValues.dateTime
        );
      },
    });
  });

  const validSchedule = useSelector(state => {
    return match(formik.values.bookingType, {
      [BookingType.MONTHLY]: () => {
        const weekdays = formik.values.weekDays.split(',');

        if (weekdays.length) {
          return courtOpenHoursOptionsSelector(
            state,
            formik.values.court,
            weekdays
          );
        }

        return [];
      },
      [BookingType.SINGLE]: () => {
        const weekday = formik.values.date.getDay() + 1;
        return courtOpenHoursOptionsSelector(
          state,
          formik.values.court,
          weekday
        );
      },
    });
  });

  const user = useSelector(state => state.user.data);

  /* Generic function to handle select change */
  const handleSelectChange = (value, name) => {
    let _name = name;
    let _value = value;

    if (typeof value === 'object') _value = value.value;
    if (typeof name === 'object') _name = name.name;

    if (_name) {
      formik.setFieldValue(_name, _value);
    }
  };

  const handleSingleHourChange = ({ value, single, monthly }) => {
    /* This will set the selected schedule value */
    formik.setFieldValue('schedule', value);

    /* This will set the default value for selected schedule */
    formik.setFieldValue(
      'price',
      SafeParse.money(
        match(formik.values.bookingType, {
          [BookingType.SINGLE]: SafeParse.number(single, 0),
          [BookingType.MONTHLY]: match(formik.values.meta.monthlyPaymentBy, {
            [MonthlyChargeType.BY_BOOKING]: SafeParse.number(monthly, 0),
            [MonthlyChargeType.BY_MONTH]: SafeParse.number(
              INITIAL_MONTHLY_PRICE,
              0
            ),
            default: 0,
          }),
          default: 0,
        }),
        true
      )
    );
  };

  /* Handle Modality Change */
  const handleModalitySelectChange = value => {
    if (lock && lock.modality) return;

    handleSelectChange(value, 'modality');
  };

  const handleUsageSelectChange = value => {
    if (lock && lock.usage) return;

    handleSelectChange(value, 'bookingClass');
  };

  /* Handle single booking date change */
  const handleGenericDateChange = (value = null, name = null) => {
    if (value && name) {
      /* Check date by timestamp. Comparing Date object is always different */
      const saved = formik.values[name];
      if (!Number.isNaN(value.getTime())) {
        if (!saved || value.getTime() !== saved.getTime()) {
          /* Reset schedule if day changes */
          formik.setValues({ ...formik.values, [name]: value });
        }
      }
    }
  };

  /* Handle single date change */
  const handleDateChange = (date = null) => {
    handleGenericDateChange(date, 'date');
  };

  /* Handle monthly schedule begin change */
  const handleBeginScheduleChange = (date = null) => {
    handleGenericDateChange(date, 'scheduleBegin');
  };

  /* Handle monthly schedule end change */
  const handleEndScheduleChange = (date = null) => {
    handleGenericDateChange(date, 'scheduleEnd');
  };

  /* Handle montlhy booking checkbox change */
  const handleWeekDaysChange = (_, _weekDays) => {
    let value = '';

    if (_weekDays && _weekDays.length) {
      /* Sort weekdays to always have same csv when selecting the same options */
      value = _weekDays.sort().join(',');
    }

    /* Reset schedule when selecting another weekday */
    if (value !== formik.values.weekDays) {
      formik.setValues({ ...formik.values, weekDays: value, schedule: '' });
    }
  };

  /* Handle booking type change */
  const handleBookingTypeChange = ({ value }, { name }) => {
    const DO_NOT_GENERATE_PAYMENT = '0';

    /* Reset schedule when changing booking type */
    if (value !== formik.values.bookingType) {
      formik.setValues({
        ...formik.values,
        schedule: '',
        meta: {
          ...formik.values.meta,
          generatePayment: DO_NOT_GENERATE_PAYMENT,
        },
        price: SafeParse.money(
          BookingType.isSingle(value)
            ? INITIAL_SINGLE_PRICE
            : INITIAL_MONTHLY_PRICE,
          true
        ),
      });

      /**
       * Because it changes the payment type before updating
       * formik, it passes an optional second argument with
       * the new bookingType value.
       */
      handlePaymentTypeChange(DO_NOT_GENERATE_PAYMENT, value);
    }

    handleSelectChange(value, name);
  };

  /* Get right selected value from bookingTypeOptions */
  const getSelectedBookingType = React.useMemo(() => {
    return bookingTypeOptions.find(x => x.value === formik.values.bookingType);
  }, [formik.values.bookingType]);

  /* Get right selected value from courtOptions */
  const getSelectedUsage = React.useMemo(() => {
    return usageOptions.find(x => x.value === formik.values.bookingClass);
  }, [formik.values.bookingClass]);

  /* Get right selected value from courtOptions */
  const getSelectedCourt = React.useMemo(() => {
    return courtOptions.find(x => x.value === formik.values.court);
  }, [formik.values.court]);

  /* Get right selected value from playerLevelOptions */
  // eslint-disable-next-line consistent-return
  const getSelectedPlayerOption = React.useMemo(() => {
    if (formik.values.playerLevel != null) {
      return playerLevelOptions.find(
        x => x.value === formik.values.playerLevel
      );
    }
  }, [formik.values.playerLevel]);

  /* Get right selected value from courtOptions */
  const getSelectedModality = React.useMemo(() => {
    return modalityOptions.find(x => x.value === formik.values.modality);
  }, [formik.values.modality]);

  /* Get right selected value from scheduleOptions */
  const getSelectedSchedule = React.useMemo(() => {
    return scheduleOptions.find(x => x.value === formik.values.schedule);
  }, [formik.values.schedule]);

  // eslint-disable-next-line consistent-return
  const getSelectedWorkArena = React.useMemo(() => {
    if (user && Array.isArray(user.works)) {
      const found = user.works.find(x => x.id === formik.values.workArena);

      if (found) {
        const { id, title } = found;
        return { value: id, label: title };
      }
    }
    return null;
  }, [user, formik.values.workArena]);

  /* Conditional rendering. Form for Monthly or single */
  let dateTimeForm = null;
  switch (formik.values.bookingType) {
    case BookingType.SINGLE:
      dateTimeForm = (
        <motion.div className={styles['select-group']} key='single' {...config}>
          <span>Dia</span>
          <DatePicker
            id='date'
            name='date'
            date={formik.values.date}
            onChange={handleDateChange}
            error={formik.errors.date}
          />
        </motion.div>
      );
      break;
    case BookingType.MONTHLY:
      dateTimeForm = (
        <motion.div
          className={styles['select-group']}
          key='montlhy'
          {...config}
        >
          <InputWrapper title='Início'>
            <DatePicker
              id='scheduleBegin'
              name='scheduleBegin'
              date={formik.values.scheduleBegin}
              onChange={handleBeginScheduleChange}
              error={formik.errors.scheduleBegin}
            />
          </InputWrapper>
          <InputWrapper title='Término'>
            <DatePicker
              id='scheduleEnd'
              name='scheduleEnd'
              date={formik.values.scheduleEnd}
              onChange={handleEndScheduleChange}
              error={formik.errors.scheduleEnd}
            />
          </InputWrapper>

          <span style={{ marginBottom: '15px', fontWeight: 600 }}>
            Dias da semana
          </span>
          <CheckboxGroup
            id='weekDays'
            name='weekDays'
            value={formik.values.weekDays}
            options={_weekDayOptions}
            onChange={handleWeekDaysChange}
            error={formik.errors.weekDays}
          />
        </motion.div>
      );
      break;
    default:
      /* Invalid type renders nothing instead of breaking */
      break;
  }

  /* Render if it isn't locked */
  let modality = null;
  if (!lock || !lock.modality) {
    modality = (
      <InputWrapper title='Modalidade'>
        <Select
          id='modality'
          name='modality'
          options={modalityOptions}
          value={getSelectedModality}
          onChange={handleModalitySelectChange}
          error={formik.errors.modality}
        />
      </InputWrapper>
    );
  }

  let courtTitle = 'Quadra';
  let instructorComponents = null;

  if (String(user.role).match(/Professor/)) {
    /* Force modality off */
    courtTitle = 'Vaga';

    instructorComponents = (
      <>
        <InputWrapper title='Espaço esportivo'>
          <Select
            id='workArena'
            name='workArena'
            options={user.works.map(x => ({ label: x.title, value: x.id }))}
            value={getSelectedWorkArena}
            onChange={handleSelectChange}
          />
        </InputWrapper>
        <InputWrapper title='Nível'>
          <Select
            id='playerLevel'
            name='playerLevel'
            options={playerLevelOptions}
            value={getSelectedPlayerOption}
            onChange={handleSelectChange}
          />
        </InputWrapper>
      </>
    );
  }

  let bookingType = null;
  if (!lock || !lock.bookingType) {
    bookingType = (
      <InputWrapper title='Tipo'>
        <Select
          id='bookingType'
          name='bookingType'
          options={bookingTypeOptions}
          value={getSelectedBookingType}
          onChange={handleBookingTypeChange}
          error={formik.errors.bookingType}
        />
      </InputWrapper>
    );
  }

  let bookingClass = null;
  if (!lock || !lock.usage) {
    bookingClass = (
      <InputWrapper title='Utilização'>
        <Select
          id='bookingClass'
          name='bookingClass'
          options={usageOptions}
          value={getSelectedUsage}
          onChange={handleUsageSelectChange}
        />
      </InputWrapper>
    );
  }

  const paymentTitle = useMemo(() => {
    if (
      BookingType.isMonthly(formik.values.bookingType) &&
      formik.values.meta.monthlyPaymentBy === '0'
    ) {
      return 'Insira o valor que será cobrado por aula';
    }

    return 'Insira o valor da cobrança total';
  }, [formik.values.meta.monthlyPaymentBy, formik.values.bookingType]);

  const isPaymentAtLocation =
    activeArena?.pay_tipo != null
      ? PaymentType.isAtLocation(activeArena.pay_tipo)
      : true;

  /* Rendering form */
  return (
    <motion.div animate='visible' initial='hidden' {...config}>
      {instructorComponents}
      {bookingClass}
      <InputWrapper title={courtTitle}>
        <Select
          id='court'
          name='court'
          options={courtOptions}
          value={getSelectedCourt}
          onChange={handleSelectChange}
          error={formik.errors.court}
        />
      </InputWrapper>
      {modality}
      {bookingType}
      <AnimatePresence exitBeforeEnter>{dateTimeForm}</AnimatePresence>
      <InputWrapper title='Horário'>
        <Select
          id='schedule'
          name='schedule'
          options={scheduleOptions}
          value={getSelectedSchedule}
          onChange={handleSingleHourChange}
          error={formik.errors.schedule}
        />
      </InputWrapper>
      {!(activeArena?.pay_tipo == null || isPaymentAtLocation) ? (
        <InputWrapper title='Gerar cobrança?' gap={24}>
          <RadioGroup
            name='paymentRadio'
            options={shouldGeneratePaymentOptions}
            gap={16}
            value={formik.values.meta.generatePayment}
            onChange={event => {
              formik.setFieldValue('meta.generatePayment', event.target.value);
              handlePaymentTypeChange(event.target.value);
            }}
          />
        </InputWrapper>
      ) : null}
      <div className={styles.hr} />
      <InputWrapper title='Pagamento'>
        <Select
          id='paymentType'
          name='paymentType'
          options={PaymentType.getOptions()}
          value={formik.values.paymentType}
          disabled
        />
      </InputWrapper>

      {BookingType.isMonthly(formik.values.bookingType) &&
      formik.values.meta.generatePayment === '1' &&
      !isPaymentAtLocation ? (
        <InputWrapper title='Modelo de cobrança mensal' gap={24}>
          <RadioGroup
            name='monthlyPaymentBy'
            options={monthlyPaymentTypes}
            gap={16}
            value={formik.values.meta.monthlyPaymentBy}
            onChange={event => {
              formik.setFieldValue('meta.monthlyPaymentBy', event.target.value);
              formik.setFieldValue(
                'price',
                SafeParse.money(
                  match(event.target.value, {
                    [MonthlyChargeType.BY_BOOKING]: SafeParse.number(
                      formik.values.schedule?.monthly,
                      0
                    ),
                    [MonthlyChargeType.BY_MONTH]: INITIAL_MONTHLY_PRICE,
                    default: 0,
                  }),
                  true
                )
              );
            }}
          />
        </InputWrapper>
      ) : null}

      {formik.values.meta.generatePayment === '1' && !isPaymentAtLocation ? (
        <InputWrapper title={paymentTitle}>
          <ContainedInput onChange={handleMoney} value={formik.values.price} />
        </InputWrapper>
      ) : null}
    </motion.div>
  );
};

BookingForm.defaultProps = {
  initialValues: {
    bookingClass: false,
    bookingType: BookingType.SINGLE,
    weekEntries: [],
    dateTime: todayAtMidnight().toDateString(),
  },
  lock: null,
};

BookingForm.propTypes = {
  /* Form initial values */
  initialValues: PropTypes.shape({
    /** ID of working arena */
    workArena: PropTypes.number,
    /** If booking is a class */
    bookingClass: PropTypes.bool,
    /** Types of player levels */
    playerLevel: PropTypes.oneOf(Object.values(PLAYER_LEVEL)),
    /** Types of booking */
    bookingType: PropTypes.oneOf(Object.values(BookingType)),
    /** Court ID */
    court: PropTypes.number,
    /** Date when single schedule will take place. YYYY-MM-dd HH:mm:ss formated DateTime */
    dateTime: PropTypes.string,
    /** Modality */
    modality: PropTypes.string,
    /** Arrayf of weekdays and hour */
    weekEntries: PropTypes.arrayOf(
      PropTypes.exact({
        /** Weekday number. Starting on Sunday with 1 */
        weekDay: PropTypes.string,
        /** HH:mm:ss formatted hour */
        dayTime: PropTypes.string,
      })
    ),
    /** Date when montlhy schedule will begin. YYYY-MM-dd formated Date */
    scheduleBegin: PropTypes.string,
    /** Date when montlhy schedule will end. YYYY-MM-dd formated Date */
    scheduleEnd: PropTypes.string,
  }),
  /** Lock and hide values from the form */
  lock: PropTypes.shape({
    modality: PropTypes.bool,
    bookingType: PropTypes.bool,
    usage: PropTypes.bool,
  }),
  /** Prop to bind submit action from outside. Function returns an function to call submit */
  bindSubmit: PropTypes.func.isRequired,
  /** Action to be called after submitting */
  onSubmit: PropTypes.func.isRequired,
};

export default BookingForm;

/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import styles from './index.module.scss';

const EmptyStateMessage = props => {
  const { message, maxWidth } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.5 }}
      exit={{ opacity: 0 }}
      className={styles.message}
      style={{ maxWidth }}
    >
      {message}
    </motion.div>
  );
};

EmptyStateMessage.defaultProps = {
  message: 'Não disponível.',
  maxWidth: '100%',
};

EmptyStateMessage.propTypes = {
  message: PropTypes.string,
  maxWidth: PropTypes.number,
};

export default EmptyStateMessage;

import merge from 'lodash/merge';

import { fetchCourts } from '../routines';

const initialState = {};

const byID = (state = initialState, action) => {
  switch (action.type) {
    case fetchCourts.SUCCESS:
      return merge({}, state, action.payload.byID);
    default:
      return state;
  }
};

export default byID;

import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';

import * as R from './routines';
import * as A from './actions';
import * as P from '../../providers/bookingRequest';
import { pascalizeObject } from '../../utils';

function* fetchPeople(action) {
  try {
    yield delay(750);

    yield put(R.fetchBookingRequests.request());

    const { page = 1, search: s = undefined } = action.payload;

    const { pageSize, search } = yield select(
      state => state.bookingRequest.pagination
    );

    const regex = new RegExp(s, 'i');

    if ((s && !String(search).match(regex)) || (!s && search)) {
      yield all([
        put(A.resetBookingRequestPaginationData()),
        put(A.changeBookingRequestSearchParam(s)),
      ]);
    }

    /* Passing arguments to fetchPeople provider */
    const request = yield call(P.fetchBookingsRequest, {
      page,
      page_size: pageSize,
      search: s ?? search,
    });

    yield all([
      put(A.addBookingRequest(pascalizeObject(request.data))),
      put(A.changeBookingRequestPage(request.page)),
      put(A.changeBookingRequestPageSize(request.page_size)),
      put(A.changeBookingRequestTotal(request.total)),
    ]);
  } catch (ex) {
    yield put(R.fetchBookingRequests.failure());
  } finally {
    yield put(R.fetchBookingRequests.fulfill());
  }
}

export default function* PeopleSaga() {
  yield all([takeLatest(R.fetchBookingRequests, fetchPeople)]);
}

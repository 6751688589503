import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

/* Containers */
import SideDrawer from '../../containers/SideDrawer';

/* Components */
import Avatar from '../Avatar';
import Section from '../PayInfoDrawer/Section';

/* Assets and styles */
import styles from './index.module.scss';
/* Local Components */
import BookingsTab from './BookingsTab';
import DataTab from './DataTab';
import TabSelector from './TabSelector';

const tabs = [
  { id: 1, label: 'Dados' },
  { id: 3, label: 'Histórico' },
];

/* Selectors */
const valuesSelector = state => state.sideDrawer.userInfo.initialValues;
const activeArenaSelector = state => state.config.activeArena;

/**
 * @component
 */
function UserInfoDrawer(props) {
  const { closeOnOutsideClick, onPressClose, isOpen } = props;

  const user = useSelector(valuesSelector);
  const activeArena = useSelector(activeArenaSelector);

  if (user) {
    return (
      <SideDrawer
        closeOnOutsideClick={closeOnOutsideClick}
        onPressClose={onPressClose}
        isOpen={isOpen}
        classModifier='user'
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <Avatar url={user.image} />

            <div className={styles.headerText}>
              <Section title='Nome' value={user?.name} />
            </div>
          </div>

          <TabSelector tabs={tabs}>
            {active => {
              switch (active) {
                case 1:
                  return <DataTab user={user} />;
                case 3:
                  return (
                    <BookingsTab
                      key='history'
                      history
                      arenaId={activeArena}
                      applicantId={user.id}
                    />
                  );
                default:
                  return null;
              }
            }}
          </TabSelector>
        </div>
      </SideDrawer>
    );
  }

  return null;
}

UserInfoDrawer.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  onPressClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

UserInfoDrawer.defaultProps = {
  closeOnOutsideClick: false,
  onPressClose: () => '',
  isOpen: false,
};

export default React.memo(UserInfoDrawer);

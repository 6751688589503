import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

export default function CloseIcon(props) {
  const { width, height, stroke, size, color = stroke } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? width}
      height={size ?? height}
      viewBox='0 0 14 14'
    >
      <g
        fill='none'
        fillRule='evenodd'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      >
        <path d='M11.861 11.723L7 6.86 11.861 2M1.861 11.723L6.723 6.86 1.86 2' />
      </g>
    </svg>
  );
}

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

CloseIcon.defaultProps = {
  width: 14,
  height: 14,
  stroke: Colors.primary,
  size: undefined,
  color: undefined,
};

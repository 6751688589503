import { all, fork } from 'redux-saga/effects';

import auth from './auth/saga';
import bookingRequest from './bookingRequest/saga';
import court from './court/saga';
import event from './event/saga';
import httpRequest from './httpRequest/saga';
import person from './person/saga';
import payment from './payment/saga';
import router from './router/saga';

export default function* Sagas() {
  yield all([
    fork(auth),
    fork(bookingRequest),
    fork(court),
    fork(event),
    fork(httpRequest),
    fork(person),
    fork(router),
    fork(payment),
  ]);
}

import { motion } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CreateBookingButton from '../../components/CreateBookingButton/CreateBookingButton';
import EmptyStateMessage from '../../components/EmptyStateMessage';
import SearchInput from '../../components/SearchInput';
import ClientApplication from '../../containers/ClientApplication';
import { resetPersonStore } from '../../store/person/actions';
import { fetchPeople } from '../../store/person/routines';
import { debounce } from '../../utils';

import styles from './index.module.scss';

/* Local Selectors */
const searchValueSelector = state => state.person.pagination.search;
const isLoadingSelector = state => state.person.ui.loading;
const isBlockedSelector = state => {
  try {
    const { manages } = state.user.data;
    if (Array.isArray(manages) && manages.length) {
      return false;
    }

    return true;
  } catch (ex) {
    return true;
  }
};

const ClientsPage = () => {
  const dispatch = useDispatch();

  /* Data from Redux */
  const isLoading = useSelector(isLoadingSelector);
  const isBlocked = useSelector(isBlockedSelector);
  const initialSearchValue = useSelector(searchValueSelector);
  const user = useSelector(state => state.user.data);

  const label =
    user && String(user.role).match(/professor/i) ? 'aluno' : 'cliente';

  /* First page fetch on component init */
  useEffect(() => {
    dispatch(fetchPeople({ page: 1 }));

    return () => {
      dispatch(resetPersonStore());
    };
  }, []);

  /* Function to be debounced */
  const fetch = useCallback(val => {
    dispatch(fetchPeople({ page: 1, search: val ?? undefined }));
  }, []);

  /* Debounced function */
  const debounced = debounce(fetch, 500);

  return (
    <motion.div
      className={styles.page}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {!isBlocked && (
        <>
          <div className={styles['sub-header']}>
            <div style={{ marginRight: 'auto' }}>
              <CreateBookingButton />
            </div>

            <SearchInput
              placeholder='Buscar'
              id='search'
              value={initialSearchValue}
              onChange={debounced}
            />
          </div>

          <ClientApplication />
        </>
      )}
      {isBlocked && !isLoading && (
        <EmptyStateMessage message={`Nenhum ${label} disponível.`} />
      )}
    </motion.div>
  );
};
export default ClientsPage;

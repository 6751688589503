export const TYPES = {
  CHANGE_VALUE: 'CHANGE_VALUE',
  REQUEST: 'REQUEST',
  FULFILL: 'FULFILL',
  CLEAR: 'CLEAR',
  SUCCESS: 'SUCCESS',
  SHOW_RESULTS: 'SHOW_RESULTS',
  HIDE_RESULTS: 'HIDE_RESULTS',
  SET_FOCUS: 'SET_FOCUS',
};

export const initialState = () => ({
  value: '',
  loading: false,
  clear: false,
  isOpen: false,
  data: [],
  selected: null,
  focus: false,
});

export const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.CHANGE_VALUE:
      return { ...state, value: action.payload, clear: true };
    case TYPES.REQUEST:
      return { ...state, loading: true };
    case TYPES.FULFILL:
      return { ...state, loading: false };
    case TYPES.CLEAR:
      return { ...state, value: '', clear: false, data: [], selected: null };
    case TYPES.SUCCESS:
      return { ...state, data: action.payload };
    case TYPES.SHOW_RESULTS:
      return { ...state, isOpen: true };
    case TYPES.HIDE_RESULTS:
      return { ...state, isOpen: false, focus: false };
    case TYPES.SELECT:
      return { ...state, selected: action.payload };
    case TYPES.SET_FOCUS:
      return { ...state, focus: action.payload };
    default:
      return state;
  }
};

/**
 * Change input value
 * @param {string} payload
 */
export const changeValue = payload => ({ type: TYPES.CHANGE_VALUE, payload });
export const success = payload => ({ type: TYPES.SUCCESS, payload });
export const select = payload => ({ type: TYPES.SELECT, payload });
export const setFocus = payload => ({ type: TYPES.SET_FOCUS, payload });
export const request = () => ({ type: TYPES.REQUEST });
export const fulfill = () => ({ type: TYPES.FULFILL });
export const clear = () => ({ type: TYPES.CLEAR });
export const showResults = () => ({ type: TYPES.SHOW_RESULTS });
export const hideResults = () => ({ type: TYPES.HIDE_RESULTS });

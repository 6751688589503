import { baseAPI } from '../../service/axios';

/**
 * @typedef { import('../../model/User.model').User } User
 *
 * Function to fetch user perfil
 *
 * @returns { Promise<User> }
 */
const fetchUserPerfil = async () => {
  const res = await baseAPI.get(`user/perfil`);

  return res.data;
};

export default fetchUserPerfil;

import { baseAPI } from '../../service/axios';
import { toFormData } from '../../utils';

/**
 * Function to delete booking
 *
 * @param {number} id
 *
 * @returns {Promise<*>}
 */
const deleteBooking = async id => {
  const formData = toFormData({ booking_id: id });

  const res = await baseAPI.post('/booking/cancel', formData);

  return res.data;
};

export default deleteBooking;

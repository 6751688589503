import { baseAPI } from '../../service/axios';
import { toFormData } from '../../utils';

/**
 * Save user notification token
 *
 * @param {string} token
 *
 * @returns {Promise<boolean>}
 */
const saveNotificationToken = async token => {
  /** @type boolean */
  let saved;
  try {
    await baseAPI.post(
      `user/notification/token`,
      toFormData({ token, prefix: 'firebase' })
    );

    saved = true;
  } catch (ex) {
    saved = false;
  }

  return saved;
};

export default saveNotificationToken;

import React from 'react';
import PropTypes from 'prop-types';

import useResize from '../../../hooks/useResize';

import styles from './index.module.scss';
import Breakpoints from '../../../sass/_breakpoints.scss';

const Table = props => {
  const windowWidth = useResize();

  const { children } = props;

  /* Mobile size */
  let thead = (
    <>
      <th style={{ width: '12.5%' }}>Nome</th>
      <th style={{ width: '6.9%' }}>Tipo</th>
      <th style={{ width: '8%' }}>Ações</th>
    </>
  );
  /* Tablet size */
  if (windowWidth >= Number(Breakpoints.md.replace('px', ''))) {
    thead = (
      <>
        <th style={{ width: '12.5%' }}>Nome</th>
        <th style={{ width: '36.9%' }}>Pedido enviado em</th>
        <th style={{ width: '14.4%' }}>Modalidade</th>
        <th style={{ width: '6.9%' }}>Tipo</th>
        <th style={{ width: '8%' }}>Ações</th>
      </>
    );
  }
  /* Desktop size */
  if (windowWidth >= Number(Breakpoints.lg.replace('px', ''))) {
    thead = (
      <>
        <th style={{ width: '12.5%' }}>Nome</th>
        <th style={{ width: '12.5%' }}>Celular</th>
        <th style={{ width: '24.5%' }}>Pedido enviado em</th>
        <th style={{ width: '14.4%' }}>Modalidade</th>
        <th style={{ width: '6.9%' }}>Tipo</th>
        <th style={{ width: '13.7%' }}>Dia(S)</th>
        <th style={{ width: '7.3%' }}>Horário</th>
        <th style={{ width: '8%' }}>Ações</th>
      </>
    );
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr>{thead}</tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

Table.defaultProps = {
  children: null,
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Table;

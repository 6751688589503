import union from 'lodash/union';

import * as types from '../types';

const initialState = () => [];

export default (state = initialState(), action) => {
  switch (action.type) {
    case types.ADD_PAYMENT:
      return union([], state, action.payload.order);
    case types.REMOVE_PAYMENT:
      return state.filter(id => id !== action.payload);
    case types.CLEAR_ORDER_PAYMENT:
      return [];
    case types.RESET_PAYMENT_STORE:
      return initialState();
    default:
      return state;
  }
};

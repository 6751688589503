import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast, ToastPosition } from 'react-toastify';

import { bookingDetailSelector } from '../../store/event/selectors';

import {
  deleteBooking,
  deleteBookingRecurrence,
} from '../../store/httpRequest/routines';

import SideDrawer from '../../containers/SideDrawer';
import List from '../List';
import ListItem from '../ListItem';
import Button from '../Button';
import ConfirmToast from '../ConfirmationToast';

import styles from './index.module.scss';
import Colors from '../../sass/_colors.scss';
import { openNewBookingDrawer } from '../../store/sideDrawer/actions';
import BookingContent from './BookingContent';
import { maskPhone } from '../../utils';

const dispatchToast = (title, onConfirm) => {
  toast(<ConfirmToast question={title} onConfirm={onConfirm} />, {
    autoClose: 5000,
    position: ToastPosition.BOTTOM_RIGHT,
  });
};

/* Local Selectors */
const isLoadingSelector = state => state.httpRequest.deleteBooking.loading;
const bookingIdSelector = state => state.sideDrawer.classDetail.bookingId;

const ClassDetailDrawer = props => {
  const { closeOnOutsideClick, onPressClose, isOpen } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const bookingId = useSelector(bookingIdSelector);

  const classDetail = useSelector(state =>
    bookingDetailSelector(state, bookingId)
  );

  const _deleteBooking = () => {
    const onConfirm = () => dispatch(deleteBooking(bookingId));
    dispatchToast('Deseja deletar esta reserva?', onConfirm);
  };

  const _deleteMonthlyRecurrence = () => {
    /* Dispatch action to delete current booking and recurrences */
    const onConfirm = () => dispatch(deleteBookingRecurrence(bookingId));
    dispatchToast('Deseja deletar esta mensalidade?', onConfirm);
  };

  const _openBookingDetailDrawer = () => {
    onPressClose();
    dispatch(openNewBookingDrawer({ edit: bookingId }));
  };

  const phone = React.useMemo(() => {
    if (
      classDetail &&
      classDetail.applicant &&
      classDetail.applicant.telephone
    ) {
      return maskPhone(classDetail.applicant.telephone);
    }

    return '';
  }, [classDetail]);

  /* Drawer action buttons */
  const buttons = [
    <div key={0} style={{ flex: '0 1 242px', marginTop: 8, marginLeft: 16 }}>
      <Button
        outline
        background={Colors.negative}
        title='Cancelar reserva'
        onClick={_deleteBooking}
        loading={isLoading}
      />
    </div>,
    <div key={1} style={{ flex: 1, marginTop: 8, marginLeft: 16 }}>
      <Button
        outline
        background={Colors.primary}
        title='Editar'
        onClick={_openBookingDetailDrawer}
      />
    </div>,
  ];

  let applicantContent = null;
  let bookingContent = null;
  if (classDetail) {
    if (classDetail.applicant) {
      /* Applicant info */
      applicantContent = (
        <List hasBorderBottom title='Dados do aplicante'>
          <ListItem label='Nome' value={classDetail.applicant.name} />
          <ListItem label='E-mail' value={classDetail.applicant.email} />
          <ListItem label='Telefone' value={phone} />
          {/* TODO: Habilitar após backend entregar perfil */}
          {/* <ListItem label='Perfil' value={classDetail.applicant.profile} /> */}
        </List>
      );
    }

    if (classDetail.booking) {
      /* Booking info */
      bookingContent = <BookingContent {...classDetail.booking} />;

      if (classDetail.booking.type.match(/mensal/i)) {
        buttons.push(
          <div
            key={buttons.length}
            style={{ flex: '0 1 242px', marginTop: 8, marginLeft: 16 }}
          >
            <Button
              outline
              background={Colors.negative}
              title='Excluir mensalista'
              loading={isLoading}
              onClick={_deleteMonthlyRecurrence}
            />
          </div>
        );
      }
    }
  }

  return (
    <SideDrawer
      closeOnOutsideClick={closeOnOutsideClick}
      onPressClose={onPressClose}
      isOpen={isOpen}
    >
      <div className={styles.container}>
        <div className={styles['container-content']}>
          {applicantContent}
          {bookingContent}
          <div className={styles.orderTimeStamp}>
            {/* TODO: INTEGRAR */}
            {/* Pedido enviado em <span>{orderData.timeStamp}</span> */}
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              marginLeft: -16,
              marginTop: -8,
            }}
          >
            {buttons}
          </div>
        </div>
      </div>
    </SideDrawer>
  );
};

ClassDetailDrawer.defaultProps = {
  closeOnOutsideClick: false,
  onPressClose: () => {
    console.log('Button has to have an action');
  },
  isOpen: false,
};

ClassDetailDrawer.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  onPressClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ClassDetailDrawer;

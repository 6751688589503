import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import styles from './index.module.scss';

const animate = {
  normal: {
    clipPath: 'circle(0% at 50% 50%)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  focus: {
    clipPath: 'circle(100% at 50% 50%)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const animatePlaceholder = {
  normal: { y: 0, scale: 1 },
  focus: { y: -20, scale: 0.9 },
};

const Placeholder = React.memo(function Placeholder(props) {
  const { isUp, hasPadding, label } = props;

  return (
    <motion.div
      animate={isUp ? 'focus' : 'normal'}
      variants={animatePlaceholder}
      className={styles.placeholder}
      style={{ originX: 0, left: hasPadding ? 12 : 0 }}
    >
      <span>{label}</span>
    </motion.div>
  );
});

Placeholder.propTypes = {
  isUp: PropTypes.bool.isRequired,
  hasPadding: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

const Input = React.forwardRef(function Input(props, ref) {
  const {
    id,
    name,
    type,
    onFocus,
    onChange,
    onBlur,
    value,
    placeholder,
    leftComponent,
    rightComponent,
    style,
    error,
    disabled,
  } = props;

  const [_value, setValue] = useState(value);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (value !== _value) setValue(value);
  }, [value, _value]);

  const _onFocus = e => {
    setFocus(true);
    if (onFocus) onFocus(e);
  };

  const _onBlur = e => {
    setFocus(false);
    if (onBlur) onBlur(e);
  };

  const _onChange = e => {
    setValue(e.target.value);
    if (onChange != null && typeof onChange === 'function') onChange(e);
  };

  /* Component to render error */
  let errorComponent = null;

  /* container styles */
  let styleContainer = styles.container;
  /* border styles */
  let styleBorderBottom = styles.border;
  if (error) {
    /* Append styles if input has errors */
    styleBorderBottom += ` ${styles.error}`;
    styleContainer += ` ${styles.hasError}`;

    /* In case type of error is a string. Render message */
    if (typeof error === 'string') {
      errorComponent = (
        <div className={styles.errorMessage}>
          <span>{error}</span>
        </div>
      );
    }
  }

  const styleAnimatedBorder = `${styles.border} ${styles.active}`;

  return (
    <div className={styleContainer} style={style}>
      <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
        {leftComponent}
        <div style={{ position: 'relative', width: '100%' }}>
          <Placeholder
            isUp={focus || !!_value.length}
            hasPadding={!!leftComponent}
            label={placeholder}
          />
          <input
            ref={ref}
            className={styles.input}
            id={id}
            name={name}
            type={type}
            onFocus={_onFocus}
            onChange={_onChange}
            onBlur={_onBlur}
            value={_value}
            disabled={disabled}
          />
        </div>
        {rightComponent}
      </div>
      <div style={{ position: 'relative' }}>
        <div className={styleBorderBottom} />
        <motion.div
          animate={focus ? 'focus' : 'normal'}
          variants={animate}
          className={styleAnimatedBorder}
        />
      </div>
      {errorComponent}
    </div>
  );
});

Input.defaultProps = {
  id: '',
  name: '',
  placeholder: '',
  type: 'text',
  onChange: null,
  onFocus: null,
  onBlur: null,
  value: '',
  leftComponent: null,
  rightComponent: null,
  style: null,
  error: null,
  disabled: false,
};

Input.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  leftComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  rightComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]),
};

export default Input;

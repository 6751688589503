import VMasker from 'vanilla-masker';
import * as Yup from 'yup';
import BookingType from '../../enum/bookingType';

import MonthlyChargeType from '../../enum/monthlyChargeType';
import PaymentType from '../../enum/paymentType';
import { weekDayOptions } from '../../enum/weekDay';
import { todayAtMidnight } from '../BookingForm/helpers';

/* Constant option values */
export const usageOptions = Object.freeze([
  { label: 'Aula', value: true },
  { label: 'Jogo', value: false },
]);

export const paymentGenerationOptions = Object.freeze([
  { id: 'YES', label: 'Sim', value: '1' },
  { id: 'NO', label: 'Não', value: '0' },
]);

export const monthlyPaymentTypes = Object.freeze([
  { id: '1', label: 'Valor por mês', value: MonthlyChargeType.BY_MONTH },
  { id: '2', label: 'Valor individual', value: MonthlyChargeType.BY_BOOKING },
]);

/* Animation config */
const componentVisibilityVariant = {
  visible: {
    x: '0%',
    opacity: 1,
  },
  hidden: {
    x: '100%',
    opacity: 0,
  },
};

const transition = {
  duration: 0.25,
};

export const config = {
  variants: componentVisibilityVariant,
  transition,
  exit: 'hidden',
};

export const DISABLE_MODALITY = 'PROFESSOR ROLE';
export const INITIAL_MONTHLY_PRICE = 200;
export const INITIAL_SINGLE_PRICE = 0;

/* Constantes */
const GAP = 40;
export const styleWithError = { marginBottom: GAP - 17 };
export const styleWithoutError = { marginBottom: GAP - 17 };

export const bookingTypeOptions = BookingType.getOptions().filter(
  x => x.value !== BookingType.VACANT
);

export const paymentOptions = PaymentType.getOptions();

export const weekDaysOptions = weekDayOptions.map(x => ({
  ...x,
  id: x.value,
  checked: false,
}));

const phoneRegex = /\(\d{2}\)\s\d{4,5}-\d{4}/;

const requiredMessage = 'Campo obrigatório.';
const pastDateMessage = 'Data não pode ser no passado.';
const dateMessage = 'Data não válida.';

/**
 * Campos foram separados para que possa ser feito a validação diferenciada
 * quando o usuário não vá pagar. Assim não é necessário informar o CPF
 */
const applicantCommonFields = {
  name: Yup.string().required(requiredMessage),
  email: Yup.string()
    .email('Campo necessita ser um e-mail válido.')
    .required(requiredMessage),
  phone: Yup.string()
    .matches(phoneRegex, 'Precisa ser um número fixo ou móvel com ddd')
    .required(requiredMessage),
};

/* Validação usando YUP */
export const validationSchema = Yup.object().shape({
  /**
   * Yup não consegue validar voltando na árvore de validação
   * ele consegue enxergar apenas do nó atual para baixo ou nós
   * irmãos.
   *
   * Por isso é necessário que todo o objeto de validação de aplicante
   * seja reconstruido quando o tipo de pagamento é alterado
   */
  applicant: Yup.mixed().when('payment.type', {
    is: PaymentType.AT_LOCATION,
    then: Yup.object().shape({
      ...applicantCommonFields,
      cpf: Yup.mixed().nullable(),
    }),
    otherwise: Yup.object().shape({
      ...applicantCommonFields,
      cpf: Yup.string()
        .required(requiredMessage)
        .test('test-cpf', 'Campo de cpf é inválido', val => {
          const cpf = String(val).replace(/\D/g, '');
          /* Não respeita quantidade dígitos */
          if (cpf.length !== 11) return false;
          /* Todos os numeros são repetidos. Ex 11111111111 */
          if (cpf.match(/^(\d)\1{10}/g)) return false;

          /* Stackoverflow function to check cpf */
          let sum = 0;
          for (let i = 0; i < 9; i++) sum += Number(cpf.charAt(i)) * (10 - i);
          let rest = 11 - (sum % 11);
          if (rest === 10 || rest === 11) rest = 0;
          if (rest !== Number(cpf.charAt(9))) return false;
          sum = 0;
          for (let i = 0; i < 10; i++) sum += Number(cpf.charAt(i)) * (11 - i);
          rest = 11 - (sum % 11);
          if (rest === 10 || rest === 11) rest = 0;

          return rest === Number(cpf.charAt(10));
        }),
    }),
  }),
  booking: Yup.object().shape({
    id: Yup.string().nullable(),

    /* Validate court */
    court: Yup.number()
      .nullable()
      .required(requiredMessage),
    /* Validate booking type */
    type: Yup.mixed()
      .oneOf(
        [BookingType.MONTHLY, BookingType.SINGLE],
        'Recorrrência não valida.'
      )
      .required(requiredMessage),

    /* Validate booking hour */
    schedule: Yup.string()
      .nullable()
      .required(requiredMessage),

    /* Validate booking date only if booking type is single */
    date: Yup.mixed().when('type', {
      is: BookingType.SINGLE,
      then: Yup.date(dateMessage)
        .min(todayAtMidnight(), pastDateMessage)
        .required(requiredMessage),
    }),

    /* Validate weekdays only if booking type is monthly */
    weekDays: Yup.mixed().when(['type', 'id'], {
      is: (type, id) => BookingType.isMonthly(type) && id == null,
      then: Yup.string()
        .min(1, 'Necessário escolher ao menos um dia.')
        .required(requiredMessage),
    }),
    scheduleBegin: Yup.mixed().when(['type', 'id'], {
      is: (type, id) => BookingType.isMonthly(type) && id == null,
      then: Yup.date(dateMessage)
        .min(todayAtMidnight(), pastDateMessage)
        .required(requiredMessage),
    }),
    scheduleEnd: Yup.mixed().when(['type', 'id'], {
      is: (type, id) => BookingType.isMonthly(type) && id == null,
      then: Yup.date(dateMessage)
        .nullable()
        .min(
          Yup.ref('scheduleBegin'),
          'Data de término não pode ser antes do início.'
        )
        .required(requiredMessage),
    }),
  }),
  /* Validação do pagamento */
  payment: Yup.object().shape({
    /* Payment validation */
    type: Yup.mixed()
      .oneOf([PaymentType.AT_LOCATION, PaymentType.FULL, PaymentType.PARTIAL])
      .required(requiredMessage),

    value: Yup.mixed().when('type', {
      is: PaymentType.AT_LOCATION,
      otherwise: Yup.number()
        .min(0, 'Valor não pode ser inferior a 0 (zero).')
        .transform((_, _val) => Number(VMasker.toNumber(_val)))
        .required(requiredMessage),
    }),
  }),
});

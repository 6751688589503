import React, { useMemo } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Route, Switch } from 'react-router-dom';

/* Data */
import store, { persistor, history } from './store';
import routes from './data/routes';

/* Utils */
import { baseAPI } from './service/axios';
import { unauthenticate } from './store/auth/actions';
import {
  closeNewBookingDrawer,
  closeBookingDetailDrawer,
  closeUserInfoDrawer,
  closePayInfoDrawer,
} from './store/sideDrawer/actions';

/* Containers */
import CustomToastContainer from './containers/CustomToastContainer';

/* Components */
import Loading from './components/Loading';
import PrivateRoute from './components/PrivateRoute';
import GuestRoute from './components/GuestRoute';
import Header from './components/Header';
import RefreshLoader from './components/RefreshLoader';
import ErrorBoundary from './components/ErrorBoundary';
import NewBookingDrawer from './components/NewBookingDrawer';

/* Pages */
import { Error404 } from './pages';
import BookingDetailDrawer from './components/BookingDetailDrawer';
import UserInfoDrawer from './components/UserInfoDrawer/UserInfoDrawer';
import PayInfoDrawer from './components/PayInfoDrawer/PayInfoDrawer';

/* Local Selectors */
const newBookingOpenSelector = state => state.sideDrawer.newBooking.open;
const bookingOpenSelector = state => state.sideDrawer.bookingDetail.open;
const userInfoOpenSelector = state => state.sideDrawer.userInfo.open;
const payInfoOpenSelector = state => state.sideDrawer.payInfo.open;

baseAPI.interceptors.response.use(
  res => res,
  err => {
    if (err && err.response) {
      if (err.response.status === 401) {
        store.dispatch(unauthenticate('Sua sessão expirou'));
      }
    }
    throw err;
  }
);

const Drawers = () => {
  const isAuth = useSelector(state => !!state.auth.token);
  const dispatch = useDispatch();

  const isNewBookingOpen = useSelector(newBookingOpenSelector);
  const closeNewBooking = () => dispatch(closeNewBookingDrawer());

  const isBookingDetailOpen = useSelector(bookingOpenSelector);
  const closeBookingDetail = () => dispatch(closeBookingDetailDrawer());

  const isUserInfoOpen = useSelector(userInfoOpenSelector);
  const closeUserInfo = () => dispatch(closeUserInfoDrawer());

  const isPayInfoOpen = useSelector(payInfoOpenSelector);
  const closePayInfo = () => dispatch(closePayInfoDrawer());

  if (!isAuth) return null;

  return (
    <>
      <UserInfoDrawer isOpen={isUserInfoOpen} onPressClose={closeUserInfo} />

      <PayInfoDrawer isOpen={isPayInfoOpen} onPressClose={closePayInfo} />

      <NewBookingDrawer
        isOpen={isNewBookingOpen}
        onPressClose={closeNewBooking}
      />

      <BookingDetailDrawer
        isOpen={isBookingDetailOpen}
        onPressClose={closeBookingDetail}
      />
    </>
  );
};

const Routes = () => {
  const appLoading = useSelector(state => state.app.loading);

  const memoizedRoutes = useMemo(
    () =>
      routes.map((r, i) => {
        if (r.private) {
          return (
            <PrivateRoute
              key={i}
              exact={r.exact}
              path={r.path}
              component={r.component}
            />
          );
        }
        return (
          <GuestRoute
            key={i}
            exact={r.exact}
            path={r.path}
            component={r.component}
          />
        );
      }),
    [routes]
  );

  if (appLoading) return <RefreshLoader />;

  return (
    <>
      <Header />
      <Switch>
        {memoizedRoutes}
        <Route component={Error404} />
      </Switch>
      <Drawers />
    </>
  );
};

const App = () => {
  return (
    <>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </PersistGate>
          <CustomToastContainer />
        </Provider>
      </ErrorBoundary>
      <VersionTag />
    </>
  );
};

const VersionTag = () => {
  // eslint-disable-next-line global-require
  const { version } = require('../package.json');

  return (
    <div style={{ position: 'fixed', bottom: 8, left: 8 }}>
      <span style={{ color: 'gray', fontSize: 10 }}>v{version}</span>
    </div>
  );
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import styles from './index.module.scss';

const IconButton = props => {
  const { color, icon, onClick } = props;

  let _icon = null;
  if (React.isValidElement(icon)) {
    _icon = icon;
  } else if (typeof icon === 'number' || typeof icon === 'string') {
    _icon = <img src={icon} alt='icon' />;
  } else if (typeof icon === 'function') {
    _icon = icon();
  }

  return (
    <motion.button
      initial={{ background: 'rgba(0, 0, 0, 0)' }}
      whileHover={{ scale: 1.1 }}
      className={styles['icon-button']}
      style={{ color }}
      onClick={onClick}
    >
      {_icon}
    </motion.button>
  );
};

IconButton.defaultProps = {
  color: '',
  icon: '',
  onClick: () => {},
};

IconButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.func,
  ]),
  onClick: PropTypes.func,
};

export default IconButton;

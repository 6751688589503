import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

/* Importing Components */
import AgendaEvent from '../../components/AgendaEvent';

/* Importing models */
import AgendaEventModel from '../../model/AgendaEvent.model';

/**
 * Function to filter events by user name
 *
 * @param {AgendaEventModel[]} events
 * @param {string} search
 * @returns {AgendaEventModel[]}
 */
const filterEventSearch = (events, search) => {
  if (!events || !events.length || !search || !search.trim()) return null;

  const _search = search.trim();

  const regexp = new RegExp(_search, 'gi');

  return events.filter(e => {
    if (e.resource) {
      const { recurrence, user } = e.resource;
      if (recurrence && user) {
        return !String(recurrence).match(/free/i) && String(user).match(regexp);
      }
    }

    return false;
  });
};

/* Search Box component */
const SearchBox = React.memo(props => {
  const { search, events } = props;

  const filterEventMemo = useCallback(() => {
    return filterEventSearch(events, search);
  }, [events, search]);

  const searchedEvents = filterEventMemo();

  if (!search || !search.trim()) return null;

  let result = (
    <div className={styles.empty}>
      <span>Nenhuma reserva encontrada!</span>
    </div>
  );

  if (searchedEvents && searchedEvents.length) {
    result = searchedEvents.map((e, i) => (
      <>
        <div style={{ height: 100, cursor: 'pointer' }}>
          <AgendaEvent
            background='#333'
            user={e.resource.user}
            sport={e.resource.sport}
            start={e.start}
            end={e.end}
            recurrence={e.resource.recurrence}
            id={e.id}
          />
        </div>
        {i < searchedEvents.length - 1 && (
          <div style={{ width: '100%', height: 2, background: '#414141' }} />
        )}
      </>
    ));
  }

  return <div className={styles.result}>{result}</div>;
});

SearchBox.defaultProps = {
  events: [],
  search: '',
};

SearchBox.propTypes = {
  search: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.instanceOf(AgendaEventModel)),
};

export default SearchBox;

import { fetchPeople } from '../routines';

import * as types from '../types';

const initialState = () => ({
  loading: false,
  error: null,
  _at: null,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case fetchPeople.TRIGGER:
      return { ...state, _at: null, error: null };
    case fetchPeople.REQUEST:
      return { ...state, loading: true };
    case fetchPeople.FAILURE:
      return { ...state, error: action.payload };
    case fetchPeople.FULFILL:
      return { ...state, loading: false, _at: new Date() };
    case types.RESET_PERSON_STORE:
      return initialState();
    default:
      return state;
  }
};

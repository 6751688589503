import { baseAPI } from '../../service/axios';

import toFormData from '../../utils/toFormData';

const approveBookingRequest = async body => {
  const res = await baseAPI.post('booking/request/approve', toFormData(body));

  return res.data;
};

export default approveBookingRequest;

/* eslint-disable react/button-has-type */
import React from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './index.module.scss';

const AgendaLoader = () => {
  const isLoading = useSelector(state => state.event.ui.loading);

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.agendaLoaderWrapper}
        >
          <motion.div className={styles.agendaLoader} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AgendaLoader;

import moment from 'moment';

import BOOKING_TYPE from '../../enum/bookingType';

/* Get current date at midnight */
export const todayAtMidnight = () =>
  moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate();

/* Generating initial date. Based on booking type */
export const buildInitialDate = initialValues => {
  const result = {
    date: todayAtMidnight(),
    weekDays: '',
    schedule: '',
  };

  if (initialValues) {
    const { bookingType, dateTime, weekEntries } = initialValues;

    switch (bookingType) {
      case BOOKING_TYPE.SINGLE:
        result.date = moment(dateTime)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate();
        result.schedule = moment(dateTime).format('HH:mm:ss');
        break;
      case BOOKING_TYPE.MONTHLY:
        if (weekEntries && weekEntries.length) {
          result.weekDays = weekEntries.map(x => x.weekDay).join(',');
          result.schedule = weekEntries[0].dayTime;
        }
        break;
      default:
        break;
    }
  }

  return result;
};

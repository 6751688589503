/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { createSelector } from 'reselect';

import { EVENT_DURATION } from '../../constants/AppConstants';
import { getPlayerLevelLabel } from '../../enum/playerLevel';
import AgendaEvent from '../../model/AgendaEvent.model';
import { generateDurationTime, uuidv4 } from '../../utils';

/**
 * Selector to convert event reducer element to
 * big calendar resource event
 *
 * @param {Date} date - Date to filter
 * @param {any} state - Current store state
 *
 * @example
 * const events = useSelector(state => agendaEventSelector(new Date(), state))
 *
 * @returns {AgendaEvent[]} An array of AgendaEvents
 */
export const agendaEventSelector = createSelector(
  date => date,
  (_, state) => state.event.byID,
  (_, state) => state.person.byID,
  (_, state) => state.court.byID,
  (date, events, persons, courts) => {
    if (!date || !(date instanceof Date)) return [];

    // const _persons = Object.values(persons);
    // if (!_persons || !_persons.length) return [];

    /**
     *  Create result object that will hold events where keys follow the following rule
     *
     * courtId + 2 digits hour + 2 digits minute
     *
     * Generating first the free slots
     * and overwriting with booked slots
     */
    const result = {};

    /* Array with courts */
    const _courts = Object.values(courts);
    /* Array with bookings */
    const _events = Object.values(events);

    /**
     * Loop for generating free slots
     */
    for (let index = 0; index < _courts.length; index++) {
      const court = _courts[index];

      /* Genereting free hour events */
      if (court && Array.isArray(court.schedules) && court.schedules.length) {
        const { id, schedules, duration = EVENT_DURATION } = court;

        if (schedules && schedules.length === 7) {
          const weekday = date.getDay();

          const schedulesWeekday = schedules[weekday];

          if (schedulesWeekday) {
            for (let j = 0; j < schedulesWeekday.length; j++) {
              const schedule = schedulesWeekday[j];

              /* Force string of four digits */
              const time = String(schedule).padStart(4, '0');

              /* Group time and minutes, fist comma skips full string */
              const [, h, m] = time.match(/(\d{2})(\d{2})/);

              /* If hour and minutes return built free AgendaEvent */
              if (h && m) {
                const _h = Number(h);
                const _m = Number(m);

                const start = moment(date)
                  .hour(_h)
                  .minute(_m)
                  .second(0)
                  .toDate();

                const end = moment(start)
                  .add(duration, 'minutes')
                  .toDate();

                const freeTime = new AgendaEvent({
                  id: uuidv4(),
                  title: 'Livre',
                  start,
                  end,
                  resourceId: id,
                  resource: {
                    recurrence: 'free',
                  },
                });

                result[court.id + h + m] = freeTime;
              }
            }
          }
        }
      }
    }

    /**
     * Loop for generating bookings
     */
    for (let index = 0; index < _events.length; index++) {
      const event = _events[index];

      /* Test if event is on the same date choose on calendar */
      if (moment(event.booking_date).isSame(date, 'day')) {
        /* Save application id */
        const person = event.applicant_id;
        /* Create user with person id as fallback */
        let user = person;
        /* If person at ID isnt undefined */
        if (persons[person]) {
          /* Override user with person name */
          user = persons[person].name;
        }

        /* Assumes that event lasts at least 60 minutes */
        let duration = EVENT_DURATION;

        /* Check if court with event.court_id exists and test for duration existence */
        const court = courts[event.court_id];
        if (court && court.duration && court.duration > 0) {
          duration = court.duration;
        }

        const start = moment(event.booking_date).toDate();
        const end = moment(event.booking_date)
          .add(duration, 'minutes')
          .toDate();

        /* Generating booked hour events */
        let agendaEvent = null;
        try {
          agendaEvent = new AgendaEvent({
            id: event.id,
            title: 'Reserva',
            start,
            end,
            resourceId: event.court_id,
            resource: {
              sport: event.modality,
              user,
              recurrence: event.booking_type ? 'monthly' : 'single',
              isClass: event.booking_class || false,
              duration: event.booking_duration ?? EVENT_DURATION,
              reservedArena: event?.booking_instructor_reservation?.title ?? '',
            },
          });
        } catch (ex) {
          console.log(
            `Event with id ${event.id} failed with message`,
            ex.message
          );
        }

        if (agendaEvent) {
          const h = String(start.getHours()).padStart(2, '0');
          const m = String(start.getMinutes()).padStart(2, '0');

          /* Override free slot */
          result[event.court_id + h + m] = agendaEvent;
        }
      }
    }

    return Object.values(result);
  }
);

export const bookingByIdSelector = createSelector(
  state => state.event.byID,
  (_, id) => id,
  (bookings, id) => bookings[id]
);

export const bookingDetailSelector = createSelector(
  state => state.court.byID,
  state => state.event.byID,
  state => state.person.byID,
  (_, bookingID) => bookingID,
  (courts, bookings, people, id) => {
    const booking = bookings[id];

    if (booking) {
      const details = {};

      const court = courts[booking.court_id]?.title ?? '';

      details.booking = {
        days: moment(booking.booking_date)
          .locale('pt-br')
          .format('ddd'),
        type: booking.booking_type_label,
        hour: generateDurationTime(booking.booking_date, 60),
        modality: booking.modality,
        court,
      };

      if (booking.booking_class != null) {
        details.booking.isClass = booking.booking_class;
        details.booking.playerLevel = getPlayerLevelLabel(
          booking.booking_player_level
        );

        if (booking.booking_instructor_reservation) {
          details.booking.reservedArena =
            booking.booking_instructor_reservation;
        }
      }

      if (people && people[booking.applicant_id]) {
        const applicant = people[booking.applicant_id];
        const { name, email, telephone } = applicant;

        details.applicant = { name, email, telephone };
      }

      return details;
    }

    return undefined;
  }
);

export const bookingInitialValuesSelector = createSelector(
  [
    state => state.event.byID,
    state => state.person.byID,
    state => state.sideDrawer.newBooking.initialValues,
  ],
  (bookings, applicants, initialValues) => {
    if (initialValues) {
      /**
       * New way to declare applicant data and booking data
       */
      if (initialValues.new != null && typeof initialValues.new === 'object') {
        return {
          applicant: initialValues.new.applicant,
          booking: initialValues.new.booking,
        };
      }

      if (initialValues.edit) {
        const id = initialValues.edit;
        if (bookings[id]) {
          const applicantId = bookings[id].applicant_id;

          const applicant = {};
          if (applicants[applicantId]) {
            const { name, email, telephone } = applicants[applicantId];

            applicant.name = name;
            applicant.email = email;
            applicant.phone = telephone;
            applicant.cpf = bookings[id].applicant_cpf;
          }

          const booking = {
            id,
            bookingClass: bookings[id].booking_class,
            court: bookings[id].court_id,
            dateTime: bookings[id].booking_date,
            bookingType: bookings[id].booking_type,
            modality: bookings[id].modality,
          };

          if (bookings[id].booking_class) {
            booking.playerLevel = bookings[id].booking_player_level;
            if (bookings[id].booking_instructor_reservation) {
              booking.workArena =
                bookings[id].booking_instructor_reservation.id;
            }
          }

          return { edit: true, applicant, booking };
        }
      }

      return { booking: initialValues };
    }
    return {};
  }
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import style from './index.module.scss';

import CloseIcon from '../../assets/icons/CloseIcon';

const SideDrawer = props => {
  const {
    onPressClose,
    closeOnOutsideClick,
    children,
    isOpen,
    position,
    classModifier,
  } = props;

  useEffect(() => {
    const body = document.querySelector('body');
    if (isOpen) {
      body.classList.add(
        `open-side-drawer${classModifier ? `-${classModifier}` : ''}`
      );
    }

    return () => {
      body.classList.remove(
        `open-side-drawer${classModifier ? `-${classModifier}` : ''}`
      );
    };
  }, [isOpen, classModifier]);

  /**
   * Constant to animate drawer position on x axis.
   */
  const x = position === 'left' ? -300 : 300;

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className={style.container}
          style={{ pointerEvents: closeOnOutsideClick ? 'auto' : 'none' }}
        >
          <div
            className={style.outside}
            onClick={closeOnOutsideClick ? onPressClose : null}
            role='none'
            style={{ order: position === 'left' ? 1 : -1 }}
          />
          <motion.div
            key='sideDrawer'
            initial={{ opacity: 0, x }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x }}
            transition={{
              type: 'spring',
              stiffness: 400,
              damping: 40,
            }}
            className={style['side-drawer']}
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={style['close-button']}
              onClick={onPressClose}
            >
              <CloseIcon />
            </motion.div>

            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

SideDrawer.defaultProps = {
  closeOnOutsideClick: false,
  onPressClose: () => {
    console.log('Button has to have an action');
  },
  children: null,
  isOpen: false,
  position: 'right',
  classModifier: undefined,
};

SideDrawer.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  onPressClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool,
  /**
   * Prop to choose which side drawer will render
   *
   * @default 'right'
   */
  position: PropTypes.oneOf(['right', 'left']),
  classModifier: PropTypes.string,
};

export default SideDrawer;

import * as types from './types';

export const fetchConflictInfo = id => ({
  type: types.FETCH_CONFLICT_INFO,
  payload: id,
});

export const fetchConflictFail = payload => ({
  type: types.FETCH_CONFLICT_FAIL,
  payload,
});

export const fetchConflictDone = payload => ({
  type: types.FETCH_CONFLICT_DONE,
  payload,
});

export const openConflictDrawer = () => ({
  type: types.OPEN_DRAWER_CONFLICT,
});

export const closeConflictDrawer = () => ({
  type: types.CLOSE_DRAWER_CONFLICT,
});

export const openBookingDetailDrawer = payload => ({
  type: types.OPEN_DRAWER_BOOKING_DETAIL,
  payload,
});

export const closeBookingDetailDrawer = () => ({
  type: types.CLOSE_DRAWER_BOOKING_DETAIL,
});

export const openClassDetailDrawer = () => ({
  type: types.OPEN_DRAWER_CLASS_DETAIL,
});

export const closeClassDetailDrawer = () => ({
  type: types.CLOSE_DRAWER_CLASS_DETAIL,
});

export const selectClassDetailId = payload => ({
  type: types.SELECT_CLASS_DETAIL_ID,
  payload,
});

export const openNewBookingDrawer = payload => ({
  type: types.OPEN_DRAWER_NEW_BOOKING,
  payload: typeof payload === 'object' ? payload : {},
});

export const closeNewBookingDrawer = () => ({
  type: types.CLOSE_DRAWER_NEW_BOOKING,
});

export const openUserInfoDrawer = payload => ({
  type: types.OPEN_DRAWER_USER_INFO,
  payload,
});

export const closeUserInfoDrawer = () => ({
  type: types.CLOSE_DRAWER_USER_INFO,
});

export const openPayInfoDrawer = payload => ({
  type: types.OPEN_DRAWER_PAY_INFO,
  payload,
});

export const closePayInfoDrawer = () => ({
  type: types.CLOSE_DRAWER_PAY_INFO,
});

/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const ListItem = props => {
  const { label, value } = props;
  return (
    <li className={styles.listItem}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </li>
  );
};

ListItem.defaultProps = {
  label: '',
  value: '',
};

ListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default ListItem;

/** @typedef {'0' | '1' | '2'} PaymentTypeValues */

/**
 * @typedef PaymentType
 * @property {'0'} FULL
 * @property {'1'} PARTIAL
 * @property {'2'} AT_LOCATION
 * @property {(val: any) => boolean} isFull
 * @property {(val: any) => boolean} isPartial
 * @property {(val: any) => boolean} isAtLocation
 * @property {(val: PaymentTypeValues) => string} getLabel
 * @property {(isProfessor?: boolean) => {id:string;value:string;label:string}[]} getOptions
 * @property {(val: any, defaultValue: PaymentTypeValues) => PaymentTypeValues} getValidValue
 * @property {(val: PaymentTypeValues, isProfessor?: boolean) => {id:string;value:string;label:string} | undefined} getOption
 */

/** @type {PaymentType} */
const _paymentType = {
  FULL: '0',
  PARTIAL: '1',
  AT_LOCATION: '2',
};

Object.defineProperty(_paymentType, 'isFull', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_paymentType.FULL);
  },
});

Object.defineProperty(_paymentType, 'isPartial', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_paymentType.PARTIAL);
  },
});

Object.defineProperty(_paymentType, 'isAtLocation', {
  /**
   * @param {any} val
   * @returns {boolean}
   */
  value(val) {
    return String(val) === String(_paymentType.AT_LOCATION);
  },
});

Object.defineProperty(_paymentType, 'getValidValue', {
  /**
   * @param {any} val
   * @param {PaymentTypeValues} [defaultValue='2']
   * @returns {PaymentTypeValues}
   */
  value(val, defaultValue = _paymentType.AT_LOCATION) {
    if (
      Object.values(_paymentType).findIndex(p => String(val) === String(p)) > -1
    ) {
      return String(val);
    }

    return defaultValue;
  },
});

Object.defineProperty(_paymentType, 'getLabel', {
  /**
   * @param {PaymentTypeValues} val
   * @param {boolean} [isProfessor=false]
   * @returns {string}
   */
  value(val, isProfessor = false) {
    switch (String(val)) {
      case '0':
        return 'Via We Play (Integral, 100%)';
      case '1':
        return 'Via We Play (Parcial, 50%)';
      case '2':
        return isProfessor
          ? 'Via professor (durante a aula)'
          : 'Via arena (no local)';
      default:
        return '';
    }
  },
});

Object.defineProperty(_paymentType, 'getOptions', {
  /**
   * @param {boolean} [isProfessor=false]
   * @returns {{id:string;value:string;label:string}[]}
   */
  value(isProfessor = false) {
    return Object.entries(_paymentType).map(([id, value]) => ({
      id,
      value,
      label: _paymentType.getLabel(value, isProfessor),
    }));
  },
});

Object.defineProperty(_paymentType, 'getOption', {
  /**
   * @param {PaymentTypeValues} val
   * @param {boolean} [isProfessor=false]
   * @returns {{id:string;value:string;label:string}|undefined}
   */
  value(val, isProfessor = false) {
    const filtered = Object.entries(_paymentType)
      .map(([id, value]) => ({
        id,
        value,
        label: _paymentType.getLabel(value, isProfessor),
      }))
      .filter(x => String(x.value) === String(val));

    return filtered.length > 0 ? filtered[0] : undefined;
  },
});

const PaymentType = Object.freeze(_paymentType);

export default PaymentType;

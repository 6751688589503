/**
 * Function to convert strings from pascal case to camel case
 *
 * @param {string} str
 *
 * @returns {string} Retuns camelcase string
 */
const toCamelCase = str => {
  if (!str) return '';

  const _str = String(str);

  return _str.replace(
    /([a-z])_([a-z])/g,
    (_, p1, p2) => `${p1}${p2.toUpperCase()}`
  );
};

export default toCamelCase;

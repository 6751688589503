import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import reducers from './reducers';
import sagas from './sagas';
import { baseAPI } from '../service/axios';

/**
 * Init history
 *
 * Configure it to work with sub-directory
 *
 * PUBLIC_URL reads from homepage inside package.json
 */
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

// Store initial state
const initialState = {};

// Enable Redux Devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create middlewares
const sagaMiddleware = createSagaMiddleware();
const routeMiddleWare = routerMiddleware(history);

// Add middlewares
const middleware = [sagaMiddleware, routeMiddleWare];

// Apply configurations and create store
const store = createStore(
  reducers(history),
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

/* Initi persistor */
const persistor = persistStore(store, {}, () => {
  const { token } = store.getState().auth;
  if (token) {
    baseAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
});

sagaMiddleware.run(sagas);

export default store;

export { persistor, history };

import React from 'react';
import PropTypes from 'prop-types';

import List from '../List';
import ListItem from '../ListItem';

import styles from './index.module.scss';

function DataTab(props) {
  const { user } = props;

  return (
    <div className={styles['info-container']}>
      <List>
        <ListItem label='Nome' value={user.name} />
        <ListItem label='Email' value={user.email} />
        <ListItem label='Celular' value={user.phone || 'Não informado'} />
        <ListItem label='CPF' value={user.cpf || 'Não informado'} />
        <ListItem label='Endereço' value={user.address || 'Não informado'} />
      </List>
    </div>
  );
}

DataTab.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    cellphone: PropTypes.string,
    cpf: PropTypes.string,
    address: PropTypes.string,
  }),
};

DataTab.defaultProps = {
  user: {},
};

export default DataTab;

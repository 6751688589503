import MonthlyChargeType from '../../enum/monthlyChargeType';

/* Constant option values */
export const usageOptions = Object.freeze([
  { label: 'Aula', value: true },
  { label: 'Jogo', value: false },
]);

export const shouldGeneratePaymentOptions = Object.freeze([
  { id: 'YES', label: 'Sim', value: 1 },
  { id: 'NO', label: 'Não', value: 0 },
]);

export const monthlyPaymentTypes = Object.freeze([
  { id: '1', label: 'Valor por mês', value: MonthlyChargeType.BY_MONTH },
  { id: '2', label: 'Valor individual', value: MonthlyChargeType.BY_BOOKING },
]);

/* Animation config */
const componentVisibilityVariant = {
  visible: {
    x: '0%',
    opacity: 1,
  },
  hidden: {
    x: '100%',
    opacity: 0,
  },
};

const transition = {
  duration: 0.25,
};

export const config = {
  variants: componentVisibilityVariant,
  transition,
  exit: 'hidden',
};

export const DISABLE_MODALITY = 'PROFESSOR ROLE';
export const INITIAL_MONTHLY_PRICE = 200;
export const INITIAL_SINGLE_PRICE = 0;

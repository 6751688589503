import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './service/moment';
import FCM from './service/firebaseCloudMessaging';

/* Registring Firebase Cloud Messing Service Worker */
(async () => {
  if ('serviceWorker' in navigator) {
    try {
      /* Passing params to custom service worker */
      const params = [
        `appId=${process.env.REACT_APP_FIREBASE_APP_ID}`,
        `apiKey=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        `messagingSenderId=${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
        `projectId=${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
      ].join('&');

      /* Custom service worker path with params */
      const fileName = `${process.env.PUBLIC_URL}/fcm-sw.js?${params}`;

      /**
       * Default firebase-messaging-sw.js does NOT work because of CRA
       *
       * CRA stop from creating or injecting customized service workers
       * so a custom firebase service worker was created as workaround
       *
       * Loading custom firebase service worker.
       */
      const registration = await navigator.serviceWorker.register(fileName);

      console.log('Registration successful, scope is:', registration.scope);

      /* Inject custom service worker on Singleton Firebase Cloud Messaging service */
      // eslint-disable-next-line no-new
      new FCM(registration);
    } catch (ex) {
      console.log('Service worker registration failed, error:', ex);
    }
  }
})();

/* Normal React flux */
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

const PLAYER_LEVEL = Object.freeze({
  BEGINNER: 'Iniciante',
  AVERAGE: 'Intermediário',
  ADVANCED: 'Avançado',
  PROFESSIONAL: 'Profissional',
});

const playerLevelOptions = Object.freeze(
  Object.keys(PLAYER_LEVEL).map(k => ({ label: PLAYER_LEVEL[k], value: k }))
);

const getPlayerLevelLabel = key => PLAYER_LEVEL[key] ?? PLAYER_LEVEL.BEGINNER;

export default PLAYER_LEVEL;
export { playerLevelOptions, getPlayerLevelLabel };

import { subDays } from 'date-fns';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import * as P from '../../providers/payments';
import { formatDate } from '../../utils';

import * as A from './actions';
import * as R from './routines';

function* fetchPayments(action) {
  try {
    yield delay(750);

    yield put(R.fetchPayments.request());

    const _maxDate = formatDate(Date.now());
    const _minDate = formatDate(subDays(Date.now(), 30));

    const {
      page = 1,
      search: s = undefined,
      maxDate = _maxDate,
      minDate = _minDate,
    } = action.payload;

    /* Get active arena  */
    const id = yield select(state => state.config.activeArena);

    const { pageSize, search, payStatus } = yield select(
      state => state.payment.pagination
    );

    const regex = new RegExp(String(s), 'i');

    if ((s && !String(search).match(regex)) || (!s && search)) {
      yield all([
        put(A.resetPaymentPaginationData()),
        put(A.changePaymentSearchParam(s)),
      ]);
    }

    /* Passing arguments to fetchPeople provider */
    const request = yield call(
      P.fetchPayments,
      id,
      {
        page,
        pageSize,
        applicantName: s ?? search,
        payStatus,
        order: 'date:DESC,time:ASC',
        maxDate,
        minDate,
      },
      { version: 3 }
    );

    const { total, page: returnedPage, page_size: perPage, data } = request;

    yield all([
      put(A.addPaginatedPayment(data)),
      put(A.changePaymentPage(returnedPage)),
      put(A.changePaymentPageSize(perPage)),
      put(A.changePaymentTotal(total)),
    ]);
  } catch (ex) {
    yield put(R.fetchPayments.failure());
  } finally {
    yield put(R.fetchPayments.fulfill());
  }
}

export default function* PeopleSaga() {
  yield all([takeLatest(R.fetchPayments, fetchPayments)]);
}

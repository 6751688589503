import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import SideDrawer from '../../containers/SideDrawer';
import { bookingInitialValuesSelector } from '../../store/event/selectors';

import NewReservationForm from '../../containers/NewReservationForm/NewReservationForm';
import styles from './index.module.scss';

/* Local Selectors */

const NewBooking = props => {
  const { closeOnOutsideClick, onPressClose, isOpen } = props;
  const refForm = useRef();

  /* Component selectors */
  const formInitVal = useSelector(bookingInitialValuesSelector);
  const user = useSelector(state => state.user.data);

  const isInstructor = React.useMemo(
    () => user && String(user.role).match(/Professor/i) != null,
    [user]
  );

  const initialValues = React.useMemo(
    () => ({
      ...formInitVal.applicant,
      ...formInitVal.booking,
      bookingClass: formInitVal?.booking?.bookingClass || isInstructor,
    }),
    [formInitVal, isInstructor]
  );

  const _onPressClose = () => {
    if (typeof refForm.current?.reset === 'function') refForm.current.reset();
    if (typeof onPressClose === 'function') onPressClose();
  };

  return (
    <SideDrawer
      closeOnOutsideClick={closeOnOutsideClick}
      onPressClose={_onPressClose}
      isOpen={isOpen}
    >
      <div className={styles.container}>
        <NewReservationForm
          ref={refForm}
          initialValues={initialValues}
          onReset={onPressClose}
        />
      </div>
    </SideDrawer>
  );
};

NewBooking.defaultProps = {
  closeOnOutsideClick: false,
  onPressClose: () => {
    console.log('Button has to have an action');
  },
  isOpen: false,
};

NewBooking.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  onPressClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default NewBooking;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GeneratePagination from '../../utils/pagination';

import styles from './index.module.scss';

const PageButton = props => {
  const { page, active, children, onClick, disabled } = props;

  const PageButtonClassName = classNames({
    [styles['pagination__bt-page']]: true,
    [styles['pagination__bt-page--active']]: active,
  });

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={PageButtonClassName}
      type='button'
    >
      {children || page}
    </button>
  );
};

const ControlButton = props => {
  const { disabled, onClick, action } = props;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={styles['pagination__bt-arrow']}
      type='button'
    >
      {action === 'forward' ? '›' : '‹'}
    </button>
  );
};

const Pagination = props => {
  const { pages, activePage, onSelectPage } = props;

  const [, setActivePage] = useState(activePage);

  const _onSelectPage = page => {
    setActivePage(page);
    if (onSelectPage && typeof onSelectPage === 'function') onSelectPage(page);
  };

  const goBackward = () => {
    const newPage = activePage - 1;
    _onSelectPage(newPage);
  };

  const goForward = () => {
    const newPage = activePage + 1;
    _onSelectPage(newPage);
  };

  const handleKeyPress = event => {
    switch (event.key) {
      case 'ArrowLeft':
        if (activePage > 1) goBackward();
        break;

      case 'ArrowRight':
        if (activePage < pages) goForward();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  const renderPageButtons = () => {
    const pageArray = GeneratePagination({
      page: activePage,
      lastPage: pages,
      skips: 2,
    });

    const pageButtons = [];

    for (let i = 0; i < pageArray.length; i++) {
      pageButtons.push(
        <PageButton
          key={i}
          onClick={() => onSelectPage(pageArray[i])}
          page={pageArray[i]}
          active={activePage === pageArray[i]}
          disabled={pageArray[i] === '...'}
        />
      );
    }

    return pageButtons;
  };

  if (pages <= 1) return null;

  return (
    <div className={styles.pagination}>
      <ControlButton
        action='backward'
        onClick={goBackward}
        disabled={activePage === 1}
      />
      <div className={styles.pagination__pageButtons}>
        {renderPageButtons()}
      </div>
      <ControlButton
        action='forward'
        onClick={goForward}
        disabled={activePage === pages}
      />
    </div>
  );
};

Pagination.defaultProps = {
  pages: 0,
  activePage: 1,
  onSelectPage: () => '',
};

Pagination.propTypes = {
  pages: PropTypes.number,
  activePage: PropTypes.number,
  onSelectPage: PropTypes.func,
};

PageButton.defaultProps = {
  page: 1,
  active: false,
  children: null,
  onClick: () => '',
  disabled: false,
};

PageButton.propTypes = {
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ControlButton.defaultProps = {
  disabled: false,
  action: '',
  onClick: () => '',
};

ControlButton.propTypes = {
  disabled: PropTypes.bool,
  action: PropTypes.string,
  onClick: PropTypes.func,
};

export default Pagination;

import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Colors from '../../sass/_colors.scss';
import { hexToRGBA } from '../../utils';

const DISABLED_COLOR = '#adadad';

const styles = {
  /* react-select styles */
  control: (base, { selectProps }) => {
    const style = {
      ...base,
      backgroundColor: hexToRGBA(Colors.primary, 0.1),
      borderRadius: 0,
      boxShadow: 0,
      borderColor: Colors.primary,
      cursor: 'pointer',
      '&:hover': { borderColor: Colors.primary },
      height: 44,
      fontWeight: 400,
      fontSize: 15,
    };

    if (selectProps?.isDisabled) {
      style.backgroundColor = hexToRGBA(DISABLED_COLOR, 0.1);
      style.borderColor = DISABLED_COLOR;
    }

    return style;
  },
  indicatorSeparator: () => ({}),
  dropdownIndicator: (base, { selectProps }) => {
    if (selectProps?.isDisabled) {
      return { opacity: 0 };
    }

    return {
      ...base,
      color: Colors.primary,
      '&:hover': { color: Colors.primary },
    };
  },
  singleValue: (base, { selectProps }) => {
    const style = { ...base, color: Colors.primary };

    if (selectProps?.isDisabled) {
      style.color = DISABLED_COLOR;
    }

    return style;
  },
  menu: () => ({
    width: '100%',
    backgroundColor: hexToRGBA(Colors.primary, 0.1),
    borderColor: Colors.primary,
    borderWidth: '0 1px 1px 1px',
    borderStyle: 'solid',
  }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? '#000' : Colors.primary,
    backgroundColor: state.isSelected ? Colors.primary : '',
    cursor: 'pointer',
    '&:hover': { backgroundColor: Colors.primary, color: '#000' },
    height: 44,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 15,
  }),
  input: base => ({
    ...base,
    height: 44,
    marginTop: -8,
  }),

  /* This component styles */
  errorContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: 6,
  },
  errorMessage: {
    fontSize: 12,
    lineHeight: 1,
    color: Colors.negative,
  },
};

const Select = props => {
  const {
    defaultValue,
    error,
    id,
    name,
    onChange,
    onClick,
    options,
    placeholder,
    value,
    disabled,
  } = props;

  const _onChange = (...args) => {
    if (onChange && typeof onChange === 'function') {
      onChange(...args);
    }
  };

  const _onClick = (...args) => {
    if (onClick && typeof onClick === 'function') {
      onClick(...args);
    }
  };

  let errorComponent = null;
  if (error) {
    /* In case type of error is a string. Render message */
    if (typeof error === 'string') {
      errorComponent = (
        <div style={styles.errorContainer}>
          <span style={styles.errorMessage}>{error}</span>
        </div>
      );
    }
  }

  return (
    <div role='none' onClick={_onClick}>
      <ReactSelect
        id={id}
        name={name}
        defaultValue={defaultValue}
        options={options}
        styles={styles}
        onChange={_onChange}
        onClick={_onClick}
        value={value}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      {errorComponent}
    </div>
  );
};

Select.defaultProps = {
  defaultValue: null,
  name: '',
  onChange: () => '',
  onClick: () => '',
  options: [],
  placeholder: 'Selecione uma opção...',
  value: '',
  error: null,
  disabled: false,
};

Select.propTypes = {
  defaultValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;

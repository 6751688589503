import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

export default function WarningIcon(props) {
  const {
    width = 14,
    height = 14,
    size,
    fill = Colors.warning,
    color = fill,
  } = props;

  return (
    <svg
      height={size ?? height}
      width={size ?? width}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.61907 0.362212C7.37688 -0.120737 6.62372 -0.120737 6.38153 0.362212L0.0818505 12.9159C0.0251418 13.0285 -0.00297186 13.1546 0.000248621 13.282C0.0034691 13.4093 0.0379139 13.5337 0.100227 13.6428C0.16254 13.752 0.250596 13.8423 0.355815 13.9049C0.461034 13.9675 0.579827 14.0002 0.70062 14H13.3C13.4207 14.0003 13.5394 13.9675 13.6446 13.905C13.7497 13.8424 13.8377 13.7522 13.8999 13.6431C13.9622 13.534 13.9966 13.4097 13.9998 13.2824C14.0029 13.1551 13.9748 13.0291 13.9181 12.9167L7.61907 0.362212ZM7.70027 11.7846H6.30034V10.3077H7.70027V11.7846ZM6.30034 8.83081V5.13854H7.70027L7.70097 8.83081H6.30034Z'
        fill={color}
      />
    </svg>
  );
}

WarningIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

WarningIcon.defaultProps = {
  width: 14,
  height: 14,
  fill: Colors.warning,
  size: undefined,
  color: undefined,
};

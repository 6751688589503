import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Colors from '../../sass/_colors.scss';
import FilterIcon from '../../assets/icons/FilterIcon';
import useOutsideClick from '../../hooks/useOutsideClick';

import CheckboxGroup from '../CheckboxGroup';

import classes from './index.module.scss';

/**
 * @param {object} props
 * @param {import('react').HTMLAttributes<HTMLDivElement>['style']} [props.style]
 * @param {{checked?:boolean;id:string|number;value:string|number;label:string}[]} [props.options=[]]
 * @param {(values: {value:string|number}[]) => void} [props.onChange]
 * @returns
 */
export default function FilterButton(props) {
  const { style, options, onChange } = props;
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [selected, setSelected] = useState([]);

  const optionsRef = useRef();

  const isActive = useMemo(() => selected.length > 0, [selected]);

  useEffect(() => {
    if (onChange != null && typeof onChange === 'function') onChange(selected);
  }, [selected, onChange]);

  useOutsideClick(optionsRef, () => {
    setOptionsVisible(false);
  });

  const _options = useMemo(
    () => options.map(o => ({ ...o, checked: selected.includes(o.value) })),
    [options, selected]
  );

  /**
   *
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const handleChange = event => {
    setSelected(prev => {
      const { value } = event.target;
      const found = prev.findIndex(val => val === value);

      if (found > -1) {
        return [...prev.slice(0, found), ...prev.slice(found + 1)];
      }

      return [...prev, value];
    });
  };

  const handleClick = () => {
    setOptionsVisible(true);
  };

  return (
    <motion.div
      className={classes.root}
      style={style}
      onClick={handleClick}
      ref={optionsRef}
    >
      <FilterIcon color={isActive ? Colors.primary : undefined} />

      <AnimatePresence>
        {isOptionsVisible ? (
          <motion.div
            style={{ originY: 0 }}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            className={classes.optionsContainer}
          >
            <CheckboxGroup
              options={_options}
              onChange={handleChange}
              style={{
                option: { marginRight: '0.5rem', marginBottom: '0.5rem' },
              }}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
}

FilterButton.propTypes = {
  // Because propTypes cannot recognize Style Prop
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
};

FilterButton.defaultProps = {
  style: undefined,
  onChange: undefined,
  options: [],
};

import { baseAPI, v2API } from '../../service/axios';
import camelizeObject from '../../utils/camelizeObject';

const fetchArena = async id => {
  const res = await baseAPI.get(`arena/${id}`);

  return res.data;
};

export default fetchArena;

/**
 *
 * @param {string|number} id
 * @returns
 */
export const fetchArenaV2 = id =>
  v2API
    .get(`arena/${id}`)
    .then(res => ({ ...res.data, ...camelizeObject(res.data) }));

import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props
 * @param {string} [props.color='black']
 * @param {string|number} [props.size=20]
 * @returns
 */
export default function PlusIcon(props) {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 9.587L17.745 9.481M9.587 2L9.769 17.55L9.587 2Z'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

PlusIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

PlusIcon.defaultProps = {
  size: 20,
  color: 'black',
};

/**
 * Function to convert strings from camel case to pascal case
 *
 * @param {string} str
 *
 * @returns {string} Retuns pascalized string
 */
const toPascalCase = str => {
  if (!str) return '';

  const _str = String(str);

  return _str.replace(
    /([a-z])(_+[a-z])/gi,
    (_, p1, p2) => p1 + p2.replace(/_+/, '').toUpperCase()
  );
};

export default toPascalCase;

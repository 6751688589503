// import union from 'lodash/union';

import { fetchEvents } from '../routines';
import * as types from '../types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'EVENT_CLEAR':
      return [];
    case fetchEvents.SUCCESS:
      // return union([], state, action.payload.order);
      return action.payload.order;
    case types.REMOVE_BOOKING:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

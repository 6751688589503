import React from 'react';
import PropTypes from 'prop-types';

import List from '../List';
import ListItem from '../ListItem';

/**
 * @typedef BookingContentProps
 * @property {string} days
 * @property {string} hours
 * @property {boolean} isClass
 * @property {string} modality
 * @property {string} playerLevel
 * @property {Object} reservedArena
 * @property {string} reservedArena.title
 * @property {string} reservedArena.profile
 * @property {'Avulsa'|'Mensal'} type
 */

/**
 *
 * @component BookingContent
 * @param {BookingContentProps} props
 */
export default function BookingContent(props) {
  const {
    days,
    hour,
    isClass,
    modality,
    playerLevel,
    reservedArena,
    type,
    court,
  } = props;

  const title = React.useMemo(
    () => (isClass ? 'Dados da aula' : 'Dados da reserva'),
    [isClass]
  );

  const courtTitle = React.useMemo(() => (isClass ? 'Vaga' : 'Quadra'), [
    isClass,
  ]);

  const recurrenceTitle = React.useMemo(
    () => (isClass ? 'Aula' : 'Tipo de reserva'),
    [isClass]
  );

  return (
    <List hasBorderBottom title={title}>
      {isClass && (
        <>
          {reservedArena && (
            <ListItem
              label='Espaço esportivo reservado'
              value={reservedArena.title}
            />
          )}
          <ListItem label='Nível de Habilidade' value={playerLevel} />
        </>
      )}
      <ListItem label={courtTitle} value={court} />
      <ListItem label='Modalidade' value={modality} />
      <ListItem label={recurrenceTitle} value={type} />
      <ListItem label='Dias da Semana' value={days} />
      <ListItem label='Horário' value={hour} />
    </List>
  );
}

BookingContent.propTypes = {
  court: PropTypes.string,
  days: PropTypes.string,
  hour: PropTypes.string,
  isClass: PropTypes.bool,
  modality: PropTypes.string,
  playerLevel: PropTypes.string,
  reservedArena: PropTypes.shape({
    title: PropTypes.string,
    profile: PropTypes.string,
  }),
  type: PropTypes.oneOf(['Avulsa', 'Mensal']),
};

BookingContent.defaultProps = {
  court: '',
  days: '',
  hour: '',
  isClass: false,
  modality: '',
  playerLevel: '',
  reservedArena: null,
  type: 'Avulsa',
};

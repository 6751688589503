import isDev from './isDev';

/**
 * @typedef MappedObject
 * @property {string[]|number[]} order
 * @property {Object<string, *>} byID
 *
 * Function to transform an array to a mappedObject by its ID
 *
 * @param {array} array - Array to be mapped
 *
 * @returns {MappedObject|null} Return an object with format { byID: {}, order: [] } return null on fail or if array is empty
 */
const toMappedObject = array => {
  let result = null;

  try {
    if (isDev) console.time('toMappedObject');

    /** Check if param is array and has length bigger than zero */
    if (Array.isArray(array)) {
      if (array.length > 0) {
        /** Create result object */
        result = { byID: {}, order: [] };

        for (let index = 0; index < array.length; index++) {
          const element = array[index];

          /** Try to extract ID from element, if undefined use index */
          const id = element.id || index;

          result.byID[id] = element;
          result.order.push(id);
        }
      }
    }

    return result;
  } catch (ex) {
    return null;
  } finally {
    if (isDev) console.timeEnd('toMappedObject');
  }
};

export default toMappedObject;

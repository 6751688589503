import { createSelector } from 'reselect';
import { arenaCourtsSelector } from '../arena/selectors';

// eslint-disable-next-line import/prefer-default-export
export const activeHoursSelector = createSelector(
  [arenaCourtsSelector],
  courts => {
    const hours = { opens: null, closes: null };

    if (courts != null) {
      if (Array.isArray(courts) && courts.length) {
        const schedules = courts.reduce((acc, cur) => {
          if (
            cur.schedules != null &&
            Array.isArray(cur.schedules) &&
            cur.schedules.length
          ) {
            return [...acc, ...cur.schedules];
          }
          return acc;
        }, []);

        if (schedules.length) {
          const sorted = schedules.flat(1).sort();

          if (sorted.length > 0) {
            const regex = /(\d{1,2})(\d{2})$/;

            const first = sorted[0];
            const last = sorted[sorted.length - 1];

            const [, fh, fm] = String(first).match(regex);
            const [, lh, lm] = String(last).match(regex);

            if (fh && fm) {
              const opens = new Date(0, 0, 0, Number(fh), Number(fm), 0);
              hours.opens = opens;
            }

            if (lh && lm) {
              /* Stops reserved hour from going to next day and bug react-calendar visual */
              let min = 59;
              let sec = 59;

              /**
               * Round off the time because react-calendar does not include a time like: 20:29:59 as 20:30:00
               * and it will not show the last hour of the day.
               * */
              if (lm === '30') {
                min = 60;
                sec = 0;
              }

              const _min = Number(lm);

              if (_min > 0 && _min <= 59) {
                min -= _min;
              }

              const closes = new Date(0, 0, 0, Number(lh), min, sec);
              hours.closes = closes;
            }
          }
        }
      }
    }

    return hours;
  }
);

import * as Yup from 'yup';

import BOOKING_TYPE from '../../enum/bookingType';
import { todayAtMidnight } from './helpers';

/* SECTION Validation Schema */
const validationSchema = Yup.object().shape({
  /* Validate court */
  court: Yup.number()
    .nullable()
    .required('Campo obrigatório'),
  /* Validate modality */
  /* modality: Yup.string()
    .nullable()
    .required('Campo obrigatório'), */
  /* Validate booking type */
  bookingType: Yup.mixed()
    .nullable()
    .oneOf(Object.values(BOOKING_TYPE), 'Recorrrência não valida.')
    .required('Campo obrigatório'),
  /* Validate booking hour */
  schedule: Yup.string()
    .nullable()
    .required('Campo obrigatório'),
  /* Validate booking date only if booking type is single */
  date: Yup.mixed().when('bookingType', {
    is: BOOKING_TYPE.SINGLE,
    then: Yup.date('Não é uma data válida')
      .min(todayAtMidnight(), 'Data não pode ser no passado.')
      .required('Campo obrigatório'),
  }),
  /* Validate weekdays only if booking type is montlhy */
  weekDays: Yup.mixed().when('bookingType', {
    is: BOOKING_TYPE.MONTHLY,
    then: Yup.string()
      .min(1, 'Necessário escolher ao menos um dia.')
      .required('Campo obrigatório'),
  }),
  scheduleBegin: Yup.mixed().when('bookingType', {
    is: BOOKING_TYPE.MONTHLY,
    then: Yup.date('Não é uma data válida')
      .min(todayAtMidnight(), 'Data não pode ser no passado.')
      .required('Campo obrigatório'),
  }),
  scheduleEnd: Yup.mixed().when('bookingType', {
    is: BOOKING_TYPE.MONTHLY,
    then: Yup.date('Não é uma data válida')
      .nullable()
      .min(
        Yup.ref('scheduleBegin'),
        'Data de término não pode ser antes do início.'
      )
      .required('Campo obrigatório'),
  }),
});

export default validationSchema;

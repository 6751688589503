import { all, put, takeEvery, call, select, delay } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';

import routes from '../../data/routes';

/* Services */
import { baseAPI } from '../../service/axios';
import FCM from '../../service/firebaseCloudMessaging';

/* Types */
import { UNAUTHENTICATE, AUTHENTICATE, CREATE_NEW_ACCOUNT } from './types';

/* Actions */
import { authenticateError, authenticateOk, unauthenticate } from './actions';

/* Routines */
import { fetchUser } from '../user/routines';
import { fetchCourts } from '../court/routines';

/* Providers */
import { authenticate, createAccount } from '../../providers/auth';
import fetchUserPerfil from '../../providers/user/fetchUserPerfil';
import fetchArenaBatch from '../../providers/arenas/fetchArenaBatch';
import saveNotificationToken from '../../providers/user/saveNotificationToken';

/* Utils */
import { toggleActiveArena } from '../config/actions';

let toastRef = null;

function* login({ payload }) {
  try {
    let token = '';

    if (payload.token) {
      token = payload.token;
    } else {
      token = '';
      token = yield call(authenticate, payload);
    }

    window.localStorage.setItem('AUTHENTICATION_BEARER', token);

    /* Promise all */
    const userPerfil = yield call(fetchUserPerfil);

    /* Populating user, arenas e persons */
    if (userPerfil) {
      const { role } = userPerfil;

      if (!role || !role.match(/administrador|gerente|professor/i)) {
        throw new Error('Lamento mas você não tem acesso a este painel.');
      }

      if (userPerfil.manages) {
        const { manages } = userPerfil;

        let _arenaID = null;

        if (Array.isArray(manages) && manages.length) {
          [_arenaID] = manages;
        } else if (manages) {
          _arenaID = manages;
        }

        if (_arenaID) {
          yield all([
            put(toggleActiveArena(_arenaID)),
            /* Fetch Courts from Arena managed by User */
            put(fetchCourts(_arenaID)),
          ]);
        }
      }

      if (userPerfil.works && userPerfil.works.length) {
        const { arenas: workArenas } = yield call(
          fetchArenaBatch,
          userPerfil.works
        );

        userPerfil.works = workArenas;
      }

      /* Link FCM token to user */
      new FCM()
        .askForPermission()
        .then(fcmToken => saveNotificationToken(fcmToken))
        .then(res => {
          if (res) {
            /* Token saved */
          } else {
            /* Token failed */
          }
        })
        .catch(() => {
          /* askForPermission exception */
        });
    }

    yield all([put(authenticateOk(token)), put(fetchUser.success(userPerfil))]);

    // Find current route
    const currentPath = yield select(state => state.router.location.pathname);
    const route = routes.find(r => currentPath.match(r.regex));

    /**
     * If current route isn't a private one and
     * user is authenticated redirect to /home
     *
     * Else user is reloading page. Continue on same page
     */
    if (!route.private) {
      yield put(replace('/agenda'));
    }
  } catch (ex) {
    if (payload.token) {
      // Token expired
      yield put(unauthenticate());
    } else {
      // Invalid credentials
      toast(ex.message, { type: toast.TYPE.ERROR });
      yield put(authenticateError());
    }
  }
}

function* newAccount({ payload }) {
  try {
    const res = yield call(createAccount, payload);

    if (res) {
      yield put(authenticate(payload));
    } else {
      toast('Não foi possível criar sua conta no momento.', {
        type: toast.TYPE.ERROR,
      });
    }
  } catch (ex) {
    toast(ex.message, { type: toast.TYPE.ERROR });
  }
}

function* logout({ payload }) {
  delete baseAPI.defaults.headers.common.Authorization;

  /* Force localstorage clean-up. Persistor not working correctly */
  window.localStorage.removeItem('persist:weplayapp-arena:auth');

  window.localStorage.removeItem('AUTHENTICATION_BEARER');

  if (payload) {
    if (toastRef === null) {
      toastRef = toast(payload, {
        onClose() {
          toastRef = null;
        },
      });
    }
  }

  yield all([put({ type: 'RESET_STORE' }), put(replace('/'))]);
}

function* rehydrate() {
  yield put({ type: 'APP_LOADING', payload: true });

  const token = yield select(state => state.auth.token);
  if (token) {
    yield call(login, { payload: { token } });

    yield delay(1500);
  }

  yield put({ type: 'APP_LOADED', payload: false });
}

export default function* authSaga() {
  yield all([
    takeEvery(AUTHENTICATE, login),
    takeEvery(CREATE_NEW_ACCOUNT, newAccount),
    takeEvery(UNAUTHENTICATE, logout),
    takeEvery('persist/REHYDRATE', rehydrate),
  ]);
}

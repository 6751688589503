/* CRUD */
export const ADD_BOOKING_REQUEST = 'BOOKING_REQUEST/ADD';
export const REMOVE_BOOKING_REQUEST = 'BOOKING_REQUEST/REMOVE';

/* UI */
export const CLEAR_ORDER_BOOKING_REQUEST = 'BOOKING_REQUEST/CLEAR_ORDER';

/* PAGINATION */
export const CHANGE_MAX_PAGES_BOOKING_REQUEST =
  'BOOKING_REQUEST/CHANGE_MAX_PAGES';
export const CHANGE_PAGE_BOOKING_REQUEST = 'BOOKING_REQUEST/CHANGE_PAGE';
export const CHANGE_PAGE_SIZE_BOOKING_REQUEST =
  'BOOKING_REQUEST/CHANGE_PAGE_SIZE';
export const CHANGE_SEARCH_PARAM_BOOKING_REQUEST =
  'BOOKING_REQUEST/CHANGE_SEARCH_PARAM';
export const CHANGE_TOTAL_BOOKING_REQUEST = 'BOOKING_REQUEST/CHANGE_TOTAL';
export const RESET_BOOKING_REQUEST_PAGINATION_DATA =
  'BOOKING_REQUEST/RESET_PAGINATION_DATA';

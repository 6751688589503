import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';

import Colors from '../../sass/_colors.scss';

const ConfirmToast = props => {
  const { onConfirm, onCancel, question, closeToast } = props;

  const handleCancel = () => {
    closeToast();
    if (onCancel && typeof onCancel === 'function') onCancel();
  };

  const handleConfirm = () => {
    closeToast();
    if (onConfirm && typeof onConfirm === 'function') onConfirm();
  };

  return (
    <div style={{ display: 'flex', flexFlow: 'column wrap' }}>
      <div style={{ flex: 1, marginBottom: 16 }}>
        <span>{question}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, marginRight: 8 }}>
          <Button
            onClick={handleConfirm}
            background={Colors.positive}
            title='Confirmar'
          />
        </div>
        <div style={{ flex: 1, marginLeft: 8 }}>
          <Button
            onClick={handleCancel}
            outline
            background={Colors.negative}
            title='Cancelar'
          />
        </div>
      </div>
    </div>
  );
};

ConfirmToast.propTypes = {
  closeToast: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmToast;

import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

export default function LockIcon(props) {
  const { width, height, stroke, fill } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 14 17'
    >
      <g fill={fill} fillRule='evenodd' stroke={stroke} strokeWidth='2'>
        <path d='M3 7.25a2 2 0 0 0-2 2v4.5a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-4.5a2 2 0 0 0-2-2H3zM3.5 7.125V4.5a3.5 3.5 0 0 1 7 0v2.625' />
      </g>
    </svg>
  );
}

LockIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
  fill: PropTypes.string,
};

LockIcon.defaultProps = {
  width: 14,
  height: 17,
  fill: 'none',
  stroke: Colors.primary,
};

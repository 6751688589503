import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Table = props => {
  const { children } = props;

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Tipo de reserva</th>
          <th>Data da reserva</th>
          <th>Horário</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>

      <tbody>{children}</tbody>
    </table>
  );
};

Table.defaultProps = {
  children: null,
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Table;

import React from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { useSelector } from 'react-redux';

import AgendaEvent, { EVENT_RECURRENCE } from '../AgendaEvent';

import AgendaLoader from '../AgendaLoader';

import './index.scss';
import EmptyStateMessage from '../EmptyStateMessage';

const localizer = momentLocalizer(moment);

const Event = props => {
  const { event } = props;
  const { start, end, resource, resourceId, id } = event;
  const {
    recurrence,
    sport,
    user,
    isClass,
    duration,
    reservedArena,
  } = resource;

  return (
    <AgendaEvent
      courtId={resourceId}
      reservedArena={reservedArena}
      end={end}
      id={id}
      recurrence={recurrence}
      sport={sport}
      start={start}
      user={user}
      isClass={isClass}
      duration={duration}
    />
  );
};
// eslint-disable-next-line react/forbid-prop-types
Event.propTypes = { event: PropTypes.object };
Event.defaultProps = { event: {} };

const AgendaContainer = props => {
  const { min, max, events, resources, date } = props;

  const isLoading = useSelector(state => state.event.ui.loading);

  return (
    <div className='agenda-wrapper'>
      <Calendar
        min={min}
        max={max}
        date={date}
        toolbar={false}
        events={events}
        localizer={localizer}
        defaultView={Views.DAY}
        views={['day']}
        step={30}
        resources={resources}
        resourceIdAccessor='id'
        resourceTitleAccessor='title'
        components={{ event: Event }}
        showMultiDayTimes
      />
      <AgendaLoader />
      {!isLoading && (!events || !events.length) && (
        <EmptyStateMessage
          message='Nenhum horário disponível no dia selecionado.'
          maxWidth={250}
        />
      )}
    </div>
  );
};

AgendaContainer.defaultProps = {
  date: new Date(),
  min: new Date(0, 0, 0, 0, 0, 0, 0),
  max: new Date(0, 0, 0, 23, 59, 59, 0),
  events: [],
  resources: [],
};

AgendaContainer.propTypes = {
  date: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
      resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      resource: PropTypes.shape({
        sport: PropTypes.string,
        user: PropTypes.string,
        recurrence: PropTypes.oneOf(Object.values(EVENT_RECURRENCE)),
      }),
    })
  ),
};

export default AgendaContainer;

/* eslint-disable react/button-has-type */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import defaultImage from '../../assets/image/logo192.png';

import LogoutButton from '../LogoutButton';

import styles from './index.module.scss';

const HeaderButton = props => {
  const { route, title } = props;

  const pathname = useSelector(state => state.router.location.pathname);

  const classname = classNames({
    [styles.button]: true,
    [styles.active]: pathname === route,
  });

  return (
    <motion.button className={classname}>
      <Link to={route}>{title}</Link>
    </motion.button>
  );
};
HeaderButton.propTypes = {
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const ActiveArenaIndicator = props => {
  const { name, image } = props;
  const [_image, setImage] = useState(image);

  if (!_image || (typeof _image === 'string' && !_image.length)) {
    setImage(defaultImage);
  }

  const replaceImage = () => {
    setImage(defaultImage);
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className={styles['active-arena-indicator-wrapper']}
    >
      <div className={styles['active-arena-indicator__dropdown']}>
        <LogoutButton />
      </div>
      <div className={styles['active-arena-indicator']}>
        <div className={styles['active-arena-indicator__logo']}>
          <img src={_image} alt={name} onError={replaceImage} />
        </div>
        <div className={styles['active-arena-indicator__name']}>{name}</div>
      </div>
    </motion.div>
  );
};
ActiveArenaIndicator.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

const Header = () => {
  const user = useSelector(state => state.user.data);
  const hasToken = useSelector(state => !!state.auth.token);

  const pages = useMemo(() => {
    const _pages = [{ route: '/agenda', title: 'Agenda' }];

    if (!String(user?.role).match(/professor/i)) {
      _pages.push({ route: '/clients', title: 'Clientes' });
    } else {
      _pages.push({ route: '/students', title: 'Alunos' });
    }

    _pages.push({ route: '/payments', title: 'Pagamentos' });

    return _pages;
  }, [user?.role]);

  if (!hasToken) return null;

  return (
    <header className={styles.header}>
      <nav>
        {pages.map(page => (
          <HeaderButton
            title={page.title}
            key={page.title}
            route={page.route}
          />
        ))}
      </nav>

      <div>
        {user && <ActiveArenaIndicator name={user.name} image={user.image} />}
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import PropTypes from 'prop-types';

import classes from './index.module.scss';

/**
 *
 * @param {object} props
 * @param {string} [props.title]
 * @param {string} [props.value]
 * @returns
 */
export default function Section(props) {
  const { title, value } = props;

  return (
    <div className={classes.section}>
      <span className={classes.title}>{title}</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
}

Section.defaultProps = {
  title: undefined,
  value: undefined,
};

Section.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

/**
 * @typedef {React.SVGProps<SVGSVGElement>} SVGProps
 */

/**
 * @typedef {object} Props
 * @property {number|string} [props.size]
 * @property {string} [props.color]
 */

/**
 *
 * @param {Props & SVGProps} props
 * @returns {JSX.Element}
 */
function FilterIcon(props, ref) {
  const { size = 20, color = 'rgba(255,255,255,0.38)', ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      width={size}
      height={calcHeight(size)}
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 10C4.14 10 2.59 11.28 2.14 13H0V15H2.14C2.59 16.72 4.14 18 6 18C7.86 18 9.41 16.72 9.86 15H20V13H9.86C9.41 11.28 7.86 10 6 10ZM6 16C4.9 16 4 15.1 4 14C4 12.9 4.9 12 6 12C7.1 12 8 12.9 8 14C8 15.1 7.1 16 6 16ZM17.86 3C17.41 1.28 15.86 0 14 0C12.14 0 10.59 1.28 10.14 3H0V5H10.14C10.59 6.72 12.14 8 14 8C15.86 8 17.41 6.72 17.86 5H20V3H17.86ZM14 6C12.9 6 12 5.1 12 4C12 2.9 12.9 2 14 2C15.1 2 16 2.9 16 4C16 5.1 15.1 6 14 6Z'
        fill={color}
      />
    </svg>
  );
}

FilterIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

FilterIcon.defaultProps = {
  size: '20px',
  color: 'rgba(255,255,255,0.38)',
};

const calcHeight = value => {
  if (typeof value === 'number') {
    return `calc(${value}px * 18 / 20)`;
  }

  if (typeof value === 'string' && !Number.isNaN(value)) {
    return `calc(${value} * 18 / 20)`;
  }

  return `18px`;
};

export default forwardRef(FilterIcon);

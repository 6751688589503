import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { toast, ToastPosition } from 'react-toastify';

/* ENUM */
import BOOKING_TYPE, { getBookingTypeString } from '../../enum/bookingType';
import { getWeekDayString } from '../../enum/weekDay';

/* Containers */
import SideDrawer from '../../containers/SideDrawer';
import BookingForm from '../../containers/BookingForm';

/* Components */
import Button from '../Button';
import List from '../List';
import ListItem from '../ListItem';
import ConflictIcon from '../ConflictIcon';
import ConfirmToast from '../ConfirmationToast';

/* Routines */
import * as R from '../../store/httpRequest/routines';

/* Styles */
import styles from './index.module.scss';

/* Local Selectors */
const bookingRequestIDSelector = state => {
  return state.sideDrawer.bookingDetail.selected;
};

const isCancellingSelector = state => {
  return state.httpRequest.cancelBookingRequest.loading;
};

const isSubmittingSelector = state => {
  return state.httpRequest.approveBookingRequest.loading;
};

const bookingRequestSelector = createSelector(
  state => state.bookingRequest.data,
  (_, id) => id,
  (requests, id) => {
    const list = requests.filter(x => x.id === id);

    if (list && list.length) return list[0];

    if (requests && requests.length && id) return 'Reserva não encontrada.';

    return null;
  }
);

const bookingSelector = createSelector(
  [state => state.event.byID, state => state.person.byID, (_, id) => id],
  (bookings, applicants, id) => {
    const booking = bookings[id];

    if (booking) {
      if (booking.applicant_id) {
        const { name, email, telephone } = applicants[booking.applicant_id];

        return {
          id: booking.id,
          applicantId: id,
          applicantName: name,
          applicantEmail: email,
          applicantCellphone: telephone,
          bookingType:
            BOOKING_TYPE.SINGLE /* Force single type. User cannot change to monthly here */,
          bookingDatetime: booking.booking_date,
          modality: booking.modality,
          // weekEntries: booking,
        };
      }
    }
    return null;
  }
);

/* Build applicant data */
const buildApplicantArray = booking => {
  const data = [];

  if (booking) {
    if (booking.applicantName) {
      data.push({ value: booking.applicantName, label: 'Nome' });
    }

    if (booking.applicantEmail) {
      data.push({ value: booking.applicantEmail, label: 'E-mail' });
    }

    if (booking.applicantCellphone) {
      data.push({ value: booking.applicantCellphone, label: 'Telefone' });
    }
  }

  return data;
};

/* Build booking request data */
const buildRequestArray = request => {
  const data = [];

  if (request) {
    const { bookingType } = request;
    if (bookingType !== null) {
      data.push({
        value: getBookingTypeString(bookingType),
        label: 'Aula',
      });

      const { modality, bookingClass } = request;

      if (bookingClass != null) {
        data.push({
          value: bookingClass ? 'Aula' : 'Jogo',
          label: 'Utilização',
        });
      }

      if (modality) {
        data.push({ value: modality, label: 'Modalidade' });
      }

      switch (bookingType) {
        case BOOKING_TYPE.MONTHLY: {
          const { weekEntries } = request;
          if (weekEntries) {
            if (Array.isArray(weekEntries) && weekEntries.length) {
              const days = weekEntries
                .map(x => getWeekDayString(x.weekDay))
                .join(', ');
              data.push({ value: days, label: 'Dias da semana' });
              data.push({ value: weekEntries[0].dayTime, label: 'Horário' });
            }
          }
          break;
        }
        case BOOKING_TYPE.SINGLE: {
          const { bookingDatetime } = request;
          if (bookingDatetime) {
            const date = moment(bookingDatetime);

            data.push({ value: date.format('DD/MM/YYYY'), label: 'Dia' });
            data.push({ value: date.format('HH:mm:ss'), label: 'Horário' });
          }
          break;
        }
        default:
          break;
      }
    }
  }

  return data;
};

const ConflictIndicator = () => (
  <div className={styles.conflictIndicator}>
    <ConflictIcon /> <span>Possui conflito com outro pedido.</span>
  </div>
);

const BookingDetailDrawer = props => {
  const dispatch = useDispatch();

  /* Component props */
  const { closeOnOutsideClick, onPressClose, isOpen } = props;

  /* Component State */
  const [submitBooking, setSubmitBooking] = useState(null);
  /**
   * Change component behavior if it is on edit mode
   */
  const [isEditMode, setEditMode] = useState(false);

  /* Selectors */
  const selectedBookingID = useSelector(bookingRequestIDSelector);
  const selectedBooking = useSelector(state => {
    const match = String(selectedBookingID).match(/edit_(\d+)/);
    if (match) {
      const [, id] = match;

      return bookingSelector(state, id);
    }

    return bookingRequestSelector(state, selectedBookingID);
  });
  const isCancelling = useSelector(isCancellingSelector);
  const isSubmitting = useSelector(isSubmittingSelector);

  /* Effects */
  useEffect(() => {
    const match = String(selectedBookingID).match(/edit_(\d+)/);

    if (match) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [selectedBookingID]);

  /* Applicant Data */
  const applicantData = buildApplicantArray(selectedBooking);
  /* Request Data */
  const requestData = buildRequestArray(selectedBooking);

  const cancelBooking = () => {
    const cancel = () => dispatch(R.cancelBookingRequest(selectedBookingID));

    if (!isEditMode) {
      toast(
        <ConfirmToast
          question='Deseja recusar esta reserva?'
          onConfirm={cancel}
        />,
        { autoClose: 5000, position: ToastPosition.BOTTOM_RIGHT }
      );
    } else {
      cancel();
    }
  };

  const handleFormSubmit = () => {
    submitBooking();
  };

  const onFormSubmit = values => {
    /* Applicant data */
    const {
      id,
      applicantId,
      applicantName,
      applicantEmail,
      applicantCellphone,
    } = selectedBooking;

    const payload = {
      ...values,
      id,
      applicantId,
      applicantName,
      applicantEmail,
      applicantCellphone,
    };

    if (isEditMode) {
      dispatch(R.createEditBooking({ ...payload, edit: true }));
    } else {
      dispatch(R.approveBookingRequest(payload));
    }
  };

  let sentAt = '';
  if (selectedBooking && selectedBooking.creationDate) {
    const date = moment(selectedBooking.creationDate).format('LL [-] HH[h]mm');

    sentAt = (
      <>
        Pedido enviado em <span>{date}</span>
      </>
    );
  }

  /* Show conflict indicator */
  let conflict = false;
  if (selectedBooking) {
    if (selectedBooking.conflits) {
      const { conflicts } = selectedBooking;
      if (Array.isArray(conflicts) && conflicts.length) {
        conflict = true;
      }
    }
  }

  /* Applicatn data */
  let bookingRequest = null;
  if (applicantData.length > 0) {
    const items = [];
    for (let i = 0; i < applicantData.length; i++) {
      const { value, label } = applicantData[i];
      items.push(<ListItem key={i} value={value} label={label} />);
    }
    bookingRequest = (
      <List
        hasBorderBottom
        title={isEditMode ? 'Reserva' : 'Pedido de Reserva'}
      >
        {items}
      </List>
    );
  }

  /* Show requested booking data */
  let requestValues = null;
  if (requestData.length > 0) {
    const _temp = [...requestData];

    /* Hide booking type value if is on editing mode */
    if (isEditMode) {
      const index = _temp.findIndex(x => x.label === 'Tipo de aula');

      if (index > -1) _temp.splice(index, 1);
    }

    const items = [];
    for (let i = 0; i < _temp.length; i++) {
      const { value, label } = _temp[i];
      items.push(<ListItem key={i} value={value} label={label} />);
    }
    requestValues = (
      <List hasBorderBottom title={isEditMode ? 'Dados atuais' : 'Pedido'}>
        {items}
      </List>
    );
  }

  return (
    <SideDrawer
      closeOnOutsideClick={closeOnOutsideClick}
      onPressClose={onPressClose}
      isOpen={isOpen}
    >
      <div className={styles.container}>
        <div className={styles['container-content']}>
          {conflict && <ConflictIndicator />}
          {bookingRequest}
          {requestValues}
          <div className={styles['order-data']}>
            <div className={styles.title}>Dados do Pedido</div>
            {selectedBooking && (
              <BookingForm
                initialValues={{
                  bookingType: selectedBooking.bookingType,
                  dateTime: selectedBooking.bookingDatetime,
                  weekEntries: selectedBooking.weekEntries,
                  modality: selectedBooking.modality,
                  bookingClass: selectedBooking.bookingClass,
                }}
                lock={{ modality: true, bookingType: isEditMode }}
                bindSubmit={setSubmitBooking}
                onSubmit={onFormSubmit}
              />
            )}
          </div>
          <div className={styles.orderTimeStamp}>{sentAt}</div>
        </div>
        <div className={styles['container-button']}>
          <Button
            outline
            background='#eb4e52'
            title={isEditMode ? 'Cancelar' : 'Recusar'}
            onClick={cancelBooking}
            loading={isCancelling}
            disabled={isSubmitting}
          />
          <Button
            background='#61b04e'
            title={isEditMode ? 'Atualizar' : '✓ Aceitar'}
            color='#ffffff'
            onClick={handleFormSubmit}
            loading={isSubmitting}
            disabled={isCancelling}
          />
        </div>
      </div>
    </SideDrawer>
  );
};

BookingDetailDrawer.defaultProps = {
  closeOnOutsideClick: false,
  onPressClose: () => {
    console.log('Button has to have an action');
  },
  isOpen: false,
};

BookingDetailDrawer.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  onPressClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default BookingDetailDrawer;

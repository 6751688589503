/**
 * @typedef MonthlyChargeType
 * @property {0} BY_BOOKING
 * @property {1} BY_MONTH
 */

/** @type {MonthlyChargeType} */
const _monthlyChargeType = {
  BY_BOOKING: '0',
  BY_MONTH: '1',
};

const MonthlyChargeType = Object.freeze(_monthlyChargeType);

export default MonthlyChargeType;

import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

export default function UserIcon(props) {
  const { width, height, stroke, fill } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 15 19'
    >
      <g
        fill={fill}
        fillRule='evenodd'
        stroke={stroke}
        strokeWidth='2'
        transform='translate(0 1)'
      >
        <path d='M1 16.5h13v-3.563a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2V16.5z' />
        <circle cx='7.604' cy='3.75' r='3.75' />
      </g>
    </svg>
  );
}

UserIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
  fill: PropTypes.string,
};

UserIcon.defaultProps = {
  width: 15,
  height: 19,
  fill: 'none',
  stroke: Colors.primary,
};

import * as types from '../types';

const initialState = () => ({
  order: null,
  page: 1,
  pageSize: 20,
  search: null,
  total: null,
  payStatus: [],
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case types.CHANGE_TOTAL_PAYMENT:
      return { ...state, total: action.payload };
    case types.CHANGE_PAGE_PAYMENT:
      return { ...state, page: action.payload };
    case types.CHANGE_PAGE_SIZE_PAYMENT:
      return { ...state, pageSize: action.payload };
    case types.CHANGE_SEARCH_PARAM_PAYMENT:
      return { ...state, search: action.payload };
    case types.CHANGE_PAY_STATUS_PARAM_PAYMENT:
      return { ...state, payStatus: action.payload };
    case types.RESET_PAYMENT_PAGINATION_DATA:
      return { ...initialState(), payStatus: state.payStatus };
    case types.RESET_PAYMENT_STORE:
      return initialState();
    default:
      return state;
  }
};

const generatePagination = args => {
  const { page, lastPage, firstPage = 1, skips = 2 } = args;

  const pages = [];

  const skipsBefore = typeof skips === 'number' ? skips : skips.before;
  const skipsAfter = typeof skips === 'number' ? skips : skips.after;

  const before = [];
  const after = [];

  let _page = page;

  if (page < firstPage) _page = firstPage;
  if (page > lastPage) _page = lastPage;

  for (let i = 1; i <= skipsBefore; i++) {
    const previous = _page - i;
    if (previous > firstPage) before.unshift(_page - i);
  }
  for (let i = 1; i <= skipsAfter; i++) {
    const next = _page + i;
    if (next < lastPage) after.push(_page + i);
  }

  if (before.length) {
    const toAdd = before;
    if (before.length === skipsBefore && before[0] - 1 !== firstPage) {
      toAdd.unshift('...');
    }
    pages.splice(0, 0, ...before);
  }

  if (_page > firstPage && _page < lastPage) pages.push(_page);

  if (after.length) {
    const toAdd = after;
    if (
      after.length === skipsAfter &&
      after[after.length - 1] + 1 !== lastPage
    ) {
      toAdd.push('...');
    }
    pages.splice(pages.length, 0, ...after);
  }

  pages.unshift(firstPage);
  pages.push(lastPage);

  return pages;
};

export default generatePagination;

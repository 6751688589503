// import merge from 'lodash/merge';

import { fetchEvents } from '../routines';
import * as types from '../types';

const initialState = {};

/**
 * Store events as a mapped object where each key is its ID
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case 'EVENT_CLEAR':
      return {};
    case fetchEvents.SUCCESS:
      // return merge({}, state, action.payload.byID);
      return action.payload.byID;
    case types.REMOVE_BOOKING: {
      const newState = { ...state };
      delete newState[action.payload];

      return newState;
    }
    default:
      return state;
  }
};

import { all, takeEvery, put, call } from 'redux-saga/effects';

/* Routines */
import { fetchCourts } from './routines';

/* Providers */
import { fetchArenaCourtsV2 } from '../../providers/arenas/fetchArenaCourts';

/* Utils */
import { toMappedObject } from '../../utils';
import { fetchArenas } from '../arena/routines';
import { fetchArenaV2 } from '../../providers/arenas/fetchArena';

function* retrieveCourts(action) {
  try {
    yield put(fetchCourts.request());

    const { payload } = action;

    if (payload) {
      /* Fetch Courts from Arena managed by User */
      const [arena, courts] = yield all([
        call(fetchArenaV2, payload),
        call(fetchArenaCourtsV2, payload),
      ]);
      const _courts = toMappedObject(courts);

      if (_courts) {
        const _arena = toMappedObject([
          {
            ...(arena != null ? arena : { id: payload }),
            courts: _courts.order,
          },
        ]);

        yield put(fetchArenas.success(_arena));
        yield put(fetchCourts.success(_courts));
      }
    }
  } catch (err) {
    yield put(fetchCourts.failure(err.message));
  } finally {
    yield put(fetchCourts.fulfill());
  }
}

export default function* courtSaga() {
  yield all([takeEvery(fetchCourts.TRIGGER, retrieveCourts)]);
}

/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const List = props => {
  const { title, hasBorderBottom, children } = props;
  return (
    <div
      className={styles.list}
      style={{ borderBottom: hasBorderBottom ? '1px solid #434343' : null }}
    >
      <header
        className={styles.header}
        style={{ paddingBottom: title ? 20 : 0 }}
      >
        <div className={styles.title}>{title}</div>
      </header>
      <ul>{children}</ul>
    </div>
  );
};

List.defaultProps = {
  title: '',
  children: null,
  hasBorderBottom: false,
};

List.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  hasBorderBottom: PropTypes.bool,
};

export default List;

import * as types from './types';

export const authenticate = payload => ({
  type: types.AUTHENTICATE,
  payload,
});

export const authenticateOk = payload => ({
  type: types.AUTHENTICATE_OK,
  payload,
});

export const authenticateError = () => ({
  type: types.AUTHENTICATE_ERROR,
});

export const unauthenticate = payload => ({
  type: types.UNAUTHENTICATE,
  payload,
});

export const createNewAccount = payload => ({
  type: types.CREATE_NEW_ACCOUNT,
  payload,
});

import { fetchUser } from '../routines';

const initialState = null;

/**
 * @typedef { import('../../../model/User.model').User } User
 *
 * @returns {User}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case fetchUser.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

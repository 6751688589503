import * as types from '../types';

const initialState = [];

/**
 * Store events as a mapped object where each key is its ID
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_BOOKING_REQUEST:
      return action.payload;
    case types.REMOVE_BOOKING_REQUEST:
      return state.filter(x => x.id !== action.payload);
    default:
      return state;
  }
};

import { createSelector } from 'reselect';
import moment from 'moment';

import { EVENT_DURATION } from '../../constants/AppConstants';
import { SafeParse } from '../../utils';

/* SECTION Data getter */
export const getCourts = state => state.court.byID;
export const getOrder = state => state.court.order;

/**
 * @typedef Option
 * @property {string} label - Label
 * @property {string|number} value - value
 * @property {number} single - price for single use
 * @property {number} monthly - price for monthly use
 */

/**
 * @typedef {Option[]} Options
 */

/* SELECTORS */
export const courtsSelector = createSelector([getCourts], courts =>
  Object.values(courts)
);

export const orderedCourtsSelector = createSelector(
  [getOrder, getCourts],
  (order, courts) => {
    return order.map(o => courts[o]).filter(c => c != null);
  }
);

export const courtByIdSelector = createSelector(
  [getCourts, (_, id) => id],
  (courts, id) => {
    if (courts && Object.keys(courts).length) {
      if (courts[id]) {
        return courts[id];
      }
    }
    return undefined;
  }
);

/**
 * @returns {Array.<Option>} Options array
 *
 * @param {Object} state - Store state
 * @param {number|string} id - court id
 *
 * @example
 * courtModalityOptionsSelector(state, 9);
 *
 */
export const courtModalityOptionsSelector = createSelector(
  [courtByIdSelector],
  /**
   * @returns {Options}
   */
  court => {
    /** @type {Options} */
    const options = [];

    if (court) {
      if (court.modality) {
        if (Array.isArray(court.modality) && court.modality.length) {
          for (let i = 0; i < court.modality.length; i++) {
            const value = court.modality[i];

            const label = String(value).replace(/^\w/, m => m.toUpperCase());

            options.push({ label, value });
          }
        }
      }
    }

    return options;
  }
);

/**
 *
 *
 * @param {string} time
 * @param {number} [duration=60]
 * @return {{ label: string, value: string }}
 */
function createHourOption(time = null, duration = EVENT_DURATION) {
  if (time == null) return null;

  /* Force string of four digits */
  const _time = String(time).padStart(4, '0');

  /* Group time and minutes, fist comma skips full string */
  const [, h, m] = _time.match(/(\d{2})(\d{2})/);

  /* Build date with right hour */
  const dateTime = new Date().setHours(Number(h), Number(m), 0, 0);

  /* Convert date to moment */
  const momentDate = moment(dateTime);

  /* Date forma. Ex. 9h00min */
  const dateFormat = 'H[h]mm[min]';

  /* Format start */
  const start = momentDate.format(dateFormat);

  /* Format end */
  const end = momentDate.add(duration, 'minutes').format(dateFormat);

  /* Regex to match '00min'. Case insensitive */
  const regexp = /00min/i;

  /* Build label. Remove min if 00 */
  const label = `${start.replace(regexp, '')} - ${end.replace(regexp, '')}`;

  /* Build value. Change HHmm to HH:mm:00 */
  const value = _time.replace(/(\d{2})(\d{2})/, '$1:$2:00');

  return Object.freeze({ label, value });
}

/**
 * Function to return an array of options when this court is available
 */
export const courtOpenHoursOptionsSelector = createSelector(
  courtByIdSelector,
  /**
   * @param {Object} - Current store state
   * @param {number|string} id - court id
   * @param {number|string} - weekday number. Starting from Sunday as 1
   */
  (_, _2, weekday) => weekday,
  (_, _2, _3, additionalHour) => additionalHour,
  /**
   * @returns {Options}
   */
  (court, weekday, additionalHour) => {
    /* Valid hours to iterate over */
    const validHours = [];

    /* Resulting array */
    const options = [];

    if (court && court.schedules) {
      if (Array.isArray(weekday)) {
        /* Check if all weekdays passed are valid */
        const _temp = weekday.filter(w => w >= 0 && w <= 6);

        /* Test for valid length */
        if (_temp.length) {
          if (_temp.length === 1) {
            /* If only one day is valid or was passed push values to validHours */
            const _weekday = _temp[0];
            validHours.push(...court.schedules[_weekday]);
          } else {
            /* Else do intersection between values */

            /* First build matrix of selected days and sort from smallest to biggest */
            const _schedules = _temp
              .map(_weekday => court.schedules[_weekday])
              .sort((a, b) => a.length - b.length);

            /* Second check if values on the smallest array is in all others */
            const smallest = _schedules[0];

            for (let i = 0; i < smallest.length; i++) {
              const element = smallest[i];

              /* Count of element found. Starts with 1 because itself is always valid */
              let every = true;
              /* For loop jump first array to not check it with itself */
              for (let j = 1; j < _schedules.length; j++) {
                const includes = _schedules[j].includes(element);

                /* Element wasn't found on array. Go to next element */
                if (!includes) {
                  every = false;
                  break;
                }
              }

              /* Element is present in all arrays. Push it to valid hours */
              if (every) validHours.push(element);
            }
          }
        }
      } else if (weekday >= 0 && weekday <= 6) {
        /* This is single when selecting only one day */
        validHours.push(...court.schedules[weekday]);
      }
    }

    /* Check if array with hour isn't empty */
    if (validHours.length) {
      const duration = court.duration ? court.duration : EVENT_DURATION;

      /* For each valid hour generate an option. */
      for (let i = 0; i < validHours.length; i++) {
        const value = validHours[i];

        let option = createHourOption(value, duration);

        /* No caso de tipo mensal buscar maior valor para apresentar como opção */
        if (Array.isArray(weekday)) {
          const prices = weekday.map(day => ({
            single: SafeParse.number(court.values[day][i].valor_avulso, 0),
            monthly: SafeParse.number(court.values[day][i].valor_pacote, 0),
          }));
          const maxSingleValue = Math.max(
            ...prices.map(({ single }) => single)
          );
          const maxMonthlyValue = Math.max(
            ...prices.map(({ monthly }) => monthly)
          );
          option = Object.freeze({
            ...option,
            single: maxSingleValue,
            monthly: maxMonthlyValue,
          });
        } else if (court?.values?.[weekday]?.[i] != null) {
          option = Object.freeze({
            ...option,
            single: SafeParse.number(court.values[weekday][i].valor_avulso, 0),
            monthly: SafeParse.number(court.values[weekday][i].valor_pacote, 0),
          });
        }

        options.push(option);
      }
    }

    /* Check if additional hour options has been passed */
    if (additionalHour != null) {
      let _temp = null;

      /* Check for type of additional hour type and convert it to moment */
      if (typeof additionalHour === 'string') {
        _temp = moment(additionalHour);
      } else if (additionalHour instanceof Date) {
        _temp = moment(additionalHour.toString());
      }

      /* Check if moment is a valid date */
      if (_temp && _temp.isValid()) {
        /**
         * Get selected court duration if no court is selected add 60 as default duration
         *
         * court?.duration ?? 60 would have a similar effect
         */
        const duration =
          court && court.duration != null ? court.duration : EVENT_DURATION;

        /* Get moment minutes */
        const _m = _temp.minutes();

        /* Round value to smallest valid value */
        if (_m > 0 && _m < 30) {
          _temp.minutes(0);
        } else if (_m > 30 && _m < 60) {
          _temp.minutes(30);
        }

        /* Call function to create an option */
        const _option = createHourOption(_temp.format('HHmm'), duration);

        /* Check if options is already inserted  */
        const exists = options.find(option => option.value === _option.value);

        /* If not exists add on options array at the first position */
        if (exists == null) options.unshift(_option);
      }
    }

    return options;
  }
);

import * as types from './types';

const initialState = {
  selectedDate: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AGENDA_SELECT_DATE:
      return { ...state, selectedDate: action.payload };
    default:
      return state;
  }
};

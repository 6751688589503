import { v2API } from '../../service/axios';

/**
 *
 * @param {string} bookingId
 * @returns {Promise<import('axios').AxiosResponse<any>['data']>}
 */
export default async function markAsPaid(bookingId) {
  const data = new FormData();
  data.append('booking_id', bookingId);

  const res = await v2API.post('pay_asaas/change_status', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return res.data;
}

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isProfessorSelector } from '../store/user/selectors';

/**
 * @template T
 * @param {T} arena
 * @param {T} professor
 * @returns {T}
 */
export default function useArenaOrProfessor(arena = null, professor = null) {
  const isProfessor = useSelector(isProfessorSelector);

  const content = useMemo(() => (isProfessor ? professor : arena), [
    arena,
    professor,
    isProfessor,
  ]);

  return content;
}

import { fetchArenas } from '../routines';

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fetchArenas.REQUEST:
      return { loading: true, error: null };
    case fetchArenas.FAILURE:
      return { ...state, error: action.payload };
    case fetchArenas.FULFILL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

import * as types from '../types';

const initialState = () => ({
  order: null,
  page: 1,
  pageSize: 10,
  search: null,
  total: null,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case types.CHANGE_TOTAL_PERSON:
      return { ...state, total: action.payload };
    case types.CHANGE_PAGE_PERSON:
      return { ...state, page: action.payload };
    case types.CHANGE_PAGE_SIZE_PERSON:
      return { ...state, pageSize: action.payload };
    case types.CHANGE_SEARCH_PARAM_PERSON:
      return { ...state, search: action.payload };
    case types.RESET_PERSON_PAGINATION_DATA:
      return { ...initialState };
    case types.RESET_PERSON_STORE:
      return initialState();
    default:
      return state;
  }
};

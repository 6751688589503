import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import CloseIcon from '../../assets/icons/CloseIcon';
import WarningIcon from '../../assets/icons/WarningIcon';
import useOutsideClick from '../../hooks/useOutsideClick';

import Colors from '../../sass/_colors.scss';
import Button from '../Button';

import classes from './index.module.scss';

/**
 * @param {object} props
 * @param {boolean} [props.isFirstRenderOpened=false]
 * @param {string} [props.title='']
 * @param {string} [props.subTitle='']
 * @param {() => void} [props.onConfirm='']
 * @param {() => void} [props.onShow='']
 * @param {() => void} [props.onHide='']
 * @returns {JSX.Element | undefined}
 */
function ConfirmationModal(props, ref) {
  const {
    isFirstRenderOpened = false,
    title,
    subTitle,
    onConfirm,
    onShow,
    onHide,
  } = props;
  const modalRef = useRef();
  const [visible, setVisible] = useState(isFirstRenderOpened);
  const [loading, setLoading] = useState(false);

  const show = () => {
    setVisible(true);
    // onShow?.();
    if (onShow != null) onShow();
  };

  const hide = () => {
    if (loading) return;

    setVisible(false);
    // onHide?.();
    if (onHide != null) onHide();
  };

  useOutsideClick(modalRef, hide);

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const handleConfirm = () => {
    new Promise(res => {
      if (onConfirm != null) {
        setLoading(true);
        return res(onConfirm());
      }
      return res();
    })
      .then(() => {
        setLoading(false);
        hide();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (visible) {
      body.classList.add('open-modal');
    }

    return () => {
      body.classList.remove('open-modal');
    };
  }, [visible]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          key='backdrop'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classes.backdrop}
        >
          <div key='modal' ref={modalRef} className={classes.modal}>
            <div className={classes.header}>
              <div className={classes.warningIcon}>
                <WarningIcon size='27px' color={Colors.negative} />
              </div>

              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className={classes.closeIcon}
                onClick={hide}
              >
                <CloseIcon size='17px' color={Colors.primary} />
              </motion.div>
            </div>

            <div className={classes.content}>
              <div className={classes.title}>{title}</div>

              {subTitle != null ? (
                <div className={classes.subTitle}>{subTitle}</div>
              ) : null}
            </div>

            <div className={classes.buttons}>
              <Button
                outline
                title='Não'
                background={Colors.negative}
                onClick={hide}
              />
              <Button
                title='Sim'
                onClick={handleConfirm}
                background={Colors.positive}
                color={Colors.text}
                loading={loading}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

ConfirmationModal.propTypes = {
  isFirstRenderOpened: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onConfirm: PropTypes.func,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  isFirstRenderOpened: false,
  title: undefined,
  subTitle: undefined,
  onConfirm: undefined,
  onShow: undefined,
  onHide: undefined,
};

// eslint-disable-next-line no-func-assign
ConfirmationModal = forwardRef(ConfirmationModal);

export default ConfirmationModal;

/* CRUD */
export const ADD_PAYMENT = 'PAYMENT/ADD';
export const REMOVE_PAYMENT = 'PAYMENT/REMOVE';

/* UI */
export const CLEAR_ORDER_PAYMENT = 'PAYMENT/CLEAR_ORDER';

/* PAGINATION */
export const CHANGE_MAX_PAGES_PAYMENT = 'PAYMENT/CHANGE_MAX_PAGES';
export const CHANGE_PAGE_PAYMENT = 'PAYMENT/CHANGE_PAGE';
export const CHANGE_PAGE_SIZE_PAYMENT = 'PAYMENT/CHANGE_PAGE_SIZE';
export const CHANGE_SEARCH_PARAM_PAYMENT = 'PAYMENT/CHANGE_SEARCH_PARAM';
export const CHANGE_TOTAL_PAYMENT = 'PAYMENT/CHANGE_TOTAL';
export const CHANGE_PAY_STATUS_PARAM_PAYMENT = 'PAYMENT/PAY_STATUS_PARAM';
export const ADD_PAGINATED_PAYMENT = 'PAYMENT/ADD_PAGINATED';
export const RESET_PAYMENT_PAGINATION_DATA = 'PAYMENT/RESET_PAGINATION_DATA';
export const RESET_PAYMENT_STORE = 'PAYMENT/RESET_STORE';

import React from 'react';
import PropTypes from 'prop-types';

import classes from './index.module.scss';
import Colors from '../../sass/_colors.scss';

const CircularLoader = props => {
  const { color, fontSize } = props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: `${fontSize}em`,
      }}
    >
      <div className={classes.loader} style={{ color, fontSize }} />
    </div>
  );
};

CircularLoader.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
};

CircularLoader.defaultProps = {
  color: Colors.primary,
  fontSize: 2,
};

export default CircularLoader;

function getValue(obj, key) {
  if (typeof obj[key] === 'function') return obj[key]();
  return obj[key];
}

/**
 * This function is an JS counterpart of the php match function
 * https://www.php.net/manual/en/control-structures.match.php
 *
 * @template T
 * @param { string | number | symbol } value
 * @param { Record<string | number | symbol, () => T | T> } obj
 * @returns { T | undefined }
 */
export default function match(value, obj) {
  if (obj[value] != null) return getValue(obj, value);

  if (obj.default != null) return getValue(obj, 'default');

  return undefined;
}

import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Row = props => {
  const { row } = props;

  if (row) {
    return (
      <tr className={styles.row}>
        <td>{row.name || 'Não informado'}</td>
        <td>{row.phone || 'Não informado'}</td>
        <td>{row.email || 'Não informado'}</td>
        <td>{row.actions}</td>
      </tr>
    );
  }

  return null;
};

Row.defaultProps = {
  row: {},
};

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    cpf: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    actions: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.number),
    ]),
  }),
};

export default Row;

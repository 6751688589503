import VMasker from 'vanilla-masker';

export const date = val => {
  if (val == null) {
    return undefined;
  }

  let temp;

  if (typeof val === 'object' && val instanceof Date) {
    temp = val;
  } else if (typeof val === 'string') {
    temp = new Date(val.replace(/\s/, 'T'));
  } else if (typeof val === 'number') {
    temp = new Date(val);
  }

  return Number.isNaN(temp.getTime()) ? undefined : temp;
};

/**
 *
 * @param {any} val - Value that will be check if it is an number
 * @param {number} [onNaN=undefined] - Value to return if val is not a value number
 * @returns {number | undefined}
 */
export const number = (val, onNaN = undefined) => {
  if (val == null) {
    return onNaN;
  }

  if (typeof val === 'number') {
    return val;
  }

  const temp = Number(val);

  return Number.isNaN(temp) ? onNaN : temp;
};

export const string = val => {
  const temp = String(val).trim();

  return val == null || temp === '' ? undefined : temp;
};

export const stringOnlyDigits = val => String(val).replace(/\D/g, '');

export const money = (val, isValueWithoutCents = false) => {
  const str = stringOnlyDigits(val);

  return VMasker.toMoney(isValueWithoutCents ? str * 100 : str, {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: 'R$',
  });
};

export const cpf = val =>
  VMasker.toPattern(stringOnlyDigits(val), '999.999.999-99');

export const phone = val => {
  const str = stringOnlyDigits(val);

  return VMasker.toPattern(
    str,
    str.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999'
  );
};

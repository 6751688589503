/* CONFLICT */
export const OPEN_DRAWER_CONFLICT = 'OPEN_DRAWER_CONFLICT';
export const CLOSE_DRAWER_CONFLICT = 'CLOSE_DRAWER_CONFLICT';
export const FETCH_CONFLICT_INFO = 'FETCH_CONFLICT_INFO';
export const FETCH_CONFLICT_FAIL = 'FETCH_CONFLICT_FAIL';
export const FETCH_CONFLICT_DONE = 'FETCH_CONFLICT_DONE';

/* BOOKING DETAIL */
export const OPEN_DRAWER_BOOKING_DETAIL = 'OPEN_DRAWER_BOOKING_DETAIL';
export const CLOSE_DRAWER_BOOKING_DETAIL = 'CLOSE_DRAWER_BOOKING_DETAIL';

/* CLASS DETAIL */
export const OPEN_DRAWER_CLASS_DETAIL = 'OPEN_DRAWER_CLASS_DETAIL';
export const CLOSE_DRAWER_CLASS_DETAIL = 'CLOSE_DRAWER_CLASS_DETAIL';
export const SELECT_CLASS_DETAIL_ID = 'SELECT_CLASS_DETAIL_ID';

/* NEW BOOKING */
export const OPEN_DRAWER_NEW_BOOKING = 'OPEN_DRAWER_NEW_BOOKING';
export const CLOSE_DRAWER_NEW_BOOKING = 'CLOSE_DRAWER_NEW_BOOKING';

/* USER INFO */
export const OPEN_DRAWER_USER_INFO = 'OPEN_DRAWER_USER_INFO';
export const CLOSE_DRAWER_USER_INFO = 'CLOSE_DRAWER_USER_INFO';

/* PAY INFO */
export const OPEN_DRAWER_PAY_INFO = 'OPEN_DRAWER_PAY_INFO';
export const CLOSE_DRAWER_PAY_INFO = 'CLOSE_DRAWER_PAY_INFO';

/**
 * Function to return an intl date (yyyy-mm-dd)
 *
 * @param {string|number|Date} date
 *
 * @returns {string|null}
 */
const toIntlDate = date => {
  let temp;

  if (typeof date === 'string') {
    temp = new Date(date);
  } else if (typeof date === 'number') {
    temp = new Date(date);
  } else if (typeof date === 'object' && date instanceof Date) {
    temp = date;
  }

  if (temp && !Number.isNaN(temp.getTime())) {
    const yyyy = temp.getFullYear();
    const mm = String(temp.getMonth() + 1).padStart(2, '0');
    const dd = String(temp.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  }

  return null;
};

export default toIntlDate;

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import Badge from '../../../components/Badge';
import BookingType from '../../../enum/bookingType';
import PaymentStatus from '../../../enum/paymentStatus';

import styles from './index.module.scss';

const empty = 'Não informado';

const Row = memo(
  /**
   * @param {object} props
   * @param {string} props.name
   * @param {0 | 1} props.bookingType
   * @param {string} props.bookingDatetime
   * @param {0 | 1 | 2}  props.paymentStatus
   * @param {JSX.Element | JSX.Element[]} props.actions
   * @returns {JSX.Element | null}
   */
  props => {
    const {
      actions,
      bookingDatetime,
      bookingType,
      name,
      paymentStatus,
    } = props;

    const date = new Date(String(bookingDatetime).replace(/\s/, 'T'));
    let day;
    let time;
    if (!Number.isNaN(date.getTime())) {
      day = format(date, 'dd/MM/yyyy');
      time = format(date, 'HH:mm');
    }

    return (
      <tr className={styles.row}>
        <td>{name ?? empty}</td>
        <td>
          <div className={styles.centerBadge}>
            <Badge {...BookingType.getBadge(bookingType)} />
          </div>
        </td>
        <td>{day ?? empty}</td>
        <td>{time ?? empty}</td>
        <td>
          <div className={styles.centerBadge}>
            <Badge {...PaymentStatus.getBadge(paymentStatus)} />
          </div>
        </td>
        <td>{actions}</td>
      </tr>
    );
  }
);

Row.defaultProps = {
  actions: null,
  bookingDatetime: undefined,
  bookingType: undefined,
  name: undefined,
  paymentStatus: undefined,
};

Row.propTypes = {
  bookingDatetime: PropTypes.string,
  bookingType: PropTypes.string,
  name: PropTypes.string,
  paymentStatus: PropTypes.string,
  actions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

export default Row;

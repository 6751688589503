import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => !!state.auth.token);

  if (!isAuthenticated) dispatch(replace('/'));

  return <Route {...rest} render={props => <Component {...props} />} />;
};

PrivateRoute.propTypes = Route.propTypes;

export default PrivateRoute;

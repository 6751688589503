/**
 * Function to generate a time or time invertal based on the initial hour
 *
 *
 * @param {number|string|Date} hour - Hour represetation or Date
 * @param {number} [duration=0] - Duration in minutes to generate the interval.
 *
 * @returns {string} Returns an string represeting a time or a time inverval
 *
 * @example <caption>Passing string</caption>
 * generateDurationTime('8:00', 30) // Returns '8h - 8h30min'
 *
 * @example <caption>Passing number</caption>
 * generateDurationTime(900, 60) // Returns '9h - 10h'
 *
 * @example <caption>Passing Date</caption>
 * generateDurationTime(new Date('2020-03-11 12:00')) // Returns '12h'
 */
const generateDurationTime = (hour, duration = 0) => {
  let start; // Starting hour. Unix
  let end; // Ending hour. Unix

  if (typeof hour === 'number' || typeof hour === 'string') {
    const time = String(hour);

    /* Group time and minutes, fist comma skips full string */
    const [, h, m] = time.match(/(\d{2}):?(\d{2}):?\d{2}$/);

    if (h && m) {
      const _h = Number(h);
      const _m = Number(m);
      start = new Date().setHours(_h, _m, 0, 0);
    }
  }

  /* If param is already instace of Date */
  if (hour instanceof Date) {
    start = hour.getTime();
  }

  /* Check if hour is defined. It will be a unix time */
  if (start) {
    const regex = /00min/i;
    /* Convert duration from minutest to millisecondes */
    const startTime = new Date(start);
    const startHour = `${startTime.getHours()}h`;
    const startMinute = `${String(startTime.getMinutes()).padStart(2, '0')}min`;

    let startLabel = startHour;
    if (!startMinute.match(regex)) {
      startLabel += startMinute;
    }

    let endLabel;
    if (duration && duration > 0) {
      const inMs = duration * 60 /* Seconds */ * 1000; /* Milliseconds */
      end = start + inMs;

      const endTime = new Date(end);
      const endHour = `${endTime.getHours()}h`;
      const endMinute = `${String(endTime.getMinutes()).padStart(2, '0')}min`;

      endLabel = endHour;
      if (!endMinute.match(regex)) {
        endLabel += endMinute;
      }
      return `${startLabel} - ${endLabel}`;
    }

    return startLabel;
  }

  return '';
};

export default generateDurationTime;

import { baseAPI } from '../../service/axios';

const fetchPeople = async (id, params) => {
  const res = await baseAPI.get(`arena/related_users`, {
    params: { arena_id: id, ...params },
  });

  return res.data;
};

export default fetchPeople;

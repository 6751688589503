import merge from 'lodash/merge';

import * as types from '../types';

const initialState = () => ({});

/**
 * Store events as a mapped object where each key is its ID
 */
export default (state = initialState(), action) => {
  switch (action.type) {
    case types.ADD_PAYMENT:
      return merge({}, state, action.payload.byID);
    case types.REMOVE_PAYMENT: {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    }
    case types.RESET_PAYMENT_STORE:
      return initialState();
    default:
      return state;
  }
};

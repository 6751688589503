import { createSelector } from 'reselect';

/**
 * @typedef Option
 * @property {string} label - Label
 * @property {string|number} value - value
 */

/**
 * @typedef {Option[]} Options
 */

/* Import getters */
import { getActiveArena } from '../config/selectors';
import { getCourts } from '../court/selector';

/* SECTION Local getters */
export const getArenas = state => state.arena.byID;

/* SECTION Expensive computations */
const getCourtsFromArena = (arenas, courts, arenaId) => {
  const _courts = [];

  if (arenaId) {
    if (arenas[arenaId]) {
      const arena = arenas[arenaId];

      if (arena.courts) {
        if (Array.isArray(arena.courts) && arena.courts.length) {
          for (let i = 0; i < arena.courts.length; i++) {
            const court = courts[arena.courts[i]];

            if (court) {
              _courts.push(court);
            }
          }
        }
      }
    }
  }
  return _courts;
};

/** @returns {Options} */
const generateArenaCourtOptions = courts => {
  /** @type {Options} */
  const options = [];

  if (courts && courts.length) {
    for (let i = 0; i < courts.length; i++) {
      const { id, title } = courts[i];

      /** @type {Option} */
      const option = { value: id, label: title };

      options.push(option);
    }
  }

  return options;
};

/* SECTION Selectors */

/**
 * Selector to return selected arena
 *
 * @param {Object} state - Receive current redux state
 *
 * @returns {*} Returns current selected arena which id is saved on config reducer
 */
export const activeArenaSelector = createSelector(
  [getArenas, getActiveArena],
  (arenas, active) => arenas[active]
);

/**
 * Selector to return an array of courts from selected arena
 *
 * @param {Object} state - Redux store state
 * @param {number|string} arenaID - Choosing which arena will return their courts
 *
 * @returns {Array.<*>}
 */
export const arenaCourtsSelector = createSelector(
  [getArenas, getCourts, (_, id) => id],
  getCourtsFromArena
);

/**
 * Selector to return an array of courts from active arena
 *
 * @param {Object} state - Redux store state
 *
 * @returns {Array.<*>}
 */
export const activeArenaCourtsSelector = createSelector(
  [getArenas, getCourts, getActiveArena],
  getCourtsFromArena
);

/**
 * Selector to find court with specific modality
 *
 * @param {Object} state - Current redux state
 * @param {string} modality - Modality to filter
 *
 * @returns {Array<*>} Returns an array of courts
 */
export const activeArenaCourtsByModalitySelector = createSelector(
  [activeArenaCourtsSelector, (_, modality) => modality],
  (courts, modality) => {
    const res = [];

    if (courts && courts.length) {
      const regex = new RegExp(modality, 'i');

      for (let i = 0; i < courts.length; i++) {
        const court = courts[i];

        if (court.modality && court.modality.length) {
          if (court.modality.findIndex(m => m.match(regex)) > -1) {
            res.push(court);
          }
        }
      }
    }

    return res;
  }
);

/**
 * Function to return an array of courts options from selected arena
 *
 * @param {Object} state - Redux store state
 * @param {number|string} arenaID - Choosing which arena will return their courts
 *
 * @returns {Options}
 */
export const arenaCourtOptionsSelector = createSelector(
  [arenaCourtsSelector],
  generateArenaCourtOptions
);

/**
 * Function to return an array of courts options from active arena with specific modality
 *
 * @param {Object} state - Redux store state
 * @param {string} modality - Filter only courts with this modality. Case insentive
 *
 * @returns {Options}
 *
 * @example
 * useSelector(state => arenaCourtOptionsByModalitySelector(state, 'Futsal'));
 */
export const arenaCourtOptionsByModalitySelector = createSelector(
  [activeArenaCourtsByModalitySelector],
  generateArenaCourtOptions
);

export const arenaByIdSelector = createSelector(
  [getArenas, (_, id) => id],
  (arenas, id) => (arenas?.[id] != null ? arenas[id] : undefined)
);

import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './index.module.scss';
const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const StateContainer = props => {
  const { loading, error, children, onErrorClick } = props;

  const Render = () => {
    if (error) {
      return (
        <motion.div
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
          key='error'
        >
          <span>{error}</span>
          <button onClick={onErrorClick} type='button'>
            Click me
          </button>
        </motion.div>
      );
    }

    if (loading) {
      return (
        <motion.div
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
          key='loading'
        >
          {/* loading */}
        </motion.div>
      );
    }

    return (
      <motion.div
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        className={styles['motion-div']}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        key='children'
      >
        {children}
      </motion.div>
    );
  };

  return (
    <AnimatePresence>
      <Render />
    </AnimatePresence>
  );
};

StateContainer.defaultProps = {
  loading: false,
  error: '',
  children: null,
  onErrorClick: () => {},
};

StateContainer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onErrorClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default StateContainer;

import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../sass/_colors.scss';

const styles = {
  wrapper: { padding: 8, color: '#fff' },
  title: {},
  subtitle: { fontSize: 14, color: Colors.labelGrey },
};

const UserItem = ({ name, email, phone, cellphone }) => (
  <div style={styles.wrapper}>
    <div style={styles.title}>{name}</div>
    <div style={styles.subtitle}>{email}</div>
    <div style={styles.subtitle}>{phone}</div>
    <div style={styles.subtitle}>{cellphone}</div>
  </div>
);

UserItem.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  cellphone: PropTypes.string,
};

UserItem.defaultProps = {
  name: '',
  email: '',
  phone: '',
  cellphone: '',
};

export default UserItem;

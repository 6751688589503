import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './index.module.scss';
import './override.scss';

import CloseIcon from '../../assets/icons/CloseIcon';

const CloseButton = ({ closeToast }) => {
  CloseButton.propTypes = {
    closeToast: PropTypes.func.isRequired,
  };

  return (
    <div
      className={styles.button}
      onClick={closeToast}
      onKeyPress={closeToast}
      role='button'
      tabIndex={0}
    >
      <CloseIcon />
    </div>
  );
};

const CustomToastContainer = () => {
  return (
    <ToastContainer
      autoClose={3000}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      closeOnClick={false}
      toastClassName={styles.toast}
      bodyClassName={styles.body}
      progressClassName={styles.progress}
      closeButton={<CloseButton />}
    />
  );
};

export default CustomToastContainer;

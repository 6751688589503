import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

/* Styles */
import styles from './index.module.scss';
import Breakpoints from '../../../sass/_breakpoints.scss';
// import Colors from '../../../sass/_colors.scss';

/* Actions */
import {
  openConflictDrawer,
  openBookingDetailDrawer,
} from '../../../store/sideDrawer/actions';
// import { approveBookingRequest } from '../../../store/httpRequest/routines';

/* Components */
// import IconButton from '../../../components/IconButton';
import OptionsButton from '../../../components/OptionsButton';

/* Icons */
import ConflictIcon from '../../../components/ConflictIcon';
// import IconApprove from '../../../assets/svg/icon-approve.svg';

/* Hooks */
import useResize from '../../../hooks/useResize';

const Row = props => {
  const { row } = props;
  const {
    applicated,
    conflict,
    date,
    hours,
    id,
    modality,
    name,
    type,
    cellphone,
  } = row;

  const windowWidth = useResize();
  const dispatch = useDispatch();

  const _openBookingDetailDrawer = useCallback(() => {
    dispatch(openBookingDetailDrawer(id));
  }, [dispatch, id]);

  if (!row) return null;

  /* Applicated column */
  const applicatedAt = [];
  if (applicated) {
    applicatedAt.push(<div key={applicatedAt.length}>{applicated}</div>);
  }
  if (conflict) {
    const _openConflictDrawer = () => dispatch(openConflictDrawer());

    applicatedAt.push(
      <div key={applicatedAt.length} style={{ marginLeft: 19 }}>
        <ConflictIcon tooltip onClick={_openConflictDrawer} />
      </div>
    );
  }
  const applicatedColumn = (
    <div style={{ display: 'flex', alignItems: 'center' }}>{applicatedAt}</div>
  );

  /* Actions column */
  const actionsColumn = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <OptionsButton onClick={_openBookingDetailDrawer} />
    </div>
  );

  /* Mobile size */
  let tr = (
    <>
      <th>{name}</th>
      <td>{type}</td>
      <td>{actionsColumn}</td>
    </>
  );
  /* Tablet size */
  if (windowWidth >= Number(Breakpoints.md.replace('px', ''))) {
    tr = (
      <>
        <th>{name}</th>
        <td>{applicatedColumn}</td>
        <td>{modality}</td>
        <td>{type}</td>
        <td>{actionsColumn}</td>
      </>
    );
  }
  /* Desktop size */
  if (windowWidth >= Number(Breakpoints.lg.replace('px', ''))) {
    tr = (
      <>
        <th>{name}</th>
        <td>{cellphone}</td>
        <td>{applicatedColumn}</td>
        <td>{modality}</td>
        <td>{type}</td>
        <td className={styles.highlight}>{date}</td>
        <td className={styles.highlight}>{hours}</td>
        <td>{actionsColumn}</td>
      </>
    );
  }

  return <tr className={styles.row}>{tr}</tr>;
};

Row.defaultProps = {
  row: {},
};

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    modality: PropTypes.string,
    conflict: PropTypes.bool,
    name: PropTypes.string,
    applicated: PropTypes.string,
    court: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.string,
    hours: PropTypes.string,
    actions: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.number),
    ]),
    cellphone: PropTypes.string,
  }),
};

export default Row;

import classNames from 'classnames';
import { detect } from 'detect-browser';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import Breakpoints from '../../sass/_breakpoints.scss';
import Colors from '../../sass/_colors.scss';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import AgendaContainer from '../../components/AgendaContainer';
import Calendar from '../../components/Calendar';
import ClassDetailDrawer from '../../components/ClassDetailDrawer';
import CreateBookingButton from '../../components/CreateBookingButton/CreateBookingButton';
import EmptyStateMessage from '../../components/EmptyStateMessage';
import IconButton from '../../components/IconButton';
import SearchInput from '../../components/SearchInput';
import StateContainer from '../../components/StateContainer';
import SideDrawer from '../../containers/SideDrawer';
import useResize from '../../hooks/useResize';
import { activeHoursSelector } from '../../store/agenda/selectors';
import { fetchCourts } from '../../store/court/routines';
import { courtsSelector } from '../../store/court/selector';
import { fetchEvents } from '../../store/event/routines';
import { agendaEventSelector } from '../../store/event/selectors';
import {
  closeClassDetailDrawer,
  openNewBookingDrawer,
} from '../../store/sideDrawer/actions';

import Filters from './Filters';
import styles from './index.module.scss';
import SearchBox from './SearchBox';
import PaymentStatus from '../../enum/paymentStatus';

/**
 * Receive an array of AgendaEventModel and filter based on its recurrence
 *
 * @param {import('../../model/AgendaEvent.model').AgendaEventType[]} events - AgendaEvents
 * @param {Object} filters - Recurrence selector
 * @param {boolean} filters.single - Filtro de recorrencia Avulsa
 * @param {boolean} filters.monthly - Filtro de recorrencia Mensal
 * @param {boolean} filters.class - Filtro de Aula
 *
 * @returns {AgendaEventModel[]}
 */
const filterEventRecurrence = (events, filters) => {
  if (!events) return [];

  const keys = Object.keys(filters).reduce((acc, cur) => {
    if (filters[cur]) acc.push(cur);

    return acc;
  }, []);

  if (!keys.length) {
    /* Nenhum filtro selecionado retorna apenas os livres */
    return events.filter(e => e.resource.recurrence.match(/free/i));
  }

  /* Gera regex baseado nos toggles selecionados */
  const regexp = new RegExp(`${keys.join('|')}`, 'gi');

  /* Filtro de classe possui um comportamento especial */

  return events.filter(e => {
    /* Sempre retorna os horários livres */
    if (e.resource.recurrence.match(/free/i)) {
      return true;
    }

    /**
     * Força retorno de reserva se força aula quando filters.class estiver ativo
     */
    if (filters.class === true) {
      if (e.resource.isClass) {
        return true;
      }
    }

    /**
     * Retorna as reservas baseado na recorrencia ignorando as que são aulas.
     */
    if (e.resource.recurrence.match(regexp) && !e.resource.isClass) {
      return true;
    }

    /**
     * Retorno para garantir regra que todas as condições retornam um valor
     */
    return false;
  });
};

/* Local Selectors */
const dateTitleSelector = state => {
  const { selectedDate } = state.agenda;

  if (!selectedDate || !(selectedDate instanceof Date)) return '';

  const _date = moment(selectedDate.getTime());

  let formatString = 'DD [de] MMMM [de] YYYY';
  if (_date.isSame(moment(), 'day')) formatString = `[Hoje,] ${formatString}`;

  return _date.format(formatString);
};

const isPageBlockedSelector = state => {
  try {
    const { manages } = state.user.data;
    if (Array.isArray(manages) && manages.length) {
      return false;
    }

    return true;
  } catch (ex) {
    return true;
  }
};

/* Page Component */
const AgendaPage = () => {
  /* FIXME - Forcing query selector. Need to find a way to use with ref */
  useEffect(() => {
    const el = document.querySelector('.rbc-time-content');

    if (el) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const mouseDown = el.addEventListener('mousedown', e => {
        isDown = true;
        el.classList.add('active');
        startX = e.pageX - el.offsetLeft;
        scrollLeft = el.scrollLeft;
      });

      const mouseLeave = el.addEventListener('mouseleave', () => {
        isDown = false;
        el.classList.remove('active');
      });

      const mouseUp = el.addEventListener('mouseup', () => {
        isDown = false;
        el.classList.remove('active');
      });

      const mouseMove = el.addEventListener('mousemove', e => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - el.offsetLeft;
        const walk = (x - startX) * 3;
        el.scrollLeft = scrollLeft - walk;
      });

      return () => {
        if (el) {
          el.removeEventListener('mousedown', mouseDown);
          el.removeEventListener('mouseleave', mouseLeave);
          el.removeEventListener('mouseup', mouseUp);
          el.removeEventListener('mousemove', mouseMove);
        }
      };
    }

    return () => {};
  }, [document.querySelector('.rbc-time-content')]);

  const dispatch = useDispatch();
  const windowSize = useResize();

  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector('.rbc-time-header-gutter');
      if (el) {
        const { width } = el.style;
        const onYourLeft = document.querySelector('#on-your-left');
        if (onYourLeft) {
          onYourLeft.style.marginLeft = width;
        }
      }
    }, 0);
  }, [document.querySelector('.rbc-time-header-gutter')]);

  /* Page State */
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    single: true,
    monthly: true,
    class: true,
  });
  const [date, setDate] = useState(new Date());
  const [isLeftDrawerOpen, setLeftDrawerOpen] = useState(false);

  /* Get events of specific date */
  const events = useSelector(state => agendaEventSelector(date, state));

  /* Get courts */
  const courts = useSelector(courtsSelector);

  /* Active arena */
  const activeArena = useSelector(state => state.config.activeArena);

  const _activeHoursSelector = useSelector(state =>
    activeHoursSelector(state, activeArena)
  );

  /* State of fetching courts */
  const fetchingCourts = useSelector(state => state.court.ui.loading);
  const errorCourts = useSelector(state => state.court.ui.error);

  /* Component Selectors */
  const dateTitle = useSelector(dateTitleSelector);
  const isBlocked = useSelector(isPageBlockedSelector);
  const isLoading = useSelector(state => state.event.ui.loading);
  const isClassDetailDrawerOpen = useSelector(
    state => state.sideDrawer.classDetail.open
  );

  useEffect(() => {
    /* Fetch events on date change */
    dispatch(
      fetchEvents({
        date,
        payStatus: [PaymentStatus.PAID, PaymentStatus.PENDING],
      })
    );
  }, [dispatch, date]);

  const filterEventsMemo = useCallback(() => {
    return filterEventRecurrence(events, filters);
  }, [events, filters]);

  /* Action to open New Booking drawer */
  const _openNewBookingDrawer = useCallback(() => {
    dispatch(openNewBookingDrawer());
  }, [dispatch]);

  /* Fetch courts */
  const retrieveCourts = useCallback(() => {
    if (activeArena) {
      dispatch(fetchCourts(activeArena));
    }
  }, [dispatch, activeArena]);

  /* Filter events based on recurrence */
  const filteredEvents = filterEventsMemo();

  /* Component actions */
  const _closeClassDetailDrawer = () => dispatch(closeClassDetailDrawer());
  const _toggleLeftDrawer = () => {
    setLeftDrawerOpen(!isLeftDrawerOpen);
  };
  const _onDateChangeMobile = value => {
    setDate(value);
    _toggleLeftDrawer();
  };

  if (isBlocked) {
    return <EmptyStateMessage message='Nenhuma arena disponível.' />;
  }

  /**
   * Title with selected date
   *
   * Render only a span if screen is bigger than lg breakpoint
   * otherwiser render a button and a sidedrawer with all filters
   */

  let fabAddButton = null;
  let title = dateTitle;
  if (windowSize < Number(Breakpoints.lg.replace('px', ''))) {
    /* Create title and SideDrawer */
    title = (
      <>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ marginRight: 8 }}>
            <IconButton
              color={Colors.primary}
              icon={<CalendarIcon />}
              onClick={_toggleLeftDrawer}
            />
          </div>
          <span>{dateTitle}</span>
        </div>
        <SideDrawer
          isOpen={isLeftDrawerOpen}
          position='left'
          onPressClose={_toggleLeftDrawer}
        >
          <div style={{ padding: 20 }}>
            <div
              className={styles['search-wrapper']}
              style={{ marginBottom: 10 }}
            >
              <SearchInput
                placeholder='Buscar'
                id='search'
                value={search}
                onChange={setSearch}
              />
              <SearchBox
                events={events}
                className={styles.result}
                search={search}
              />
            </div>
            <div style={{ justifySelf: 'center', width: 224 }}>
              <Calendar value={date} onChange={_onDateChangeMobile} />
            </div>
            <Filters filters={filters} onChange={setFilters} />
          </div>
        </SideDrawer>
      </>
    );

    fabAddButton = (
      <Fab
        icon={
          <div style={{ transform: 'rotate(45deg)' }}>
            <CloseIcon />
          </div>
        }
        onClick={_openNewBookingDrawer}
      />
    );
  }

  /**
   * Return page content
   */
  return (
    <>
      <div className={styles.page}>
        <div className={styles.createBooking}>
          <CreateBookingButton initialDate={date} />
        </div>

        <div>
          <header className={styles['schedule-header']}>
            <span id='on-your-left'>{title}</span>

            <div
              className={classNames([
                styles['search-wrapper'],
                styles.hideOnSmall,
              ])}
            >
              <SearchInput
                placeholder='Buscar'
                id='search'
                value={search}
                onChange={setSearch}
              />
              <SearchBox
                events={events}
                className={styles.result}
                search={search}
              />
            </div>
          </header>
        </div>

        <div>
          <Calendar value={date} onChange={setDate} />
          <Filters filters={filters} onChange={setFilters} />
        </div>

        <div
          style={
            detect().name === 'safari' || detect().os === 'Mac OS'
              ? { overflowX: 'hidden', height: '100%' }
              : { overflow: 'hidden' }
          }
        >
          <StateContainer
            className={styles['state-container']}
            loading={!activeArena || fetchingCourts}
            error={activeArena && errorCourts}
            onErrorClick={retrieveCourts}
          >
            <AgendaContainer
              date={date}
              className={styles['agenda-container']}
              min={_activeHoursSelector.opens}
              max={_activeHoursSelector.closes}
              events={isLoading ? [] : filteredEvents}
              resources={courts}
            />
          </StateContainer>
        </div>
      </div>

      <ClassDetailDrawer
        isOpen={isClassDetailDrawerOpen}
        onPressClose={_closeClassDetailDrawer}
      />

      {fabAddButton}
    </>
  );
};

export default AgendaPage;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import FormLogin from '../../containers/FormLogin';
import RecoverPasswordForm from '../../containers/RecoverPasswordForm';
import ConfirmNewPasswordForm from '../../containers/ConfirmNewPasswordForm';
/* import FormSignUp from '../../containers/FormSignUp'; */

import styles from './index.module.scss';

import IconBack from '../../assets/svg/icon-back.svg';

const BackButton = ({ onClick }) => (
  <button className={styles['back-button']} onClick={onClick} type='button'>
    <img src={IconBack} alt='IconBack' />
    <span className={styles['back-button-text']}>login</span>
  </button>
);

const Login = ({ onPwdRecoverClick }) => (
  <motion.div
    key='login'
    initial={{ opacity: 0, x: -300 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 300 }}
    transition={{ duration: 0.5 }}
    className={styles['login-container']}
  >
    <span className={styles.title}>Bem-Vindo</span>
    <FormLogin onPwdRecoverClick={onPwdRecoverClick} />
  </motion.div>
);

const RecoverPassword = ({ onBackClick }) => (
  <motion.div
    key='recoverPassword'
    initial={{ opacity: 0, x: 300 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -300 }}
    transition={{ duration: 0.5 }}
    className={styles['login-container']}
  >
    <div className={styles['recover-container']}>
      <div>
        <BackButton onClick={onBackClick} />
      </div>
      <span className={styles.title}>Esqueceu sua senha?</span>
      <span className={styles.subtitle}>
        Informe seu email para enviarmos a recuperação de senha.
      </span>
      <RecoverPasswordForm onSuccess={onBackClick} />
    </div>
  </motion.div>
);

const ConfirmPassword = ({ onBackClick, onSuccess, urlParams }) => (
  <motion.div
    key='login'
    initial={{ opacity: 0, x: -300 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 300 }}
    transition={{ duration: 0.5 }}
    className={styles['login-container']}
  >
    <div>
      <BackButton onClick={onBackClick} />
    </div>
    <span className={styles.title}>Criar nova senha.</span>
    <ConfirmNewPasswordForm urlParams={urlParams} onSuccess={onSuccess} />
  </motion.div>
);

/* const SignUp = ({ onBackClick }) => (
  <motion.div
    key='signUp'
    initial={{ opacity: 0, x: 300 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -300 }}
    transition={{ duration: 0.5 }}
    className={styles['login-container']}
  >
    <BackButton onClick={onBackClick} />
    <span className={styles.title}>Crie a sua conta</span>
    <FormSignUp />
  </motion.div>
); */

const LoginPage = () => {
  // const [isNewUser, setIsNewUser] = useState(false);
  // const onClick = () => { setIsNewUser(!isNewUser) };
  const [recoverPwd, setRecoverPwd] = useState(false);
  const history = useHistory();

  const togglePwdRecover = () => setRecoverPwd(!recoverPwd);
  const returnToLogin = () => history.replace('/');

  let component = null;
  if (history.location.pathname.match(/\/recover-password/i)) {
    /* Component to change lost password */
    component = (
      <ConfirmPassword
        urlParams={history.location.search}
        onBackClick={returnToLogin}
        onSuccess={returnToLogin}
      />
    );
  } else {
    /* Component to Login */
    component = <Login onPwdRecoverClick={togglePwdRecover} />;
    if (recoverPwd) {
      /* Component to request email to recover password */
      component = <RecoverPassword onBackClick={togglePwdRecover} />;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles['image-container']} />
      <div className={styles['content-container']}>
        <div className={styles['logo-container']} />
        <AnimatePresence>{component}</AnimatePresence>
      </div>
    </div>
  );
};

export default LoginPage;

/* PropTypes */
BackButton.defaultProps = {
  onClick: () => '',
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};

ConfirmPassword.defaultProps = {
  onBackClick: () => '',
  onSuccess: () => '',
  urlParams: '',
};

ConfirmPassword.propTypes = {
  onBackClick: PropTypes.func,
  onSuccess: PropTypes.func,
  urlParams: PropTypes.string,
};

RecoverPassword.defaultProps = {
  onBackClick: () => '',
};

RecoverPassword.propTypes = {
  onBackClick: PropTypes.func,
};

Login.defaultProps = {
  onPwdRecoverClick: () => '',
};

Login.propTypes = {
  onPwdRecoverClick: PropTypes.func,
};

/* SignUp.defaultProps = {
  onBackClick: () => '',
};

SignUp.propTypes = {
  onBackClick: PropTypes.func,
}; */

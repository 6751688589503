/* eslint-disable react/button-has-type */
import React from 'react';
import { motion } from 'framer-motion';

import Logo from './logo192.png';
import styles from './index.module.scss';

const RefreshLoader = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.refreshLoaderWrapper}
    >
      <motion.div className={styles.refreshLoaderPulse} />
      <img src={Logo} alt='' />
    </motion.div>
  );
};

export default RefreshLoader;

import { isValid } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import PlusIcon from '../../assets/icons/PlusIcon';
import { openNewBookingDrawer } from '../../store/sideDrawer/actions';

import Button from '../Button';

export default function CreateBookingButton(props) {
  const { initialDate } = props;

  const dispatch = useDispatch();

  const onClickHandler = useCallback(() => {
    let payload;

    /**
     * isDate can give a class Date object with time as NaN and that is invalid date
     * but a valid class Date
     *
     * isValid also checks if time is valid
     */
    if (initialDate != null && isValid(initialDate)) {
      payload = {
        dateTime: initialDate,
      };
    }

    dispatch(openNewBookingDrawer(payload));
  }, [initialDate]);

  return (
    <Button
      title='Adicionar reserva'
      icon={
        <div style={{ marginRight: 4 }}>
          <PlusIcon size={12} />
        </div>
      }
      onClick={onClickHandler}
    />
  );
}

CreateBookingButton.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
};

CreateBookingButton.defaultProps = {
  initialDate: null,
};

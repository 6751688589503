/* eslint-disable global-require */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WarningIcon from '../../assets/icons/WarningIcon';
import SideDrawer from '../../containers/SideDrawer';
import BookingType from '../../enum/bookingType';
import PaymentStatus from '../../enum/paymentStatus';
import PaymentType from '../../enum/paymentType';
import { fetchPayment } from '../../providers/payments/fetchPayments';
import markAsPaid from '../../providers/payments/markAsPaid';
import Colors from '../../sass/_colors.scss';
import { arenaByIdSelector } from '../../store/arena/selectors';
import { fetchPayments } from '../../store/payment/routines';
import {
  closePayInfoDrawer,
  openNewBookingDrawer,
} from '../../store/sideDrawer/actions';
import { match, moneyMask } from '../../utils';
import camelizeObject from '../../utils/camelizeObject';

import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import Loading from '../Loading';

import classes from './index.module.scss';
import { createActions, initialState, reducer } from './reducer';
import Section from './Section';

const initialValuesSelector = state => state.sideDrawer.payInfo.initialValues;

const safeValue = value =>
  value != null && String(value).trim() !== '' ? value : ' -';

/**
 * @param {object} props
 * @param {boolean} [props.isOpen=false]
 * @param {boolean} [props.closeOnOutsideClick=false]
 * @param {() => void} [props.onPressClose]
 * @returns {JSX.Element}
 */
export default function PayInfoDrawer(props) {
  const { isOpen, onPressClose, closeOnOutsideClick } = props;

  const [state, dispatch] = useReducer(reducer, initialState());

  const initialValuesRedux = useSelector(initialValuesSelector);

  const arena = useSelector(reduxState =>
    arenaByIdSelector(reduxState, state.data?.arenaId)
  );

  const actions = useMemo(() => createActions(dispatch), []);

  useEffect(() => {
    if (initialValuesRedux !== null) {
      actions.fetch();

      const { id: bookingId, arena_id: arenaId } = initialValuesRedux;

      fetchPayment(arenaId, bookingId)
        .then(camelizeObject)
        .then(res => ({ ...res, paymentType: res.payTipo }))
        .then(actions.success);
    }
  }, [initialValuesRedux]);

  const buildContent = useCallback(
    () =>
      match(state.status, {
        LOADING: (
          <div
            style={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)',
            }}
          >
            <Loading />
          </div>
        ),
        ERROR: 'ERROR',
        IDLE: () => {
          const chargeType = match(state.data.bookingType, {
            [BookingType.SINGLE]: state.data.bookingClass
              ? 'Por aula'
              : 'Por uso',
            [BookingType.MONTHLY]: 'Por mês',
            default: '',
          });

          const location = match(state.data.bookingClass, {
            true: state.data.bookingInstructorReservation,
            false: arena?.title,
          });

          let day;
          let time;
          if (state.data.bookingDate) {
            [day, time] = String(state.data.bookingDate).split(' ');
            day = String(day)
              .split('-')
              .reverse()
              .join('/');
          }

          return (
            <div className={classes.root}>
              <div className={classes.container}>
                <h3 className={classes.title}>Detalhe da reserva</h3>

                <div>
                  <Section
                    title='Nome'
                    value={safeValue(state.data.applicantName)}
                  />

                  <Section
                    title='E-mail'
                    value={safeValue(state.data.applicantEmail)}
                  />

                  <Section
                    title='Telefone'
                    value={safeValue(state.data.applicantTelephone)}
                  />
                </div>

                <div className={classes.hr} />

                <div>
                  <Section
                    title='Tipo da reserva'
                    value={safeValue(
                      BookingType.getLabel(state.data.bookingType)
                    )}
                  />

                  <Section
                    title='Tipo de cobrança'
                    value={safeValue(chargeType)}
                  />

                  <Section title='Local' value={safeValue(location)} />

                  <Section
                    title='Modalidade'
                    value={safeValue(state.data.modality)}
                  />

                  <Section title='Dia' value={safeValue(day)} />

                  <Section title='Horário' value={safeValue(time)} />
                </div>

                <div className={classes.hr} />

                <div>
                  <Section
                    title='Forma de pagamento'
                    value={safeValue(
                      PaymentType.getLabel(state.data.paymentType)
                    )}
                  />

                  <Section
                    title='Valor'
                    value={safeValue(moneyMask(state.data.valor))}
                  />

                  <PaymentAlert payStatus={state.data.payStatus} />
                </div>

                <div className={classes.buttonContainer}>
                  <ActionButton date={state.data.bookingDate} {...state.data} />
                </div>
              </div>
            </div>
          );
        },
      }),
    [state]
  );

  return (
    <SideDrawer
      closeOnOutsideClick={closeOnOutsideClick}
      onPressClose={onPressClose}
      isOpen={isOpen}
      classModifier='payment'
    >
      {buildContent()}
    </SideDrawer>
  );
}

const ActionButton = props => {
  const dispatch = useDispatch();
  const currentPagination = useSelector(state => state.payment.pagination);
  const ref = useRef();

  if (
    PaymentStatus.isPending(props?.payStatus) &&
    PaymentType.isAtLocation(props?.payTipo)
  ) {
    return (
      <>
        <Button
          title='Recebido'
          color='#fff'
          background={Colors.positive}
          icon={
            <div style={{ marginRight: 8 }}>
              <img
                src={require('../../assets/svg/icon-money.svg')}
                alt='money-symbol'
              />
            </div>
          }
          onClick={() => {
            ref.current.show();
          }}
        />
        <ConfirmationModal
          ref={ref}
          title='Você tem certeza que deseja colocar essa reserva como paga?'
          subTitle='Ao clicar Sim você não poderá alterar o status do pagamento.'
          onConfirm={async () => {
            // NOTE - This will dispatch the new page request to reload screen info
            await markAsPaid(props?.id).then(() => {
              dispatch(
                fetchPayments({
                  page: currentPagination.page,
                  search: currentPagination.search,
                })
              );
              dispatch(closePayInfoDrawer());
            });
          }}
        />
      </>
    );
  }

  if (PaymentStatus.isCancelled(props?.payStatus)) {
    return (
      <Button
        background={Colors.positive}
        title='Nova reserva'
        outline
        onClick={() => {
          dispatch(closePayInfoDrawer());
          dispatch(
            openNewBookingDrawer({
              new: {
                applicant: {
                  id: props?.applicantId > 0 ? props?.applicantId : null,
                  name: props?.applicantName,
                  email: props?.applicantEmail,
                  phone: props?.applicantTelephone,
                  cpf: props?.applicantCpf,
                },
                booking: {
                  court: props?.courtId,
                  dateTime: props?.date,
                  bookingType: props?.bookingType,
                  modality: props?.modality,
                },
              },
            })
          );
        }}
      />
    );
  }

  return null;
};

// TODO: Salvar no backend quando pagamento expira e buscar para criar este componente.
// const MonthlyPaymentAlert = () => (
//   <div className={[classes.row, classes.marginTop].join(' ')}>
//     <img
//       src={require('../../assets/svg/icon-exclamation.svg')}
//       alt='exclamation-icon'
//       className={classes.exclamationIcon}
//     />
//     <span className={classes.dangerText}>
//       O pagamento deve ser efetuado até 10 dias após a geração do pagamento
//       referente ao mês desta reserva.
//     </span>
//   </div>
// );

// eslint-disable-next-line react/prop-types
const PaymentAlert = ({ payStatus }) => {
  let title;

  if (PaymentStatus.isPending(payStatus)) {
    title = 'Pagameto em aberto';
  } else if (PaymentStatus.isCancelled(payStatus)) {
    title = 'Reserva cancelada';
  }

  if (title == null) return null;

  return (
    <div className={classes.row} style={{ paddingLeft: '0.5rem' }}>
      <WarningIcon fill={Colors.negative} />
      <span className={classes.dangerText} style={{ marginLeft: '0.5rem' }}>
        {title}
      </span>
    </div>
  );
};

PayInfoDrawer.defaultProps = {
  isOpen: false,
  closeOnOutsideClick: false,
  onPressClose: () => undefined,
};

PayInfoDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onPressClose: PropTypes.func,
  closeOnOutsideClick: PropTypes.bool,
};

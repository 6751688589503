import { useEffect, useState } from 'react';
import { debounce } from '../utils';

/**
 * Hook to observe debounced window resize
 *
 * @param {number} [timeout=300] - debounce time in ms. Default 300
 * @param {function} [cb] - callback to be called immediately after resize happens
 *
 * @return {number} current window size
 */
const useResize = (timeout = 300, cb) => {
  const [width, setWidth] = useState(
    window.document.documentElement.clientWidth
  );

  useEffect(() => {
    const listener = window.addEventListener(
      'resize',
      debounce(_ => {
        const _width = window.document.documentElement.clientWidth;

        setWidth(_width);

        if (cb && typeof cb === 'function') {
          cb(_width);
        }
      }, timeout)
    );
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return width;
};

export default useResize;

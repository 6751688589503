/**
 * Generic error throw
 *
 * @param {string} prop - Failed prop name
 */
const throwMissingValue = prop => {
  throw new Error(`${prop} has to be defined.`);
};

export default throwMissingValue;

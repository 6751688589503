import { authAPI, baseAPI } from '../../service/axios';
import toFormData from '../../utils/toFormData';

export const authenticate = async payload => {
  try {
    const res = await authAPI.post('', {
      username: payload.email,
      password: payload.password,
    });

    if (res && res.data) return Promise.resolve(res.data.token);

    return Promise.reject(res);
  } catch (ex) {
    const { response } = ex;
    if (response) {
      const { data } = response;
      if (data) {
        if (data.data) {
          const { status } = data.data;
          if (Number(status) === 403) {
            return Promise.reject(new Error('Credenciais inválidas.'));
          }
        }
      }
    }

    return Promise.reject(
      new Error('Estamos tendo problemas com nossos servidores.')
    );
  }
};

export const createAccount = async payload => {
  try {
    const formData = toFormData({
      name: payload.name,
      email: payload.email,
      password: payload.password,
    });

    const res = await baseAPI.post('user/create', formData);

    return Promise.resolve(res);
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const recoverPassword = async ({ email }) => {
  try {
    const formData = toFormData({
      email,
      w: 'U2V1IGhpa2VyIGhhY2tlciE=',
    });

    const res = await baseAPI.post('user/recover', formData);

    return Promise.resolve(res);
  } catch (ex) {
    return Promise.reject(ex);
  }
};

import * as types from '../types';

const initialState = {
  order: null,
  page: 1,
  pageSize: 10,
  search: null,
  total: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TOTAL_BOOKING_REQUEST:
      return { ...state, total: action.payload };
    case types.CHANGE_PAGE_BOOKING_REQUEST:
      return { ...state, page: action.payload };
    case types.CHANGE_PAGE_SIZE_BOOKING_REQUEST:
      return { ...state, pageSize: action.payload };
    case types.CHANGE_SEARCH_PARAM_BOOKING_REQUEST:
      return { ...state, search: action.payload };
    case types.RESET_BOOKING_REQUEST_PAGINATION_DATA:
      return { ...initialState };
    default:
      return state;
  }
};

import * as types from './types';

/**
 * @typedef { import('./actions').ConfigActions } ConfigActions
 *
 * @typedef ConfigState
 * @property {number} activeArena
 */

/** @type {ConfigState} */
const initialState = {
  activeArena: null,
};

/**
 * Config reducer
 *
 * @param {ConfigState} state
 * @param {ConfigActions} action
 */
const config = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_ACTIVE_ARENA:
      return { ...state, activeArena: action.payload };
    default:
      return state;
  }
};

export default config;

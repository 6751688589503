import { format, sub } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import fetchPayments from '../../providers/payments/fetchPayments';

import Button from '../Button';
import Loading from '../Loading';

import BookingItem from './BookingItem';
import classes from './index.module.scss';

const styles = { separator: { height: '1rem' } };

/* Reducer */
const initialState = {
  loading: false,
  data: [],
  error: null,
  hasMore: false,
  page: 1,
};
function reducer(state, action) {
  switch (action.type) {
    case 'error':
      return { ...state, error: action.error };
    case 'fetch':
      return { ...state, loading: true, error: null };
    case 'fulfill':
      return { ...state, loading: false };
    case 'success':
      return {
        ...state,
        data: action.payload.data,
        hasMore: action.payload.hasMore,
        page: state.page + 1,
      };
    default:
      return state;
  }
}

/* Components */
function EmptyList() {
  return (
    <span style={{ color: '#949494', textAlign: 'center' }}>Lista vazia</span>
  );
}

function BookingsTab(props) {
  const { history, arenaId, applicantId } = props;

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { data, loading, hasMore, page } = state;

  const fetchData = params => {
    dispatch({ type: 'fetch' });

    const _params = {
      ...params,
      applicantId,
      pageSize: 5,
      order: 'date:DESC,time:ASC',
      maxDate: format(Date.now(), 'yyyy-MM-dd'),
      // minDate: format(sub(Date.now(), { days: 10 }), 'yyyy-MM-dd'),
      firsts: 10,
    };

    fetchPayments(arenaId, _params, { version: 3 })
      .then(payload => {
        dispatch({
          type: 'success',
          payload: {
            data: [].concat(...data, ...payload.data),
            // hasMore: payload.page < payload.max_pages,
            hasMore: false,
          },
        });
      })
      .catch(err => console.log(err))
      .finally(() => dispatch({ type: 'fulfill' }));
  };

  React.useEffect(() => {
    fetchData({ history });
  }, [history]);

  const handleHasMoreClick = () => fetchData({ history, page });

  /* What to render */
  const render = [];
  if (data && data.length) {
    data.forEach((booking, index) => {
      render.push(React.createElement(BookingItem, booking));
      if (index !== data.length - 1) {
        render.push(<div style={styles.separator} />);
      }
    });
  } else if (!loading) {
    render.push(<EmptyList key='empty' />);
  }

  if (loading) {
    render.push(
      <Loading style={{ alignSelf: 'center' }} key='loading' size={50} />
    );
  } else if (hasMore) {
    render.push(
      <div style={styles.separator} />,
      <Button
        key='hasMore'
        title='Carregar mais'
        onClick={handleHasMoreClick}
      />
    );
  }

  return (
    <div className={classes['booking-container']}>
      <div className={classes['booking-scroll']}>
        {render}
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
}

BookingsTab.propTypes = {
  history: PropTypes.bool,
  arenaId: PropTypes.number,
  applicantId: PropTypes.number,
};

BookingsTab.defaultProps = {
  history: false,
  arenaId: null,
  applicantId: null,
};

export default React.memo(BookingsTab);

import { createRoutine } from 'redux-saga-routines';

/* CD Booking */
export const createEditBooking = createRoutine('CREATE_EDIT_BOOKING');
export const deleteBooking = createRoutine('DELETE_BOOKING');
export const deleteBookingRecurrence = createRoutine(
  'DELETE_BOOKING_RECURRENCE'
);

/* Create Booking Request */
export const createNewBookingRequest = createRoutine(
  'CREATE_NEW_BOOKING_REQUEST'
);
/* Approve Booking request */
export const approveBookingRequest = createRoutine('APPROVE_BOOKING_REQUEST');
/* Cancel Booking request */
export const cancelBookingRequest = createRoutine('CANCEL_BOOKING_REQUEST');

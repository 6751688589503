/* CRUD */
export const ADD_PERSON = 'PERSON/ADD';
export const REMOVE_PERSON = 'PERSON/REMOVE';

/* UI */
export const CLEAR_ORDER_PERSON = 'PERSON/CLEAR_ORDER';

/* PAGINATION */
export const CHANGE_MAX_PAGES_PERSON = 'PERSON/CHANGE_MAX_PAGES';
export const CHANGE_PAGE_PERSON = 'PERSON/CHANGE_PAGE';
export const CHANGE_PAGE_SIZE_PERSON = 'PERSON/CHANGE_PAGE_SIZE';
export const CHANGE_SEARCH_PARAM_PERSON = 'PERSON/CHANGE_SEARCH_PARAM';
export const CHANGE_TOTAL_PERSON = 'PERSON/CHANGE_TOTAL';
export const ADD_PAGINATED_PERSON = 'PERSON/ADD_PAGINATED';
export const RESET_PERSON_PAGINATION_DATA = 'PERSON/RESET_PAGINATION_DATA';
export const RESET_PERSON_STORE = 'PERSON/RESET_STORE';

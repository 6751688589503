/**
 * Store para guardar pedidos que foram aceitos, ou seja, reservas já confirmadas
 * pelo administrador da arena.
 */
import { combineReducers } from 'redux';

import byID from './reducers/byID';
import order from './reducers/order';
import ui from './reducers/ui';

export default combineReducers({ byID, order, ui });

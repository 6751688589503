import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading';
import OptionsButton from '../../components/OptionsButton';
import Pagination from '../../components/Pagination';
import { fetchPeople } from '../../store/person/routines';
import { openUserInfoDrawer } from '../../store/sideDrawer/actions';

import styles from './index.module.scss';
import Row from './Row';
import Table from './Table';

const mapRows = (row, action) => {
  const { id, name, cpf, phone, email, role } = row;

  const handleClick = () => action(row);

  const actions = (
    <div className={styles.actions}>
      <OptionsButton onClick={handleClick} />
    </div>
  );

  return (
    <Row
      key={id}
      row={{
        name,
        cpf,
        phone,
        email,
        role,
        actions,
      }}
    />
  );
};

const ClientApplication = () => {
  const activePage = useSelector(state => state.person.pagination.page);
  const isLoading = useSelector(state => state.person.ui.loading);
  const clientList = useSelector(state => state.person.data);
  const user = useSelector(state => state.user.data);
  const dispatch = useDispatch();

  const handleOptionsClick = client => dispatch(openUserInfoDrawer(client));

  const handleRowsMap = r => mapRows(r, handleOptionsClick);

  const _onSelectPage = page => {
    dispatch(fetchPeople({ page }));
  };
  const pageSize = useSelector(state => state.person.pagination.pageSize);
  const total = useSelector(state => state.person.pagination.total);
  const totalPages = !total ? 1 : Math.ceil(total / pageSize);

  const firstLength = 1 + (activePage - 1) * pageSize;
  let lastLength = activePage * pageSize;
  if (lastLength > total) lastLength = total;

  const label =
    user && String(user.role).match(/professor/i) ? 'aluno(s)' : 'cliente(s)';

  return (
    <>
      {!isLoading && clientList && clientList.length ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Table>{clientList.map(handleRowsMap)}</Table>
          <footer className={styles.footer}>
            <div className={styles['empty-wrapper']} />
            <div className={styles['pagination-wrapper']}>
              <Pagination
                pages={totalPages}
                activePage={activePage}
                onSelectPage={_onSelectPage}
              />
            </div>
            {total >= 1 && (
              <div className={styles['orders-length-indicator']}>
                {`${firstLength} a ${lastLength} de ${total} ${label}`}
              </div>
            )}
          </footer>
        </motion.div>
      ) : null}
      {isLoading && (
        <motion.div
          className={styles.loader}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Loading />
        </motion.div>
      )}
    </>
  );
};

export default ClientApplication;

/**
 * Create dynamic reducers based on ./routines.js
 *
 * Each routine will become
 */

import { combineReducers } from 'redux';
import * as routines from './routines';

const reducers = {};

const _routines = Object.keys(routines);
for (let i = 0; i < _routines.length; i++) {
  const routine = _routines[i];
  const reducer = routines[routine];

  /** Store dynamic routine inside its const name */
  reducers[routine] = (
    state = { data: null, loading: false, error: null, _at: null },
    { type, payload }
  ) => {
    switch (type) {
      /* Create dynamic reducer */
      case reducer.REQUEST:
        return { ...state, loading: true };
      case reducer.SUCCESS:
        return { ...state, data: payload };
      case reducer.FAILURE:
        return { ...state, error: payload };
      case reducer.FULFILL:
        return { ...state, loading: false, _at: new Date() };
      default:
        return state;
    }
  };
}

export default combineReducers({ ...reducers });

import { format } from 'date-fns';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import BookingType from '../../../enum/bookingType';
import PaymentStatus from '../../../enum/paymentStatus';
import { openPayInfoDrawer } from '../../../store/sideDrawer/actions';
import { SafeParse } from '../../../utils';

import Badge from '../../Badge';

import classes from './index.module.scss';

function BookingItem(props) {
  const {
    id,
    booking_date: bookingDate,
    booking_type: type,
    modality,
    pay_status: payStatus,
  } = props;

  const dispatch = useDispatch();

  const typeBadge = BookingType.getBadge(type);

  const statusBadge = PaymentStatus.getBadge(payStatus);

  /* Extracting date */
  const dateTime = useMemo(() => {
    const date = SafeParse.date(bookingDate);

    return date ? format(date, 'dd/MM/yyyy - HH:mm') : undefined;
  }, [bookingDate]);

  return (
    <motion.div
      key={id}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={classes.container}
      onClick={() => dispatch(openPayInfoDrawer(props))}
    >
      <div className={classes.header}>
        <div className={classes.title}>{modality}</div>
        <div className={classes.date}>{dateTime}</div>
      </div>

      <div className={classes.badges}>
        {typeBadge.title !== '' && <Badge {...typeBadge} />}
        {statusBadge.title !== '' && <Badge {...statusBadge} />}
      </div>
    </motion.div>
  );
}

BookingItem.propTypes = {
  applicant_email: PropTypes.string,
  applicant_id: PropTypes.number,
  applicant_name: PropTypes.string,
  applicant_telephone: PropTypes.string,
  arena_id: PropTypes.number,
  booking_class: PropTypes.bool,
  booking_date: PropTypes.string,
  booking_status: PropTypes.oneOf([0, 1]),
  booking_type_label: PropTypes.string,
  booking_type: PropTypes.oneOf([0, 1]),
  cancelled_at: PropTypes.string,
  court_id: PropTypes.number,
  date_created: PropTypes.string,
  id: PropTypes.number,
  modality: PropTypes.string,
  pay_status: PropTypes.string,
};

BookingItem.defaultProps = {
  applicant_email: null,
  applicant_id: null,
  applicant_name: null,
  applicant_telephone: null,
  arena_id: null,
  booking_class: null,
  booking_date: null,
  booking_status: null,
  booking_type_label: null,
  booking_type: null,
  cancelled_at: null,
  court_id: null,
  date_created: null,
  id: null,
  modality: null,
  pay_status: null,
};

export default React.memo(BookingItem);

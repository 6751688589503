import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.scss';

const ConflictIcon = props => {
  const { tooltip, onClick } = props;

  const classname = classNames({
    [styles.conflict]: true,
    [styles.tooltip]: tooltip,
    [styles.clickable]: onClick,
  });

  if (onClick) {
    return (
      <div
        onClick={onClick}
        onKeyPress={onClick}
        tabIndex={0}
        role='button'
        className={classname}
      >
        !
      </div>
    );
  }

  return <div className={classname}>!</div>;
};

ConflictIcon.defaultProps = {
  tooltip: false,
  onClick: null,
};

ConflictIcon.propTypes = {
  tooltip: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ConflictIcon;

import merge from 'lodash/merge';

import { fetchArenas } from '../routines';

const initialState = {};

/**
 * Store events as a mapped object where each key is its ID
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case fetchArenas.SUCCESS:
      return merge({}, state, action.payload.byID);
    default:
      return state;
  }
};
